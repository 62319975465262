<form [formGroup]="administracionImagenForm" class="example-form">
  <mat-card class="admin-user-card administrar-imagen">
    <mat-card-title class="card-title">Administrar imagen del sitio</mat-card-title>
    <mat-label class="label">Cambiar imagen de bienvenida</mat-label><br />
    <app-upload-button
      (onChange)="onUploadFile($event)"
      [data]="values.ImagenBienvenida"
      [recommendedWidth]="1920"
      [recommendedHeight]="640"
      [previewImage]="'url('+previewImage+')'"></app-upload-button>
    <br />
    <mat-checkbox [checked]="values.PlecaBienvenida"
                  (change)="onCheckChange($event, 'plecaBienvenida')">
      Mostrar recuadro de bienvenida sobre imagen
    </mat-checkbox><br><br><br>
    <mat-label class="label">Configurar textos</mat-label>
    <mat-tab-group>
      <mat-tab label="Bienvenida">
        <ckeditor name="textoBienvenida" [data]="values.TextoBienvenida" [editor]="Editor" (change)="onEditorChange($event, 'textoBienvenida')"></ckeditor>
      </mat-tab>
      <mat-tab label="Proceso de admisión">
        <ckeditor name="textoProceso" [data]="values.TextoProceso" [editor]="Editor" (change)="onEditorChange($event, 'textoProceso')"></ckeditor>
      </mat-tab>
      <mat-tab label="Conoce Aliat Universidades">
        <ckeditor [editor]="Editor" [data]="values.TextoConoce" (change)="onEditorChange($event, 'textoConoce')"></ckeditor>
      </mat-tab>
    </mat-tab-group>
    <button class="color-btn save-btn" (click)="save()" mat-raised-button>Guardar</button>
  </mat-card>
</form>
