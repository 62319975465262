<mat-card class="card-size">
  <br>
  <div>
    <mat-card-title class="color-celeste-claro rob-20 section-title">
      <img class="sm-img" style="margin-left: 56px;" routerLink="/" src="/assets/iconos/ALT_Icono_Volver-2.png" />
      <img class="sm-img" routerLink="/" src="/assets/iconos/ALT_Icono_Paso-2.png" />
      <span class="color-celeste-claro rob-26 space">Información académica de interés</span>
    </mat-card-title>
  </div>
  <br>
  <mat-divider></mat-divider>
  <br>
  <div class="margin-principal">
    <label class="rob-18">
      <div>
        A continuación se muestra el monto de inscripción que debes cubrir
        de acuerdo a tu selección, así como un detalle de plan de pagos de
        colegiatura que irás cubriendo parcialmente.
      </div>
    </label>
    <br>
    <br>
    <div>
      <label class="sub-title">MONTO DE INSCRIPCIÓN POR CUBRIR</label>
    </div>
    <br>
    <br>
    <div class="col center">
      <table class="responsive-table">
        <tr>
          <td><label class="title-table">Nivel:</label></td>
          <td><label>{{solicitudAdmision.PlanAcademico.Programa.Grado.Nombre}}</label></td>
          <td><label class="title-table">Ciclo:</label></td>
          <td><label>{{solicitudAdmision.CicloAcademico.Descripcion}}</label></td>

        </tr>
        <br>

        <tr>
          <td><label class="title-table">Campus:</label></td>
          <td><label>{{solicitudAdmision.Campus.Nombre}}</label></td>
          <td><label class="title-table">Programa Académico:</label></td>
          <td><label>{{solicitudAdmision.PlanAcademico.Nombre}}</label></td>

        </tr>
        <br>

        <tr>
          <td><label class="title-table">Especialidad:</label></td>
          <td><label>{{solicitudAdmision.SubPlanAcademico.Descripcion}}</label></td>
          <td><label class="title-table">Modalidad:</label></td>
          <td><label>{{solicitudAdmision.PlanAcademico.Modalidad.Nombre}}</label></td>

        </tr>
        <br>

        <tr>
          <td><label class="title-table">Temporalidad:</label></td>
          <td><label>{{solicitudAdmision.PlanAcademico.Programa.Temporalidad.Nombre}}</label></td>
          <td><label class="title-table">Inicio de clases:</label></td>
          <td><label>{{solicitudAdmision.CicloAcademico.FechaInicio |  date:'dd/LL/yyyy'}}</label></td>

        </tr>
        <br>        
       
        <tr>
          <td> <div *ngIf="mostrarTurno"><label class="title-table">Turno:</label></div></td>
          <td><div *ngIf="mostrarTurno"><label>{{solicitudAdmision.TurnoID}}</label></div></td>
          <td></td>
          <td></td>
        </tr>

        <br>

        <tr>
          <td></td>
          <td></td>
          <td><label class="title-table">Total de inscripción:</label></td>
          <td><label style="color: red;">{{cotizacion.Total.Valor | currency:"MXN" }} MXN</label></td>
        </tr>

      </table>
    </div>
    <br>
    <br>
    <div class="text-right">
      <button class="search-btn" (click)="modificaSolicitud()" [disabled]="tienePago || avance.CompletoPago"
        mat-raised-button>MODIFICAR</button>
      <button class="color-btn"  style=" font-size: 14px; margin-left: 5px;" routerLink="/realiza-pago" mat-raised-button>PAGAR
        INSCRIPCIÓN</button>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br>
    <br />
  </div>
  <br>
</mat-card>