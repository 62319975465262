import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service'
import { NavigationService } from '@app/_services/navigation.service';
import { environment } from '@environments/environment';
import { zip } from 'rxjs';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Admisión en Línea';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.gaKey,
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });

    this.authenticationService.currentUser.subscribe(user => {
      if(user != null) {
        this.isProspect = user.IsProspecto;
        this.sidebarHidden = false;
        this.navigationService.isToggledMenu.next(false);

        if(user.IsAdmin || user.IsEmpleado) {
          this.sidebarMode = "side";
          this.sidebarFixed = false;
          this.sidebarOpened = true;
          this.navigationService.isToggledMenu.next(true);
        }
      }
      else {
        this.sidebarHidden = true;
        this.navigationService.isToggledMenu.next(false);
      }
    });

    this.navigationService.isMobile.subscribe(isMobile => {
      if(isMobile) {
        this.sidebarPosition = "end";
      }
      else {
        this.sidebarPosition = "start";
      }
    });

    this.navigationService.ToggleMenuValue.subscribe(data => {
      this.sidebarOpened = data;
    });
  };

  public isAuthenticated() {
    return this.authenticationService.isAuthenticated;
  }

  public sidebarMode: string = "over";
  public sidebarPosition: string = "start";
  public sidebarOpened: boolean = false;
  public isProspect: boolean = false;
  public sidebarFixed: boolean = true;
  public sidebarHidden: boolean = true;
}
