import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { DetalleInfInteresComponent } from '../_components/detalle-inf-interes/detalle-inf-interes.component';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { InfocionInteres } from '@app/_models/informacioninteres';
import { InformacionInteres } from '@app/_services/informacioninteres.service';

@Component({
  selector: 'app-admin-inf-interes',
  templateUrl: './admin-inf-interes.component.html',
  styleUrls: ['./admin-inf-interes.component.scss']
})
export class AdminInfInteresComponent implements OnInit {

  ngOnInit() {

    this.dataSource.paginator = this.paginator;

    this.getDataInfoInteres();
  }

  displayedColumns: string[] = ['Nombre', 'Descripcion', 'Liga', 'Opciones'];
  dataSource = new MatTableDataSource<InfocionInteres>();

  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  pageEvent: PageEvent;
  datasource: null;
  pageIndex:number = 0;
  pageSize:number = 5;
  length:number = 0
  
  filtro: string = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialog: MatDialog, private interesService: InformacionInteres, private formBuilder: FormBuilder) { }

  getDataInfoInteres() {
    this.getLiga(this.pageSize, this.pageIndex + 1);
    this.getCount();
  }

  agregarInfo(): void {
    const dialogRef = this.dialog.open(DetalleInfInteresComponent, {
      width: '900px',
      data: {operacion: 'Crear'}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDataInfoInteres();
    });
  }

  editarInfo(info:InfocionInteres): void {
    const dialogRef = this.dialog.open(DetalleInfInteresComponent, {
      width: '900px',
      data: {operacion: 'Editar', info: info}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDataInfoInteres();
    });
  }

  eliminarInfo(info:InfocionInteres){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar la información?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.interesService.delete(info)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Success){
              this.getDataInfoInteres();
            }else{
              this.errorMessage = data.Message;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
              const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
              });
            }            
          },
          error => {
            this.error = true;
            this.errorMessage = "Error al eliminar";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedió un error al procesar la solitud.', false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
            });
          });
      }
    });    
  }

  public getServerData(event?:PageEvent){

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      

    this.getDataInfoInteres();

    return event;
  }

  getLiga(nRegistros:number, nPagina:number) {
    this.loading = true;
    this.interesService.get(nRegistros, nPagina, this.filtro)
    .pipe(first())
    .subscribe(
      data => {
        this.dataSource = new MatTableDataSource<InfocionInteres>(data);
        this.loading = false;
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al obtener el total de registros";
        this.loading = false;
      });
  }

  getCount():number{

    this.interesService.getCount(this.filtro)
    .pipe(first())
    .subscribe(
      dataCount => {
        this.length = dataCount;
        return dataCount;
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al obtener el total de registros";
      });

    return 0;
  }

  filterText(){
    this.pageIndex = 0;
    this.getDataInfoInteres();
  }
}