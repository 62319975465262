import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { InfocionInteres } from '@app/_models/informacioninteres';
import { OperationResult } from '@app/_models/operationResult';


@Injectable({
  providedIn: 'root'
})
export class InformacionInteres {

  constructor(private http: HttpClient) { }

  get(nRegistros:number, nPagina:number, filtro: string){
    let params = new HttpParams();
    params = params.append('numRegistros', nRegistros.toString() );
    params = params.append('pagina', nPagina.toString() );
    params = params.append('filtro', filtro );

    return this.http.get<InfocionInteres[]>(`${environment.apiUrl}/InformacionInteres`, { params });
  }

  getCount(filtro: string){
    let params = new HttpParams();
    params = params.append('filtro', filtro );

    return this.http.get<number>(`${environment.apiUrl}/InformacionInteres/Count`, { params });
  }

  post(info: InfocionInteres){
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/InformacionInteres`, info );
  }

  put(info: InfocionInteres){
    return this.http.put<OperationResult<null>>(`${environment.apiUrl}/InformacionInteres`, info );
  }

  delete(info: InfocionInteres){
    let params = new HttpParams();
    params = params.append('InformacionInteresID', info.InformacionInteresID.toString() );

    return this.http.delete<OperationResult<null>>(`${environment.apiUrl}/InformacionInteres`, { params } );
  }

  importar(archivos: FormData, idProspecto: number, nombre: string) {
    let params = new HttpParams();
    params = params.append('idProspecto', idProspecto.toString() );
    params = params.append('nombre', nombre );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }

    return this.http.post<OperationResult<any>> (`${environment.apiUrl}/InformacionInteres/saveFile`, archivos, { params } );
  }
}
