import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public isMobile: Observable<boolean>;

  public isToggledMenu: BehaviorSubject<boolean>;
  public ToggleMenuValue: Observable<boolean>;


  constructor(
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService,
  ) {
    this.isToggledMenu = new BehaviorSubject<boolean>(false);
    this.ToggleMenuValue = this.isToggledMenu.asObservable();
    this.isMobile = new Observable<boolean>(observer => {
      breakpointObserver.observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait
      ]).subscribe(result => {
        observer.next(result.matches);
      });
    });
  }
}
