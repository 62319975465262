import { Component, OnInit } from '@angular/core';

import { PagoService } from '@app/_services/pago.service';
import { FileDownloadService } from '@app/_services/file-download.service';
import { AlertaService } from '@app/_services/alerta.service';
import { Prospecto } from '@app/_models/prospecto';
import { Cargo } from '@app/_models/cargo';
import { SolicitudAdmision } from '@app/_models/solicitudAdmision';
import { InteresAcademicoService } from '@app/_services/interes-academico.service';
import { ProspectoService } from '@app/_services/prospecto.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { CicloAcademico } from '@app/_models/cicloAcademico';
import { Campus } from '@app/_models/campus';
import { PlanAcademico } from '@app/_models/planAcademico';
import { Modalidad } from '@app/_models/modalidad';
import { Programa } from '@app/_models/programa';
import { Grado } from '@app/_models/grado';
import { Temporalidad } from '@app/_models/temporalidad';
import { SubPlanAcademico } from '@app/_models/subPlane';
import { Avance } from '@app/_models/avance';
import { Concepto } from '@app/_models/concepto';
import { Cotizacion } from '@app/_models/cotizacion';
import { MatTableDataSource } from '@angular/material/table';
import { CotizacionService } from '@app/_services/cotizacion.service';

@Component({
  selector: 'app-realiza-pago',
  templateUrl: './realiza-pago.component.html',
  styleUrls: ['./realiza-pago.component.scss']
})
export class RealizaPagoComponent implements OnInit {
  public prospecto: Prospecto;
  public universidad: string;
  public campus: string;
  public grado: string;
  public periodo: string;
  public saldo: number;
  public cantidadTipo: string = "p";
  public cantidad: number;
  public metodoPago: string;
  public error: string = null;
  public pagosRealizados: boolean = false;

  mostrarTurno = false;

  solicitudAdmision: SolicitudAdmision;

  public avance: Avance = {
    CompletoDatosPersonales: false,
    CompletoMatriculacion: false,
    CompletoPago: false,
    CompletoSolicitud: false,
    SolicitudAdmisionId: -1
  };

  cotizacion :Cotizacion;
  conceptos : Concepto[] = [];
  tienePago  = false;

  
  displayedColumns = [
    {name:'Nombre',   field:'Nombre',   title: 'Concepto', isArray: false},
    {name:'Valor',    field:'Valor',    title: 'Inscripcion', isArray: false},
    {name:'periodo0', field:'Parcialidades', title: 'Colegiatura 1', isArray: true, index:0},
    {name:'periodo1', field:'Parcialidades', title: 'Colegiatura 2', isArray: true, index:1},
    {name:'periodo2', field:'Parcialidades', title: 'Colegiatura 3', isArray: true, index:2},
    {name:'periodo3', field:'Parcialidades', title: 'Colegiatura 4', isArray: true, index:3},
    {name:'periodo4', field:'Parcialidades', title: 'Colegiatura 5', isArray: true, index:4},
    {name:'periodo5', field:'Parcialidades', title: 'Colegiatura 6', isArray: true, index:5},
    {name:'Total',    field:'Total',  title: 'Total', isArray: false}
  ]
  columnsToDisplay: any[] = [];
  dataSource = new MatTableDataSource<Concepto>(this.conceptos);

  constructor(
    private pagoService: PagoService,
    private alertaService: AlertaService,
    private fileDownloadService: FileDownloadService,
    private interesAcademicoService: InteresAcademicoService,
    private prospectoService: ProspectoService,
    private authenticationService: AuthenticationService,
    private cotizacionService: CotizacionService
  ) {
    this.prospecto = new Prospecto;
    this.error = null;
    this.pagosRealizados = false;
    this.initSolicitud();
    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.prospectoService.obtieneAvance(user.ProspectoID).then(avance =>
          this.avance = avance
        ).catch(err => console.log("Error" + err));
      };
    });
  }

  ngOnInit() {


    this.interesAcademicoService.obtienerSolicitud().then(solicitudAdmision => {
      this.solicitudAdmision = solicitudAdmision;
      if(this.solicitudAdmision.PlanAcademico.Modalidad.Clave == 'MX'){
        this.mostrarTurno = true;
      }else{
        this.mostrarTurno = false;
      }

      this.cotizacionService.consultarCotizacion(this.solicitudAdmision.CicloAcademico.CicloAcademicoID,this.solicitudAdmision.TurnoID).then( cotizacion =>
        {
          if(cotizacion != null){
            this.cotizacion = cotizacion;	
            //console.log("conceptos" + JSON.stringify(cotizacion.Conceptos));              
            //this.conceptos = this.cotizacion.Conceptos;	
            //console.log("Conceptos " + JSON.stringify(this.cotizacion));                
            this.initConceptos();
           this.dataSource = new MatTableDataSource<Concepto>(this.conceptos);                
          }
  
        }).catch(err=> { console.log("Error" + err)});

    }).catch(err => {
      console.log("Error" + err);
    });

    this.pagoService.obtieneProspecto()
      .subscribe(
        data => {
          this.prospecto = data;
          if (data.Cargos.length > 0) {
            let cargo: Cargo = data.Cargos[data.Cargos.length - 1];
            this.universidad = cargo.Campus.InstitucionID;
            this.campus = cargo.Campus.Nombre;
            this.grado = cargo.Grado.Nombre;
            this.periodo = cargo.Ciclo.Nombre;
          }
          if (data.Pagos.length > 0) {
            data.Pagos.forEach(p => {
              if (p.FechaAplicacion != null) {
                this.pagosRealizados = true;
                return;
              };
            });
          }
          this.saldo = data.Saldo;
        });
  }

  initConceptos(){

    var to = 6 - this.cotizacion.PrecioPrograma.Parcialidades.length
    this.columnsToDisplay = this.displayedColumns.map(col => col.name);

        
    this.columnsToDisplay = this.columnsToDisplay.splice(0,2+this.cotizacion.PrecioPrograma.Parcialidades.length);

    this.columnsToDisplay.push(this.displayedColumns[this.displayedColumns.length-1].name);

    this.conceptos = new Array();
    this.conceptos.push(this.cotizacion.PrecioPrograma);
    this.conceptos.push(this.cotizacion.BeneficioEspecial);
    this.conceptos.push(this.cotizacion.PrecioNeto);
    
    this.cotizacion.DerivadosInscripcion.forEach(element => {
      this.conceptos.push(element);
    });
    //this.conceptos.push(this.cotizacion.Total);




  }

  public pagar(): void {
    this.error = null;
    let cantidadPagar = this.saldo;

    if (!this.cantidadTipo || !this.metodoPago) {
      this.error = "Debes elegir una cantidad a pagar y un medio de pago.";
      return;
    }

    if (this.cantidadTipo == "o") {
      cantidadPagar = this.cantidad || 0;
    }

    if (isNaN(cantidadPagar)) {
      this.error = "La cantidad a pagar debe ser una cantidad monetaria válida.";
      return;
    };

    if (cantidadPagar < 0) {
      this.error = "La cantidad a pagar no puede ser un monto negativo.";
      return;
    }
    if (cantidadPagar <= 0) {
      this.error = "La cantidad a pagar no puede ser $0.00.";
      return;
    }
    if (cantidadPagar > this.saldo) {
      this.error = "La cantidad a pagar no puede ser un montor mayor que tu saldo.";
      return;
    }

    if (this.metodoPago == "p") {
      this.pagoService.obtieneUrlTarjeta(cantidadPagar)
        .subscribe(
          liga => {
            if (!!liga) {
              if (!!liga.Resultado)
                location.href = liga.Url;
              else
                alert(liga.Mensaje);
            }
          }
        );
    } else if (this.metodoPago == "o") {
      this.pagoService.obtieneFicha(cantidadPagar)
        .subscribe(
          data => {
            if (!!data) {
              this.fileDownloadService.downloadFile(data, "ficha-pago.pdf", "application/pdf");
            }
          }
        );
    };
  };

  initSolicitud(){
    this.solicitudAdmision = new SolicitudAdmision();
    this.solicitudAdmision.CicloAcademico = new CicloAcademico();
    this.solicitudAdmision.Campus = new Campus();
    this.solicitudAdmision.PlanAcademico = new PlanAcademico();
    this.solicitudAdmision.PlanAcademico.Modalidad = new Modalidad();    
    this.solicitudAdmision.PlanAcademico.Programa = new Programa();
    this.solicitudAdmision.PlanAcademico.Programa.Grado = new Grado();
    this.solicitudAdmision.PlanAcademico.Programa.Temporalidad = new Temporalidad();
    this.solicitudAdmision.SubPlanAcademico = new SubPlanAcademico();

  }

}
