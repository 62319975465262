<mat-card class="admin-user-card">
    <mat-card-title class="card-title">Administrar Descuentos</mat-card-title>

    <form (keydown.enter)="$event.preventDefault()" class="example-form" name="adminUserForm" id="adminUserForm" >

    <button mat-raised-button class="color-btn" (click)="agregarInfo()" >+ Agregar</button>
        
    <br>
    <br>

    <mat-form-field>
      <mat-label>Filtro de búsqueda:</mat-label>
      <input matInput  [(ngModel)]="filtro"    (keyup.enter)="filterText()" id="filter" name="filter">      
    </mat-form-field>
    <button mat-icon-button color="basic" aria-label="Buscar" (click)="filterText()"  matTooltip="Buscar" matTooltipPosition="right"><mat-icon>search</mat-icon></button>                  
    </form>

    <br/>
  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="Clave">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Clave </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="Clave">
            {{element.Clave}}
          </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="Nombre">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Nombre</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Nombre"> 
            {{element.Nombre}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Ciclo">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Ciclo</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Ciclo"> 
            {{element.CicloAcademico.NombrePlan}} {{element.CicloAcademico.Descripcion}}          
          </mat-cell>
        </ng-container>        
     
        <ng-container matColumnDef="Estatus">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Estatus</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Estatus"> 
            <div *ngIf="element.Activo">Activo</div>
            <div *ngIf="!element.Activo">Vencido</div>
          </mat-cell>
        </ng-container>     

    
        <!--ng-container matColumnDef="FechaInicio">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Fecha Inicio </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="FechaInicio"> 
            {{element.FechaInicio  | date: 'dd-MM-yyyy' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="FechaFin">
          <mat-header-cell *matHeaderCellDef  class="sub-title">Fecha Fin</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="FechaFin"> 
            {{element.FechaFin  | date: 'dd-MM-yyyy'}}
          </mat-cell>
        </ng-container-->

        <ng-container matColumnDef="Opciones">
            <mat-header-cell *matHeaderCellDef> Opciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Opciones">
                <button mat-icon-button color="basic" aria-label="Editar"  (click)="editarDescuento(element)" matTooltip="Editar"  matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button color="accent" aria-label="Eliminar" (click)="eliminarDescuento(element)"  matTooltip="Eliminar" matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
            </mat-cell>
          </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>

      </mat-table>         
    </div>         
<br>
    
</mat-card>
