import { Component, OnInit, ViewChild, Directive, Input } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { Campus } from '@app/_models/campus';
import { CampusService } from '@app/_services/campus.service';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { BusquedaCampusParam } from '@app/_models/busquedaCampusParam';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { InteresAcademicoService } from '@app/_services/interes-academico.service';
import { MatRadioButton } from '@angular/material/radio';
import { SolicitudAdmision } from '@app/_models/solicitudAdmision';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-selecciona-institucion',
  templateUrl: './selecciona-institucion.component.html',
  styleUrls: ['./selecciona-institucion.component.scss']
})
export class SeleccionaInstitucionComponent implements OnInit {


  
  displayedColumns: string[] = [ 'Institución', 'Campus', 'Enlaces', ' '];
  instituciones: string[] = [];
  filteredInstituciones: Observable<string[]>;   
  
  seleccionInstitucionForm: FormGroup;

  dataSource = new MatTableDataSource<Campus>();
  pageEvent: PageEvent;  
  pageIndex:number = 0;
  pageSize:number = 10;  
  length:number = 10  

  CP ="";
  tieneSolicitud = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;  
  constructor( private formBuilder: FormBuilder,
       private campusService: CampusService,
       private interesAcademicoService: InteresAcademicoService,       
       public dialog: MatDialog,
       private router: Router) {
  }


  ngOnInit(): void {



    var esModificacion = this.interesAcademicoService.getEsModificacion();
    this.interesAcademicoService.tieneSolicitud()
    .subscribe(
      tiene => {
        this.tieneSolicitud = tiene;
        if(this.tieneSolicitud ){ 
          if( esModificacion){
            this.cargarComboInstituciones();
            this.initInstitucionSolicitud();            

          }else{
            this.router.navigate(['/cotizacion']);
          }
        }else{
          var SessionInstitucionID = this.interesAcademicoService.getSessionStorageInstitucionID();
          var SessionCampusID = this.interesAcademicoService.getSessionStorageCampusID();

          if(SessionInstitucionID != null && SessionInstitucionID != "" &&  SessionInstitucionID!= "null"){

            if(SessionCampusID != null && SessionCampusID != "" &&  SessionCampusID!= "null"){
              console.log("Buscando campus" + SessionCampusID);
              this.interesAcademicoService.obtieneCampus(SessionCampusID).then(campus => {
                  this.campusService.almacenarCampus(campus);
                  this.router.navigate(['/interes-academico']);                  
              }).catch(err => {
                console.log("Error" + err);
              });            
  
            }else{
              this.cargarComboInstituciones();
              this.cargarCampusInstitucion();
            }

          }else{
            this.cargarComboInstituciones();

          }

        }
        
      }
    );




    this.seleccionInstitucionForm = this.formBuilder.group({
      campus: [''],
      codigoPostal: [''],
      tipo: ['2']
    });

    var SessionInstitucionID = this.interesAcademicoService.getSessionStorageInstitucionID();
    if(SessionInstitucionID != null && SessionInstitucionID != "" 
                &&    SessionInstitucionID != 'null'   && !this.tieneSolicitud){
      this.seleccionInstitucionForm.get('campus').setValue(SessionInstitucionID);
      this.seleccionInstitucionForm.get('tipo').setValue('1');
      this.seleccionInstitucionForm.get('codigoPostal').setValue('');
      this.seleccionInstitucionForm.get('codigoPostal').disable();      

    }else{
      this.campusService.getCP().then(CP => {
        this.CP = CP;
        this.seleccionInstitucionForm.get('codigoPostal').setValue (this.CP);      
        this.seleccionInstitucionForm.get('campus').disable();        
      }).catch(err => {
        console.log("Error" + err);
      });      
      this.seleccionInstitucionForm.get('campus').setValue('');
    }



    this.dataSource.paginator = this.paginator;



  }


  cargarComboInstituciones(){
    this.campusService.getInstituciones().then(instituciones => {
      this.instituciones = instituciones,
      this.filteredInstituciones = this.seleccionInstitucionForm.get('campus').valueChanges.pipe(startWith(''), 
                    map( value =>  this._filterInstituciones(value)));      
    }).catch(err => {
      console.log("Error" + err);
    });


  }


  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.getCount();
    this.getInstituciones();
    return event;
  }
  

  cargarCampusInstitucion(){
    var SessionInstitucionID = this.interesAcademicoService.getSessionStorageInstitucionID();
    var params = new BusquedaCampusParam();
    params.CampusID = SessionInstitucionID;
    params.CodigoPostal = "";
    params.numRegistros = this.pageSize;
    params.pagina = this.pageIndex+1;
    this.campusService.consultaCampusXCampusOrCP(params).then(campus => {
            this.dataSource = new MatTableDataSource<Campus>(campus);      
    }).catch(err => {
      console.log("Error" + err);
    });

    this.campusService.getCount(params).then(total => {
      this.length = total;
    }).catch(err => {
      console.log("Error" + err);
    });

  }



  getInstituciones() {
    console.log("Buscando instituciones");
    var campuses = new Array();
    var campusID = "-1";
    //console.log("Seleecionado " + this.seleccionInstitucionForm.get('campus').value);
    if(this.seleccionInstitucionForm.get('campus').value != null && 
       this.seleccionInstitucionForm.get('campus').value === 'Todas'){
      campusID = "0";
    }else if( this.seleccionInstitucionForm.get('campus').value != null &&
        this.seleccionInstitucionForm.get('campus').value != ""){
      campusID =this.seleccionInstitucionForm.get('campus').value;
    }
   
    var codigoPostal =  this.seleccionInstitucionForm.get('codigoPostal').value;
    var params = new BusquedaCampusParam();
    params.CampusID = campusID;
    params.CodigoPostal = codigoPostal;
    params.numRegistros = this.pageSize;
    params.pagina = this.pageIndex+1;
    this.campusService.consultaCampusXCampusOrCP(params).then(campus => {
      campuses = campus;
      if(campuses.length == 0 && codigoPostal != ""){
        const dialogData = new ConfirmDialogModel('Información', 'No se encontraron campus con  planes académicos disponibles para esta entidad federativa. Por favor busca la universidad a través del nombre.', false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      }


      this.dataSource = new MatTableDataSource<Campus>(campuses);      
    }).catch(err => {
      console.log("Error" + err);
    });


    
  }

  initInstitucionSolicitud() {
    var campuses = new Array();
    var solicisolicitudAdmision = this.interesAcademicoService.getSolicitudAdmision();
    campuses.push(solicisolicitudAdmision.Campus);
    this.dataSource = new MatTableDataSource<Campus>(campuses);              

  
  }




  getCount():number{
    var campusID = "-1";

    if(this.seleccionInstitucionForm.get('campus').value != null && 
    this.seleccionInstitucionForm.get('campus').value === 'Todas'){
   campusID = "0";
 }else if( this.seleccionInstitucionForm.get('campus').value != null &&
     this.seleccionInstitucionForm.get('campus').value != ""){
   campusID =this.seleccionInstitucionForm.get('campus').value;
 }    

    var codigoPostal =  this.seleccionInstitucionForm.get('codigoPostal').value;

    var params = new BusquedaCampusParam();
    //params.CampusID = this.selectedCampus.CampusID;
    params.CampusID = campusID;
    params.CodigoPostal = codigoPostal;
    this.campusService.getCount(params).then(total => {
      this.length = total;
      return total;
    }).catch(err => {
      console.log("Error" + err);
    });
    return 0;
  }  


  private _filterInstituciones(value: any): string[] {

    const filterValue = value ? ((typeof value === 'string') ? value.toLowerCase() : "") : "";    
    return this.instituciones.filter(option => (option.toLowerCase().includes(filterValue) ));
  } 


 buscar() {

  if((this.seleccionInstitucionForm.get('campus').value == null || 
      this.seleccionInstitucionForm.get('campus').value ==="") && 
      (this.seleccionInstitucionForm.get('codigoPostal').value == null || 
      this.seleccionInstitucionForm.get('codigoPostal').value ==="")){
      
      const dialogData = new ConfirmDialogModel('Validación', 'Debe ingresar un valor de búsqueda', false);    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      return;
    }

    this.getInstituciones();   
    this.getCount();     
 }


seleccionarInstitucion(instElement:any): void {
  this.campusService.almacenarCampus(instElement);
  this.router.navigate(['/interes-academico']);  

}

onSelecTipo(entrando) {
  
  var tipo =  this.seleccionInstitucionForm.get('tipo').value;
  if(tipo === "1"){

    this.seleccionInstitucionForm.get('campus').enable();
    this.seleccionInstitucionForm.get('codigoPostal').disable();
    this.seleccionInstitucionForm.get('codigoPostal').setValue('');    
  }else{
    this.seleccionInstitucionForm.get('campus').setValue('');
    this.seleccionInstitucionForm.get('campus').disable()
    this.seleccionInstitucionForm.get('codigoPostal').enable()    
    
  }

  if(!entrando){


  }
}


}

