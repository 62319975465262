import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service'
import { NavigationService } from '@app/_services/navigation.service';
import { LoadingScreenService } from '@app/_services/loading-screen.service';
import { ConfiguracionSitioService } from '@app/_services/configuracion-sitio.service';
import { ConfiguracionSitio } from '@app/_models/configuracionSitio';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  usuario: string = "";
  password: string = "";
  returnUrl: string = "";
  error: boolean = false;
  errorMessage: string = "";
  loading: boolean = false;
  imagenBienvenida: string = "";
  plecaBienvenida: boolean = true;
  pageReady: boolean = false;

  @ViewChild('textoBienvenida') textoBienvenida: ElementRef;
  @ViewChild('textoProceso') textoProceso: ElementRef;
  @ViewChild('textoConoce') textoConoce: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService,
    private loadingScreenService: LoadingScreenService,
    private configuracionSitioService: ConfiguracionSitioService
  ) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log("entrando a login");
    if (this.authenticationService.currentUserValue != null) {
      console.log("navegando a inicio");
      this.router.navigate(['/inicio']);
    }

    
    const body = <HTMLDivElement> document.body;
    const chat = document.getElementById('hubspot-messages-iframe-container');
    if(chat ){
      body.removeChild(chat);
    }
  }

  ngAfterViewInit() {
    this.cargarConfiguracionSitio();
  }

  async cargarConfiguracionSitio() {
    try {
      const configuracionSitio: ConfiguracionSitio = await this.configuracionSitioService.obtener();

      if(configuracionSitio.TextoBienvenida) {
        this.textoBienvenida.nativeElement.innerHTML = configuracionSitio.TextoBienvenida;
      }

      this.plecaBienvenida = configuracionSitio.PlecaBienvenida;

      if(configuracionSitio.TextoConoce) {
        this.textoConoce.nativeElement.innerHTML = configuracionSitio.TextoConoce;
      }

      if(configuracionSitio.TextoProceso) {
        this.textoProceso.nativeElement.innerHTML = configuracionSitio.TextoProceso;
      }

      if(configuracionSitio.ImagenBienvenida) {
        this.imagenBienvenida = `url('${environment.apiUrl.replace("/api", "")}/Content/ConfiguracionSitio/${configuracionSitio.ImagenBienvenida}')'`;
      }
    }
    catch(error) {
      console.error(error)
    }
    finally {
      this.pageReady = true
    }
  }

  login(): void {

    this.loadingScreenService.startLoading();
    this.error = false;
    this.errorMessage = "";

    this.authenticationService.login(this.usuario, this.password)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
          this.loadingScreenService.stopLoading();
        },
        error => {
          this.error = true;
          this.errorMessage = "Usuario o contraseña inválidos.";
          this.loadingScreenService.stopLoading();
        });
  }

}
