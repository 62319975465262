import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario_ } from '@app/_models/usuario_';
import { Prospecto } from '@app/_models/prospecto';
import { PagoTarjetaUrlResponse } from '@app/_models/pagotarjetaurlresponse';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/_services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class PagoService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  private portalId: number = 2;

  get(nRegistros:number, nPagina:number){
    let params = new HttpParams();
    params = params.append('numRegistros', nRegistros.toString() );
    params = params.append('pagina', nPagina.toString() );

    return this.http.get<Usuario_[]>(`${environment.apiUrl}/usuario`, { params });
  }

  obtieneProspecto(): Observable<Prospecto> {
    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    return this.http.get<Prospecto>(`${environment.apiUrl}/Prospecto/${prospectoId}`);
  }

  obtieneSaldo(): Observable<number> {
    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    return this.http.get<number>(`${environment.apiUrl}/Prospecto/${prospectoId}/Saldo`);
  }

  obtieneUrlTarjeta(cantidad: number): Observable<PagoTarjetaUrlResponse> {
    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    return this.http.post<PagoTarjetaUrlResponse>(`${environment.apiUrl}/Tarjeta/${prospectoId}/cantidad/${cantidad}/portal/${this.portalId}/`, {});
  }
  obtieneFicha(cantidad: number): Observable<any> {
    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    return this.http.post<any>(`${environment.apiUrl}/Ficha/${prospectoId}/cantidad/${cantidad}/portal/${this.portalId}/`, {});
  }
}
