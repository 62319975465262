import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Descuento } from '@app/_models/descuento';


import { environment } from '@environments/environment';
import { ResponseRest } from '@app/_models/responseRest';
import { CicloAcademico } from '@app/_models/cicloAcademico';
import { Modalidad } from '@app/_models/modalidad';

@Injectable({ providedIn: 'root' })
export class DescuentoService{

    constructor(private http: HttpClient) {
    }


    consultaDescuento(nRegistros:number, nPagina:number, filtro: string, idUsuario:number){
        console.log('nRegistros', nRegistros + "nPagina " + nPagina );
  
        let params = new HttpParams();
        params = params.append('numRegistros', nRegistros.toString() );
        params = params.append('pagina', nPagina.toString() );
        params = params.append('filtro', filtro );
        params = params.append('idUsuario', idUsuario.toString() );        
   
        return this.http.get<Descuento[]>(`${environment.apiUrl}/Descuento`, { params });
    
    }    



    getCount(filtro: string, idUsuario:number){
        let params = new HttpParams();
        params = params.append('filtro', filtro );
        params = params.append('idUsuario', idUsuario.toString() );        
        return this.http.get<number>(`${environment.apiUrl}/Descuento/Count`, { params });
    }    

    guardarDescuento(descuento : Descuento):Promise<ResponseRest<string>> {
        return  this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Descuento`, descuento).toPromise();
        
    }     

    eliminarDescuento(descuento : Descuento):Promise<ResponseRest<string>> {
        let params = new HttpParams();
        params = params.append('DescuentoID', descuento.DescuentoID.toString() );
        return  this.http.delete<ResponseRest<string>>(`${environment.apiUrl}/Descuento`, {params}).toPromise();      
    }      

    consultarCiclosAcademicos( CampusID : string, GradoID: number, ModalidadID : number):Promise<CicloAcademico[]>{
        return this.http.post<CicloAcademico[]>(`${environment.apiUrl}/Descuento/${CampusID}/${GradoID}/${ModalidadID}/CiclosAcademicos`, {}).toPromise();
    }
    
    consultarModalidades( CampusID : string, GradoID: number):Promise<Modalidad[]>{
        return this.http.post<Modalidad[]>(`${environment.apiUrl}/Descuento/${CampusID}/${GradoID}/Modalidades`, {}).toPromise();
    }
    


}