<mat-card class="conciliacion-card">
    <mat-card-title class="card-title">Registro de pagos</mat-card-title>
    
        <form (keydown.enter)="$event.preventDefault()" class="example-form" 
        [formGroup]="conciliacionForm"
        name="conciliacionForm" id="conciliacionForm" >
        
          <br><br>
          <label>Tipo de portal:</label>&nbsp; &nbsp; 
          <mat-radio-group id='tipoPortal' name="tipoPortal" formControlName="tipoPortal" >
            <mat-radio-button value="1">Caja</mat-radio-button>
            <mat-radio-button value="2">Admisión</mat-radio-button>
            <mat-radio-button value="0">Ambos</mat-radio-button>
        </mat-radio-group>  
        <br><br>                  
          <label>Búsqueda por:</label>
          &nbsp; &nbsp; 
          <mat-radio-group id='tipoFecha' name="tipoFecha" formControlName="tipoFecha" >
              <mat-radio-button value="1">Fecha de pago</mat-radio-button>
              <mat-radio-button value="2">Fecha de aplicación</mat-radio-button>
          </mat-radio-group>  

          <br><br>
          <mat-form-field>
            <mat-label>Desde</mat-label>
                <input matInput [matDatepicker]="pickerDesde" placeholder="dd/mm/yyyy"   
                formControlName="dateDesde"
                id="dateDesdeControl" name="dateDesdeControl" >
                <mat-datepicker-toggle matSuffix [for]="pickerDesde">
                </mat-datepicker-toggle>
            <mat-datepicker #pickerDesde></mat-datepicker>
            </mat-form-field>
    
            &nbsp; &nbsp; &nbsp; &nbsp;
            <mat-label>a</mat-label>
            &nbsp; &nbsp; &nbsp; &nbsp;
    
            <mat-form-field>
              <mat-label>Hasta</mat-label>
                  <input matInput [matDatepicker]="pickerHasta" placeholder="dd/mm/yyyy" 
                  formControlName="dateHasta"
                  id="dateHastaControl" name="dateHastaControl" >
                  <mat-datepicker-toggle matSuffix [for]="pickerHasta">
                  </mat-datepicker-toggle>
              <mat-datepicker #pickerHasta></mat-datepicker>
            </mat-form-field>
                  
          &nbsp; &nbsp; &nbsp; &nbsp;
          <mat-form-field class="example-full-width">
            <mat-label>Referencia:</mat-label>
            <input matInput formControlName="referencia"  id="filter" name="filter"   >
          </mat-form-field>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <button class="color-btn" mat-raised-button (click)="buscar()">Buscar</button>     

  
      </form>
  
      <br>
      <br>
      <mat-card>
      <div class="mat-elevation-z8">
          <mat-table [dataSource]="dataSourceP">

            <ng-container matColumnDef="institucion">
              <mat-header-cell  *matHeaderCellDef > Institución </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Institucion"> {{element.Institucion}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="campus">
              <mat-header-cell  *matHeaderCellDef > Campus </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Campus"> {{element.Campus}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fechaPago">
              <mat-header-cell  *matHeaderCellDef > Fecha de pago </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Fecha pago"> {{element.fechaAplicacion | date: 'dd-MM-yyyy'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fechaAplicacion">
              <mat-header-cell  *matHeaderCellDef > Fecha de aplicación </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Fecha aplicación"> {{element.fechaCreacion | date: 'dd-MM-yyyy'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="nombre">
              <mat-header-cell  *matHeaderCellDef > Nombre </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="nombre"> {{element.PrimerApellido}} {{element.SegundoApellido}} {{element.NombreProspecto}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="referencia">
              <mat-header-cell  *matHeaderCellDef > Referencia </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="referencia"> {{element.Referencia}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="montoPagado" >
              <mat-header-cell  *matHeaderCellDef > Monto  pagado</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Monto pagado"> {{element.MontoPagado | currency:"MXN" }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="banco">
              <mat-header-cell  *matHeaderCellDef > Banco </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="banco"> {{element.Banco}} </mat-cell>
            </ng-container>

  
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </mat-table> 
        
          <mat-paginator
          #paginatorP
          [length]="lengthP"
          [pageIndex]="pageIndexP"
          [pageSize]="pageSizeP"
          [pageSizeOptions]="[10, 20, 50]" 
          (page)="pageEventP = getServerDataP($event)">
          
          </mat-paginator>


    
    

      </div>
      <br><br>
      <div  class="toRight">
        <button  mat-raised-button color="primary"   (click)="generar()"  ><mat-icon>cloud_download</mat-icon> Descargar</button>

      </div>

    </mat-card>






   


    
    








