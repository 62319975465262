import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GradoService } from '@app/_services/grado.service';
import { CampusService } from '@app/_services/campus.service';
import { Campus } from '@app/_models/campus';
import { Grado } from '@app/_models/grado';
import { CicloAcademico } from '@app/_models/cicloAcademico';
import { Modalidad } from '@app/_models/modalidad';
import { ModalidadService } from '@app/_services/modalidad.service';
import { DescuentoService } from '@app/_services/descuentos.service';
import { Descuento } from '@app/_models/descuento';
import { InteresAcademicoService } from '@app/_services/interes-academico.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { DescuentoOperation } from '@app/_services/descuentoOperation';


@Component({
  selector: 'app-detallen-descuento',
  templateUrl: './detallen-descuento.component.html',
  styleUrls: ['./detallen-descuento.component.scss']
})
export class DetallenDescuentoComponent implements OnInit {

  detalleDescuentoForm: FormGroup;
  disabled_ = false;
  enableBtn = false;

  campus: Campus[] = [];

  grados: Grado[] = [];

  modalidades: Modalidad[] = [];  

  ciclos: CicloAcademico[] = [];


  idUsuario : number;
  
  porcentajeInsErr  = false;
  porcentajeColErr  = false;

  porcentajeSumaErr = false;

  porcentajeInsNumber : number;  

  porcentajeColNumber : number;  


  cargandoCampus = null;
  cargandoGrado = null;
  cargandoModalidad = null;
  cargandoCiclo = null;  

  errorMessage = "";
  error = false;   

  constructor(public dialogRef: MatDialogRef<DetallenDescuentoComponent>,
                    private formBuilder: FormBuilder,
                    private gradoService: GradoService,
                    private campusService: CampusService,
                    private modalidadService : ModalidadService,
                    private descuentoService: DescuentoService,
                    private authenticationService: AuthenticationService,
                    private interesAcademicoService: InteresAcademicoService,
                    @Inject(MAT_DIALOG_DATA) public data: DescuentoOperation
                    ) { }

  ngOnInit() {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.campusService.getByIdUser(this.idUsuario).then(campus => {
      this.campus = campus;
    }).catch(err => {
      console.log("Error" + err);
    });


    this.detalleDescuentoForm = this.formBuilder.group({
      codigo: ['', Validators.required],
      nombre: ['', Validators.required],
      fechaInicio: ['', Validators.required],
      fechaFin: ['', Validators.required],            
      campus: ['', Validators.required],
      grado: ['',Validators.required],
      modalidad: ['',Validators.required],      
      ciclo: ['', Validators.required],
      monto: ['', Validators.required],
      porcentajeIns: ['', Validators.required],
      porcentajeCol: ['', Validators.required],

    });





  }


  ngAfterViewInit() {

    if (this.data.Operation === 'Editar') {

      this.cargandoCampus = "(Cargando ...)";
      this.cargandoGrado = "(Cargando ...)";
      this.cargandoModalidad = "(Cargando ...)";
      this.cargandoCiclo= "(Cargando ...)";      
  
      this.interesAcademicoService.consultarNiveles(this.data.Descuento.CicloAcademico.CampusID).
      then(grados => {
        this.grados = grados;
        this.cargandoGrado = null;
      }).catch(err => {
        console.log("Error" + err);
      });
  
      this.descuentoService.consultarModalidades(this.data.Descuento.CicloAcademico.CampusID, 
        this.data.Descuento.Grado.GradoID).then(modalidades => {
        this.modalidades = modalidades;
        this.cargandoModalidad = null;
      }).catch(err => {
        console.log("Error" + err);
      });
  
      this.descuentoService.consultarCiclosAcademicos(this.data.Descuento.CicloAcademico.CampusID,
        this.data.Descuento.Grado.GradoID, 
        this.data.Descuento.Modalidad.ModalidadID).then(ciclos => {
        this.ciclos= ciclos;
        this.cargandoCiclo = null;        
        }).catch(err => {
        console.log("Error" + err);
        });     
  
  
      
      console.log("Data: " + JSON.stringify(this.data));
      this.detalleDescuentoForm.get('codigo').setValue(this.data.Descuento.Clave);    
      this.detalleDescuentoForm.get('nombre').setValue(this.data.Descuento.Nombre);    
      this.detalleDescuentoForm.get('fechaInicio').setValue(this.data.Descuento.FechaInicio);    
      this.detalleDescuentoForm.get('fechaFin').setValue(this.data.Descuento.FechaFin);    
      this.detalleDescuentoForm.get('campus').setValue(this.data.Descuento.CicloAcademico.CampusID);    
      this.detalleDescuentoForm.get('grado').setValue(this.data.Descuento.Grado.GradoID);        
      this.detalleDescuentoForm.get('modalidad').setValue(this.data.Descuento.Modalidad.ModalidadID);    
      this.detalleDescuentoForm.get('ciclo').setValue(this.data.Descuento.CicloAcademico.CicloAcademicoID);    
      this.detalleDescuentoForm.get('monto').setValue(this.data.Descuento.Monto);    
      this.detalleDescuentoForm.get('porcentajeIns').setValue(this.data.Descuento.Inscripcion);    
      this.detalleDescuentoForm.get('porcentajeCol').setValue(this.data.Descuento.Colegiatura);    
  

    }



  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  onSelectCampus(){
    var CampusID = this.detalleDescuentoForm.get('campus').value;    
    if(CampusID != null && CampusID != ""){
      this.cargandoGrado = "(Cargando ...)";      
      this.interesAcademicoService.consultarNiveles(CampusID).then(grados => {
        this.grados = grados;
        this.modalidades = [];
        this.ciclos = [];
        this.detalleDescuentoForm.get('grado').setValue("");
        this.detalleDescuentoForm.get('modalidad').setValue("");        
        this.detalleDescuentoForm.get('ciclo').setValue("");        
        this.cargandoGrado = null;
      }).catch(err => {
        console.log("Error" + err);
      });
    }
  }


  onSelectGrado(){
    var CampusID = this.detalleDescuentoForm.get('campus').value;      
    var GradoID  =  this.detalleDescuentoForm.get('grado').value;   

    console.log("Campus grado "  + CampusID + " : " + GradoID);
    if(CampusID != null && CampusID != "" && GradoID != null && GradoID != ""){
      this.cargandoModalidad = "(Cargando ...)";          
      this.descuentoService.consultarModalidades(CampusID, GradoID).then(modalidades => {
        this.modalidades = modalidades;
        this.ciclos = [];
        this.detalleDescuentoForm.get('modalidad').setValue("");        
        this.detalleDescuentoForm.get('ciclo').setValue("");        
        this.cargandoModalidad = null;
        
      }).catch(err => {
        console.log("Error" + err);
      });
  

    }




  }


  buscarCiclos(){

      var GradoID  =  this.detalleDescuentoForm.get('grado').value;      
      var CampusID = this.detalleDescuentoForm.get('campus').value;      
      var ModalidadID = this.detalleDescuentoForm.get('modalidad').value;            

      console.log("GradoID" + GradoID);
      console.log("CampusID" + CampusID);
      console.log("ModalidadID" + ModalidadID);


      if(GradoID != '' && CampusID != '' && ModalidadID != ''){
        console.log("Buscando");
        this.cargandoCiclo = "(Cargando ...)";
        this.descuentoService.consultarCiclosAcademicos(CampusID,GradoID, ModalidadID).then(ciclos => {
          this.ciclos= ciclos;
          this.detalleDescuentoForm.get('ciclo').setValue("");                  
          this.cargandoCiclo = null;  
          }).catch(err => {
          console.log("Error" + err);
          });      
    }
  }

  validarPorcentajeInscripcion() {
    this.porcentajeSumaErr = false;
    this.porcentajeInsErr = false;

    var porcentajeIns  =  this.detalleDescuentoForm.get('porcentajeIns').value;    
    console.log("porcentaje " + porcentajeIns);
    if(porcentajeIns != null){
      this.porcentajeInsNumber =  Number(porcentajeIns);      
      if(this.porcentajeInsNumber > 1 || this.porcentajeInsNumber < 0 ){
        this.porcentajeInsErr= true;
      }
      var suma = this.porcentajeColNumber + this.porcentajeInsNumber;
      if(suma != 1 ){
        this.porcentajeSumaErr = true;
      }

    }
  }

  validarPorcentajeCol() {
    this.porcentajeSumaErr = false;
    this.porcentajeColErr = false;
    var porcentajeCol  =  this.detalleDescuentoForm.get('porcentajeCol').value;      
    if(porcentajeCol != null){
      this.porcentajeColNumber =  Number(porcentajeCol);      
      if(this.porcentajeInsNumber > 1 || this.porcentajeInsNumber < 0 ){
        this.porcentajeColErr= true;
      }
      var suma = this.porcentajeColNumber + this.porcentajeInsNumber;
      if(suma != 1 ){
        this.porcentajeSumaErr = true;
      }

    }
  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 46) return true; 
    if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
      return false;
    }
    return true;
  
  }  

  guardar(): void {

    var GradoID  =  this.detalleDescuentoForm.get('grado').value;      
    var CampusID = this.detalleDescuentoForm.get('campus').value;      
    var ModalidadID = this.detalleDescuentoForm.get('modalidad').value;            
    var CicloAcademicoID = this.detalleDescuentoForm.get('ciclo').value;            

    var descuento = new Descuento();
    if (this.data.Operation === 'Editar') {
      descuento.DescuentoID = this.data.Descuento.DescuentoID;
    }
    descuento.Clave = this.detalleDescuentoForm.get('codigo').value;            
    descuento.Nombre = this.detalleDescuentoForm.get('nombre').value;            
    descuento.FechaInicio = this.detalleDescuentoForm.get('fechaInicio').value;            
    descuento.FechaFin = this.detalleDescuentoForm.get('fechaFin').value;            
    descuento.Monto= this.detalleDescuentoForm.get('monto').value;            
    descuento.Inscripcion = this.detalleDescuentoForm.get('porcentajeIns').value;                
    descuento.Colegiatura= this.detalleDescuentoForm.get('porcentajeCol').value;            

    descuento.Grado = new Grado();
    descuento.Grado.GradoID = GradoID;

    descuento.Campus = new Campus();
    descuento.Campus.CampusID = CampusID;

    descuento.Modalidad = new Modalidad();
    descuento.Modalidad.ModalidadID = ModalidadID;

    descuento.CicloAcademico = new CicloAcademico();
    descuento.CicloAcademico.CicloAcademicoID = CicloAcademicoID;
    
    
    this.descuentoService.guardarDescuento(descuento).then(res => {
      if (res.Exito) {
        this.dialogRef.close();
      } else {
        //Mostrar Mensaje res.Mensaje
        this.errorMessage = res.Mensaje;
        this.error = true;        
      }
    }).catch(err => {
      console.log("Error" + err);
    }).finally(() => {

    });
    



  


  }




}
