<mat-card class="card-size">
  <div>
    <mat-card-title class="color-celeste">
      <img class="sm-img" style="margin-left: 56px;" routerLink="/" src="/assets/iconos/ALT_Icono_Volver-1.png"/>
      <span class="color-celeste rob-26 space">Crear cuenta</span>
    </mat-card-title>
  </div>
  <br>
  <mat-divider></mat-divider>
  <br>
  <div class="principal1">
    <div class="margin-principal">
      <form class="registro-form" name="cambioPasswordForm" id="cambioPasswordForm"  [formGroup]="cambioPasswordForm">  
        <br>
        <mat-form-field class="registro-full-width">
          <mat-label>Correo electrónico</mat-label>
          <input matInput placeholder="correo@example.com"  (ngModelChange)="validarCorreo()"
            matTooltip="Ingrese un correo electrónico, será tu nombre de usuario"  autocomplete="usuario"
            value="" formControlName="correo"  >
          <mat-error *ngIf="cambioPasswordForm.hasError('existe')">
            El correo ya se  dió de alta en el sistema
          </mat-error>      
          <div *ngIf="correoError">
            <mat-error *ngIf="correoError"> Ingrese un correo válido </mat-error>
          </div>                  
        </mat-form-field>              
    
        <mat-form-field class="registro-full-width">
          <mat-label>Nombre*</mat-label>
          <input matInput placeholder="Ingrese su nombre" value="" formControlName="nombre">
        </mat-form-field>
    
        <mat-form-field class="registro-full-width">
          <mat-label>Primer apellido*</mat-label>
          <input matInput placeholder="Ingrese primer apellido" value="" formControlName="primerApellido">
        </mat-form-field>
      
        <mat-form-field class="registro-full-width">
          <mat-label>Segundo apellido</mat-label>
          <input matInput placeholder="Ingrese segundo apellido" value="" formControlName="segundoApellido">
        </mat-form-field>
        <div style="width: 100%;">
          <mat-label>Teléfono*</mat-label>
          <br>
          <mat-form-field class="md-width rob-15">
            <mat-select id="claveTelefonica" name="claveTelefonica" placeholder="Clave país" formControlName="claveTelefonica"   required >
              <mat-option *ngFor="let e of paises" [value]="e.PaisID">
                {{e.Nombre}} {{e.ClaveTelefonica}}
              </mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field class="xs-width rob-15">
            <mat-label>Teléfono</mat-label>
            <input matInput name="telefono" formControlName="telefono"  placeholder="Teléfono"
             autocomplete="off"  maxlength="10" required (keypress)="numberOnly($event)" 
            matTooltip="Ingresa los 10 dígitos de tu teléfono sin caractéres extra" >
          </mat-form-field>
  
        </div>
      
      
        <mat-form-field class="md-width">
          <mat-label>Fecha de nacimiento*</mat-label>
          <input matInput [min]="minDate" [max]="maxDate"  placeholder="dd/mm/yyyy"  name ="fechaNacimiento"
                (ngModelChange)='validar()'  (input)='validar()'
                [matDatepicker]="picker" formControlName="fechaNacimiento">    
                <div *ngIf="dateError">
                  <mat-error *ngIf="dateError"> La fecha ingresada no tiene un formato correcto. </mat-error>
                </div>                                   
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      

        
        <div class="registro-full-with">
            <mat-label class="registro-label">Genero*:</mat-label>
            <br>
            <mat-radio-group aria-label="Seleccione una opcion" required  formControlName="genero" name = "genero">
              <mat-radio-button value="1"><label>Hombre</label></mat-radio-button>
              <mat-radio-button value="2"><label>Mujer</label></mat-radio-button>
            </mat-radio-group>
        </div>

        <br>


  

        <div class="registro-full-with">
          <mat-label class="registro-label-interes">Modelo educativo de interes*:</mat-label>
          <br>
          <mat-radio-group aria-label="Seleccione una opcion"  formControlName="modeloEducativo" required  name = "modeloEducativo">
            <mat-radio-button value="1"><label>Presencial</label></mat-radio-button>
            <mat-radio-button value="2"><label>En linea</label></mat-radio-button>
          </mat-radio-group>
      </div>        

      <br>
      <mat-label>Contraseña</mat-label> <br>
      <mat-form-field class="registro-full-width">
          <mat-label>Escribe la contraseña</mat-label>
          <input matInput type="password" id="password" name="password"  autocomplete="new-password"
            formControlName="password" required (ngModelChange)="updateProgressBar()" 
            (focus)="mostrarDescripcion()" (focusout)="ocultarDescripcion()"
            matTooltip="La contraseña debe tener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o caracter especial">
            <div *ngIf="fortalezaError">
              <mat-error *ngIf="fortalezaError">La contraseña no cumple con los requisitos de seguridad </mat-error>
            </div>            
        </mat-form-field>
        <div *ngIf="mostrarDescripcionPass">
          <mat-label  class="registro-label-pass">Debe contener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o caracter especial</mat-label>       
        </div>           
        <br>
      
        <mat-progress-bar *ngIf="containsPssword"
        mode="determinate"
        class="example-full-width"
        [color]="colorForce"        
        [value]="valueForce"
        [bufferValue]="bufferValue" >
        </mat-progress-bar>
      
      
        <mat-form-field class="registro-full-width">
            <input matInput placeholder="Vuelve a escribir tu contraseña" autocomplete="new-password2"
              type="password" id="confirmPassword" name="confirmPassword" 
              formControlName="confirmPassword" [errorStateMatcher]="matcher">
              <mat-error *ngIf="cambioPasswordForm.hasError('notSame')">
              El password no coincide
            </mat-error>  
        </mat-form-field>
        <br>
        <mat-checkbox formControlName="acepto" required>
          <div> Acepto el <a target="blank" href="https://www.aliatuniversidades.com.mx/aviso-de-privacidad/?utm_source=Internet&utm_medium=Directo">Aviso de privacidad</a>  </div>
        </mat-checkbox>
        <br>
        <br/>
        <div #recaptcha></div>
        <br/><br/>
        <div class="text-center">
          <button class="color-btn" mat-raised-button  class="cancelar-btn"  (click)="cancelar()" >Cancelar</button>
          <button class="color-btn" mat-raised-button  (click)="crearCuenta()" >Crear cuenta</button>
        </div>
        <br/>
      </form>
    </div>
  </div>  
</mat-card>