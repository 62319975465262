import { Temporalidad } from './temporalidad';
import { Grado } from './grado';

export class Programa {

    ProgramaID:string;
    Clave : string;
    Nombre: string;
    Descripcion : string;    

    Grado : Grado;
    Temporalidad : Temporalidad;

  }
  