<mat-card class="card-size">
  <br>
          <mat-card-title class="card-title"><div class="el-titulo">¡Recuperación Exitosa!</div></mat-card-title>
          <br>
          <div class="texto-informativo">
            <label class=''>
              Te hemos enviado un correo electrónico a <b>{{correo}}</b>.
              <br>
              <br>
              En los próximos 10 minutos recibirás un correo electrónico de noreply5@redaliat.mx con los pasos para restablecer tu contraseña.
              <br>
              <br>
              En caso de que no lo recibas, te pedimos que revises la bandeja de correos no deseados o SPAM.
            </label>           
          </div>
  </mat-card>
  