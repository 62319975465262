<mat-toolbar style="background-color: #213046;" class="main-navbar">
  <mat-toolbar-row>
    <div fxFlex fxLayout fxLayoutAlign="center">
      <img routerLink="/" src="/assets/logo-aliat.png" class="logo-universidad" />
    </div>
    
    <div fxFlex fxLayout fxLayoutAlign="flex-end">
      <ul fxLayout fxLayoutGap="20px" class="navigation-items" fxHide fxShow.xs fxShow.sm>
        <li>
          <button [fxShow]="!isProspect" mat-icon-button  style="color: white;" aria-label="Menú" matTooltip="Mostrar el menú de navegación" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
          </button>
          <button [fxShow]="isProspect" mat-icon-button  style="color: white;" aria-label="Menú"  matTooltip="Terminar la sesión del usuario" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
          </button>
      </ul>

      <ul fxLayout fxHide.xs fxHide.sm fxLayoutGap="20px" class="navigation-items" [hidden]="!isAuthenticated">
        
        <li >
          <label class="color-bar style-sub" style="margin-right: 10rem !important;">#CLARO QUE PUEDO</label>
        </li>
        <div class="vl"></div>
        <li>
          <label routerLink="/"  matTooltip="Ir a inicio" class="color-bar style-sub">Admisión en Línea</label>
        </li>
        <li>
          <label class="color-bar style-sub" matTooltip="Terminar la sesión del usuario" (click)="logout()">Cerrar sesión</label>
        </li>
      </ul>

      <ul fxLayout fxHide.xs fxHide.sm fxLayoutGap="20px" class="navigation-items" [hidden]="isAuthenticated">
        <li >
          <label class="color-bar style-sub" style="margin-right: 10rem !important;">#CLARO QUE PUEDO</label>
        </li>
        <div class="vl"></div>
        <li  [hidden]="isLogin">
          <label routerLink="/" class="color-bar style-sub">Admisión en Línea</label>
        </li>
        <li [hidden]="!isLogin">
          <label class="color-bar style-sub" routerLink="/registro">Crear Cuenta</label>
        </li>
        <li [hidden]="isLogin">
          <label class="color-bar style-sub" routerLink="/login">Iniciar Sesión</label>
        </li>
      </ul>

    </div>
  </mat-toolbar-row>
</mat-toolbar>
