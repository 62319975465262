import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { DetallenDescuentoComponent } from '../_components/detallen-descuento/detallen-descuento.component';
import { Descuento } from '@app/_models/descuento';
import { DescuentoService } from '@app/_services/descuentos.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { first } from 'rxjs/operators';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';


@Component({
  selector: 'app-admin-descuentos',
  templateUrl: './admin-descuentos.component.html',
  styleUrls: ['./admin-descuentos.component.scss']
})
export class AdminDescuentosComponent implements OnInit {
  displayedColumns: string[] = ['Clave', 'Nombre', 'Ciclo', 'Estatus',  'Opciones'];
  dataSource = new MatTableDataSource<Descuento>();
  
  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";


  
  pageEvent: PageEvent;
  pageIndex:number = 0;
  pageSize:number = 5;
  length:number = 10



  filtro: string = "";
  result: string = '';
  idUsuario: number;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  constructor(public dialog: MatDialog,
              private descuentoService : DescuentoService,
              private authenticationService: AuthenticationService) { }

  ngOnInit(){
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.dataSource.paginator = this.paginator;
    this.getDataDescuentos();


  }

  getDataDescuentos() {
    this.getDescuentos(this.pageSize, this.pageIndex + 1);
    this.getCount();
  }


  getDescuentos(nRegistros:number, nPagina:number) {
    this.loading = true;
    this.descuentoService.consultaDescuento(nRegistros, nPagina, this.filtro, this.idUsuario)
    .pipe(first())
    .subscribe(
        data => {
          this.dataSource = new MatTableDataSource<Descuento>(data);
          this.loading = false;
        }, 
        error => {
          this.error = true;
          this.errorMessage = "Error al obtener la lista de usuarios-empleados";
          this.loading = false;
  
        }

    );

  }

  getCount():number{

    
    this.descuentoService.getCount(this.filtro, this.idUsuario)
    .pipe(first())
    .subscribe(
      dataCount => {
        this.length = dataCount;
        return dataCount;
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al obtener el total de registros";
      });

    return 0;
  }




  agregarInfo(): void {

    const dialogRef = this.dialog.open(DetallenDescuentoComponent, {
      width: '900px',
      data: {Operation: 'Crear', Descuento: {}}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDataDescuentos();
    });

  }



  editarDescuento(descuentoElement:any): void {


  
    const dialogRef = this.dialog.open(DetallenDescuentoComponent, {
      width: '900px',
      data: {Operation: 'Editar', Descuento: descuentoElement}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDataDescuentos();

    });


  }


  eliminarDescuento(descuentoElement:Descuento): void {


    const message = `¿Está seguro de que  quiere borrar el descuento?`;

    const dialogData = new ConfirmDialogModel("Confirmar", message);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if(this.result){
        this.descuentoService.eliminarDescuento(descuentoElement).then( res => {
          if(res.Exito){
            this.getDataDescuentos();
            
          }else{
            //Mostrar Mensaje res.Mensaje
          }
        }).catch(err => {
          console.log("Error"+ err);
        });


      }


    });

  }

  filterText(){
    this.pageIndex = 0;
    this.getDataDescuentos();      

  }




}



