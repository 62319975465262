import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../_services/navigation.service';
import { DomSanitizer } from '@angular/platform-browser';

export interface File {
  data: any;
  filename: string;
}

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {
  @ViewChild("uploadInput") uploadInput
  @ViewChild("imageSlot") imageSlot
  public file: File
  private static defaultFilename: string = 'Ningún archivo seleccionado'
  @Output() onChange = new EventEmitter<File>()
  @Input() data: string
  @Input() helpText: string
  @Input() recommendedWidth: number = 0
  @Input() recommendedHeight: number = 0
  public error: any
  @Input() previewImage: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private sanitization:DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.file = { filename: this.data || UploadButtonComponent.defaultFilename, data: undefined }
  }

  onClickUpload() {
    this.uploadInput.nativeElement.click()
  }

  checkProportions(width: number, height: number) {
    const widthCheck: number = width / this.recommendedWidth;
    const heightCheck: number = height / this.recommendedHeight;

    return Number.isInteger(widthCheck) && Number.isInteger(heightCheck);
  }

  onUploadFile(event) {
    if (event.target.files.length <= 0){
      this.file.filename = 'Ningún archivo seleccionado'
      this.file.data = null
    } else {
      this.file.data = event.target.files[0]
      this.file.filename = this.file.data.name
      this.imageSlot.nativeElement.src = URL.createObjectURL(this.file.data)
      this.previewImage = this.sanitization.bypassSecurityTrustStyle(`url("${URL.createObjectURL(this.file.data)}")`)
      this.imageSlot.nativeElement.onload = ({ target }) => {
        const width = target.naturalWidth
        const height = target.naturalHeight

        if(!this.checkProportions(width, height)) {
          this.error = `La imagen seleccionada no tiene la proporción correcta.`
        } else {
          this.error = false
        }
      }
    }

    this.onChange.emit(this.file)
  }
}
