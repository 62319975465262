<mat-card-title class="card-title">{{data.Operation}}  Descuento</mat-card-title>
<form    [formGroup]="detalleDescuentoForm" class="form" name="detalleDescuentoForm" id="detalleDescuentoForm">  
    <div class="margin-principal">

            <mat-form-field class="example-full-width">
            <mat-label>Código</mat-label>
            <input matInput  formControlName="codigo"  >
            </mat-form-field>
        
            <mat-form-field class="example-full-width">
                <mat-label>Nombre</mat-label>
                <input matInput   formControlName="nombre"  >
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Fecha Inicio</mat-label>
                <input matInput [matDatepicker]="picker1"  formControlName="fechaInicio" >
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
    
            <mat-form-field>
            <mat-label>Fecha Fin</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="fechaFin">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field  class="mat-form-field-80">
              <mat-label>Campus </mat-label>
              <mat-select id="campus" name="campus" formControlName="campus"  (openedChange)="onSelectCampus()"   >
                <mat-option *ngFor="let c of campus" [value]="c.CampusID">
                  {{c.InstitucionID}}-{{c.Nombre}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          <br>
                      
            <mat-form-field  class="full-width">
                <mat-label>Grado {{cargandoGrado}}</mat-label>                
                <mat-select id="grado" name="grado" formControlName="grado"   (openedChange)="onSelectGrado()"  >
                  <mat-option *ngFor="let c of grados" [value]="c.GradoID">
                    {{c.Nombre}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field  class="full-width">
                <mat-label>Modalidad {{cargandoModalidad}}</mat-label>
                <mat-select id="modalidad" name="modalidad" formControlName="modalidad"   (openedChange)="buscarCiclos()" >
                  <mat-option *ngFor="let c of modalidades" [value]="c.ModalidadID">
                    {{c.Nombre}}
                  </mat-option>
                </mat-select>
            </mat-form-field>            
        <br>



        <mat-form-field  class="mat-form-field-80">
            <mat-label>Ciclo Académico{{cargandoCiclo}} </mat-label>
            <mat-select id="ciclo" name="ciclo" formControlName="ciclo"  >
              <mat-option *ngFor="let c of ciclos" [value]="c.CicloAcademicoID">
                {{c.NombrePlan}}-{{c.Descripcion}}
              </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Monto</mat-label>
            <input matInput   formControlName="monto"  >
        </mat-form-field>        

        <mat-form-field class="example-full-width">
            <mat-label>Porcentaje inscripción</mat-label>
            <input matInput   formControlName="porcentajeIns" (keypress)="numberOnly($event)"  maxlength="4"  (ngModelChange)="validarPorcentajeInscripcion()" >
        </mat-form-field>        

        <mat-form-field class="example-full-width">
            <mat-label>Porcentaje colegiatura</mat-label>
            <input matInput   formControlName="porcentajeCol" (keypress)="numberOnly($event)"  maxlength="4"  (ngModelChange)="validarPorcentajeCol()"  >
        </mat-form-field>        

          <div *ngIf="porcentajeSumaErr">
            <mat-error *ngIf="porcentajeSumaErr"> La suma de porcentajes debe ser igual a 1 </mat-error>
          </div>

          <div *ngIf="error">
            <br>
            <mat-card class='card-danger'>{{errorMessage}}</mat-card>
          </div>

        <br> 
        <div style="text-align: end;">
            <button type="button"  (click)="guardar()"  mat-raised-button class="color-btn style-ingresar button-btn" [disabled]="!detalleDescuentoForm.valid || porcentajeInsErr ||porcentajeColErr ||porcentajeSumaErr ">
                Guardar
            </button>
            <button type="button" mat-raised-button (click)="onNoClick()" class="color-btn style-ingresar button-btn" style="margin-left: 1rem;">
                Cancelar
            </button>
        </div>
    </div>

</form>
