import { Component, OnInit } from '@angular/core';
import { CampusService } from '@app/_services/campus.service';
import { Router } from '@angular/router';
import { Grado } from '@app/_models/grado';
import { Ciclo } from '@app/_models/ciclo';
import { InteresAcademicoService } from '@app/_services/interes-academico.service';
import { GradoService } from '@app/_services/grado.service';
import { CicloService } from '@app/_services/ciclo.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Programa } from '@app/_models/programa';
import { Modalidad } from '@app/_models/modalidad';
import { Temporalidad } from '@app/_models/temporalidad';
import { SolicitudAdmision } from '@app/_models/solicitudAdmision';
import { PlanAcademico } from '@app/_models/planAcademico';
import { CicloAcademico } from '@app/_models/cicloAcademico';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Campus } from '@app/_models/campus';
import { Turno } from '@app/_models/turno';
import { SubPlanAcademico } from '@app/_models/subPlane';

@Component({
  selector: 'app-interes-academico',
  templateUrl: './interes-academico.component.html',
  styleUrls: ['./interes-academico.component.scss']
})
export class InteresAcademicoComponent implements OnInit {

  interesAcademicoForm: FormGroup;
  
  grados: Grado[] = [];

  //planes: PlanAcademico[] = [];
  programas: Programa[] = [];

  modalidades: Modalidad[] = [];

  temporalidades: Temporalidad[] = [];



  ciclos: CicloAcademico[] = [];

  turnos: Turno[] = [];

  subPlanes: SubPlanAcademico[] = [];  

  ProspectoID : number;

  solicitudAdmision :SolicitudAdmision;

  campus: Campus;

  mostrarEspecialidad = true;

  planAcademicoID : string;

  mostrarTurno = false;


  constructor(public dialog: MatDialog,
    private campusService: CampusService,
    private cicloService : CicloService,
    private interesAcademicoService: InteresAcademicoService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router) { 

        this.campus = new Campus();
           this.authenticationService.currentUser.subscribe(user => {
        if (user != null) {
          this.ProspectoID = user.ProspectoID;
        }
  
      });
  
    }

  ngOnInit(): void {

    this.interesAcademicoForm = this.formBuilder.group({
      grado:          [  '',Validators.required],
      carrera:        [  '',Validators.required],
      ciclo:          [  '',Validators.required],
      modalidad :     [  '',Validators.required],
      temporalidad :  ['',Validators.required],
      turno :         ['',Validators.required],
      subPlan :       ['',Validators.required],

    });

    this.campus = this.campusService.currentCampusValue;
    if(this.campus == null){
      var SessionCampusID = this.interesAcademicoService.getSessionStorageCampusID();
      if(SessionCampusID != null && SessionCampusID != "" &&  SessionCampusID!= "null"){
        console.log("Buscando campus" + SessionCampusID);
        this.interesAcademicoService.obtieneCampus(SessionCampusID).then(campus => {
            this.campusService.almacenarCampus(campus);
            this.campus = this.campusService.currentCampusValue;
        }).catch(err => {
          console.log("Error" + err);
        });            
        if(this.campus == null){
          this.router.navigate(['/selecciona-institucion']);
        }

      }

    }
    if(this.campus == null){
      this.router.navigate(['/selecciona-institucion']);
    }else{
      this.interesAcademicoService.consultarNiveles(this.campus.CampusID).then(grados => {
        var SessionGradoClave = this.interesAcademicoService.getSesionStorageGradoID();  
        var  setVal = null;          
        this.grados = grados;
        if(this.solicitudAdmision  == null || this.solicitudAdmision.SolicitudAdmisionID == null
                  || this.solicitudAdmision.SolicitudAdmisionID == 0){
          if(SessionGradoClave != null && SessionGradoClave != '' && SessionGradoClave != 'null'){
             setVal = this.grados.find( op => op.Clave === SessionGradoClave);     
  
            if(setVal != null){
              this.interesAcademicoForm.get('grado').setValue(setVal.GradoID);
              this.interesAcademicoService.consultarCarreras(setVal.GradoID,this.campus.CampusID).then(programas => {
                this.programas = programas;
                var SessionCarreraClave = this.interesAcademicoService.getSesionStorageCarreraID();
                console.log("SessionCarreraClave: " + SessionCarreraClave);          
                if(SessionCarreraClave != null && SessionCarreraClave != '' && SessionCarreraClave != 'null'){
                  var ProgramaPreSel = this.programas.find( op => op.Clave === SessionCarreraClave);     
                  this.interesAcademicoForm.get('carrera').setValue(ProgramaPreSel.ProgramaID);
                  this.iniciarModalidad(ProgramaPreSel.ProgramaID);
                  this.iniciarTemporalidad(ProgramaPreSel.ProgramaID);
          

                }
                }).catch(err => {
                console.log("Error" + err);
                });          
        

            }
          }
          

    
  
        }
  
      }).catch(err => {
        console.log("Error" + err);
      });    
  
      this.solicitudAdmision = this.interesAcademicoService.getSolicitudAdmision();
      if(this.solicitudAdmision  == null){
        this.initSolicitud();
      }else{
        this.llenaForma(); 
  
      }
    }

  }


  llenaForma(){
    console.log("Llenando forma" );
    var campus = this.campusService.currentCampusValue;
    if(campus.CampusID !=this.solicitudAdmision.Campus.CampusID ){
      this.interesAcademicoForm.get('grado').setValue('');
      this.interesAcademicoForm.get('carrera').setValue('');
      this.interesAcademicoForm.get('modalidad').setValue('');
      this.interesAcademicoForm.get('temporalidad').setValue('');
      this.interesAcademicoForm.get('ciclo').setValue('');
      this.interesAcademicoForm.get('turno').setValue('');
      this.interesAcademicoForm.get('subPlan').setValue('');            
  
    }else{
      this.planAcademicoID =this.solicitudAdmision.PlanAcademico.PlanAcademicoID;      
      //console.log("Especialidad" + JSON.stringify( this.solicitudAdmision.SubPlanAcademico));
      this.interesAcademicoService.consultarCarreras(this.solicitudAdmision.PlanAcademico.Programa.Grado.GradoID,
        campus.CampusID).then(programas => {
        this.programas = programas;
        }).catch(err => {
        console.log("Error" + err);
        });          


        this.interesAcademicoService.consultarModalidades(this.solicitudAdmision.PlanAcademico.Programa.ProgramaID, this.campus.CampusID).then(modalidades => {
        this.modalidades = modalidades;
        }).catch(err => {
        console.log("Error" + err);
        });          

        this.interesAcademicoService.consultarTemporalidades(this.solicitudAdmision.PlanAcademico.Programa.ProgramaID, this.campus.CampusID).then(temporalidades => {
        this.temporalidades= temporalidades;
        //console.log("Temporalidades " + JSON.stringify(this.temporalidades));
        }).catch(err => {
        console.log("Error" + err);
        });          

        this.interesAcademicoService.consultarCiclosAcademicos(this.solicitudAdmision.PlanAcademico.PlanAcademicoID).then(ciclos => {
        this.ciclos= ciclos;
        }).catch(err => {
        console.log("Error" + err);
        });      

        var gradoID = this.solicitudAdmision.PlanAcademico.Programa.Grado.GradoID
        this.interesAcademicoService.consultarSubPlanes(this.solicitudAdmision.PlanAcademico.PlanAcademicoID, gradoID).then(subPlanes => {
          this.subPlanes = subPlanes;
        }).catch(err => {
          console.log("Error" + err);
        });       
      
        if(this.solicitudAdmision.SubPlanAcademico.Codigo != 'NA'){
          this.mostrarEspecialidad = true;
        }else{
          this.mostrarEspecialidad = false;
        }


        this.interesAcademicoService.consultarTurnos(this.solicitudAdmision.CicloAcademico.CicloAcademicoID).then(turnos => {
          this.turnos = turnos;
          if(this.turnos.length > 1){
            this.mostrarTurno = true;
          }else{
            this.mostrarTurno = false;
          }
        }).catch(err => {
          console.log("Error" + err);
        });  
  
        this.interesAcademicoForm.get('grado').setValue(this.solicitudAdmision.PlanAcademico.Programa.Grado.GradoID);
        this.interesAcademicoForm.get('carrera').setValue(this.solicitudAdmision.PlanAcademico.Programa.ProgramaID);
        this.interesAcademicoForm.get('modalidad').setValue(this.solicitudAdmision.PlanAcademico.Modalidad.ModalidadID);
        this.interesAcademicoForm.get('temporalidad').setValue(this.solicitudAdmision.PlanAcademico.Programa.Temporalidad.TemporalidadID);
        this.interesAcademicoForm.get('ciclo').setValue(this.solicitudAdmision.CicloAcademico.CicloAcademicoID);
        this.interesAcademicoForm.get('turno').setValue(this.solicitudAdmision.TurnoID);
        this.interesAcademicoForm.get('subPlan').setValue(this.solicitudAdmision.SubPlanAcademico.SubPlanAcademicoID);            
  
    }
  }

  onSelectGrado(entrando) {
    if(!entrando){
      console.log("Cargando...");  
      var gradoID  =  this.interesAcademicoForm.get('grado').value;      
      var campus = this.campusService.currentCampusValue;
      this.interesAcademicoService.consultarCarreras(gradoID,campus.CampusID).then(programas => {
        //console.log(JSON.stringify(planes));
        this.programas = programas;
        this.modalidades = [];         
        this.temporalidades = [];       
        this.ciclos = [];               
        this.interesAcademicoForm.get('carrera').setValue('');        
        this.interesAcademicoForm.get('modalidad').setValue(0);        
        this.interesAcademicoForm.get('temporalidad').setValue(0);        
        this.interesAcademicoForm.get('ciclo').setValue('');     
        this.interesAcademicoForm.get('turno').setValue('');
        this.interesAcademicoForm.get('subPlan').setValue('');  
        this.mostrarEspecialidad = true;          
  
      }).catch(err => {
        console.log("Error" + err);
      });          
    }
  }  


  onSelectCarrera(entrando) {
    if(!entrando){
      var gradoID  =  this.interesAcademicoForm.get('grado').value;      
      var ProgramaID  =  this.interesAcademicoForm.get('carrera').value;      
      console.log("onSelectCarrera..." +ProgramaID);       
      if(ProgramaID != null && ProgramaID != ''){
      
        this.iniciarModalidad(ProgramaID);
        this.iniciarTemporalidad(ProgramaID);
      }
      

    }
  }
  




  onSelectModalidad(entrando) {
    if(!entrando){
      var ProgramaID  =  this.interesAcademicoForm.get('carrera').value;      
      var ModalidadID  =  this.interesAcademicoForm.get('modalidad').value;      


      this.interesAcademicoService.obtenerPlanID(this.campus.CampusID,ProgramaID, ModalidadID).subscribe(planAcademicoID => {
          this.planAcademicoID =planAcademicoID;
          this.iniciarCiclosAcademicos();
          var GradoID  =  this.interesAcademicoForm.get('grado').value;            
          this.iniciarEspecialidad(GradoID);
      });
    }
  }


  iniciarModalidad(ProgramaID : string){
    this.interesAcademicoService.consultarModalidades(ProgramaID,this.campus.CampusID).then(modalidades => {
      this.modalidades = modalidades;
      if(this.modalidades.length ==1){
        this.interesAcademicoForm.get('modalidad').setValue(this.modalidades[0].ModalidadID);
        this.interesAcademicoService.obtenerPlanID(this.campus.CampusID,ProgramaID, this.modalidades[0].ModalidadID).subscribe(planAcademicoID => {
          this.planAcademicoID =planAcademicoID;
          this.iniciarCiclosAcademicos();
          var GradoID  =  this.interesAcademicoForm.get('grado').value;            
          this.iniciarEspecialidad(GradoID);
      });        

      }

    }).catch(err => {
      console.log("Error" + err);
    });  
  }

  iniciarTemporalidad(ProgramaID : string){
    this.interesAcademicoService.consultarTemporalidades(ProgramaID, this.campus.CampusID).then(temporalidades => {
      this.temporalidades = temporalidades;
      if(this.temporalidades.length ==1){
        this.interesAcademicoForm.get('temporalidad').setValue(this.temporalidades[0].TemporalidadID);

      }

    }).catch(err => {
      console.log("Error" + err);
    });       


  }




  iniciarCiclosAcademicos(){
    this.interesAcademicoService.consultarCiclosAcademicos(this.planAcademicoID).then(ciclos => {
      //console.log(JSON.stringify(ciclos));
      this.ciclos = ciclos;
      if(this.ciclos.length ==1){
        this.interesAcademicoForm.get('ciclo').setValue(this.ciclos[0].CicloAcademicoID);
        this.interesAcademicoService.consultarTurnos(this.ciclos[0].CicloAcademicoID).then(turnos => {
          this.turnos = turnos;
          if(this.turnos.length ==1){
            this.interesAcademicoForm.get('turno').setValue(this.turnos[0].TurnoID);
            this.mostrarTurno = false;
          }else{
            this.interesAcademicoForm.get('turno').setValue('');
            this.mostrarTurno = true;
          }
  
        }).catch(err => {
          console.log("Error" + err);
        });  
  

      }        

    }).catch(err => {
      console.log("Error" + err);
    });    


  }


  iniciarEspecialidad(GradoID: number){
    this.interesAcademicoService.consultarSubPlanes(this.planAcademicoID, GradoID).then(subPlanes => {
      this.subPlanes = subPlanes;
      this.mostrarEspecialidad = true;          
      if(this.subPlanes.length ==1){
        var codigo = this.subPlanes[0].Codigo;
        if(codigo == 'NA'){
          this.mostrarEspecialidad = false;
        }
        
        this.interesAcademicoForm.get('subPlan').setValue(this.subPlanes[0].SubPlanAcademicoID);
      }     
    }).catch(err => {
      console.log("Error" + err);
    });       


  }



  onSelectCiclo(entrando) {
    if(!entrando){

      var CicloAcademicoID  =  this.interesAcademicoForm.get('ciclo').value;    
      console.log("Cargando..." +CicloAcademicoID);        
      
      this.interesAcademicoService.consultarTurnos(CicloAcademicoID).then(turnos => {
        this.turnos = turnos;
        if(this.turnos.length ==1){
          this.interesAcademicoForm.get('turno').setValue(this.turnos[0].TurnoID);
          this.mostrarTurno = false;
        }else{
          this.mostrarTurno =  true;
        }

      }).catch(err => {
        console.log("Error" + err);
      });  

    }
  }





  guardarInformacion() {

    this.solicitudAdmision.ProspectoID = this.ProspectoID;
    this.solicitudAdmision.Campus.CampusID = this.campusService.currentCampusValue.CampusID;
    this.solicitudAdmision.PlanAcademico.PlanAcademicoID = this.planAcademicoID;
    this.solicitudAdmision.PlanAcademico.Modalidad.ModalidadID = this.interesAcademicoForm.get('modalidad').value;    

    this.solicitudAdmision.PlanAcademico.Programa.Grado.GradoID = this.interesAcademicoForm.get('grado').value;
    this.solicitudAdmision.PlanAcademico.Programa.Temporalidad.TemporalidadID = this.interesAcademicoForm.get('temporalidad').value;
    
    this.solicitudAdmision.CicloAcademico.CicloAcademicoID = this.interesAcademicoForm.get('ciclo').value;
    this.solicitudAdmision.SubPlanAcademico.SubPlanAcademicoID = this.interesAcademicoForm.get('subPlan').value;
    this.solicitudAdmision.TurnoID = this.interesAcademicoForm.get('turno').value;
    
    

    
    //console.log("Guardado..." + JSON.stringify(this.solicitudAdmision));

    //this.router.navigate(['/cotizacion']);

    
    this.interesAcademicoService.guardarSolicitudAdmision(this.solicitudAdmision).then(res => {
      if (res.Exito) {
        this.router.navigate(['/realiza-pago']);
      } else {
        //this.error = true;
        const dialogData = new ConfirmDialogModel('Error', res.Mensaje, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });

      }
    })   

      //routerLink="/cotizacion"      
  }



  initSolicitud(){

    this.solicitudAdmision = new SolicitudAdmision();
    this.solicitudAdmision.CicloAcademico = new CicloAcademico();
    this.solicitudAdmision.Campus = new Campus();
    this.solicitudAdmision.PlanAcademico = new PlanAcademico();
    this.solicitudAdmision.PlanAcademico.Modalidad = new Modalidad();    
    this.solicitudAdmision.PlanAcademico.Programa = new Programa();
    this.solicitudAdmision.PlanAcademico.Programa.Grado = new Grado();
    this.solicitudAdmision.PlanAcademico.Programa.Temporalidad = new Temporalidad();
    this.solicitudAdmision.SubPlanAcademico = new SubPlanAcademico();




  }

}
