import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { OperationResult } from '@app/_models/operationResult';
import { Usuario_ } from '@app/_models/usuario_';

@Injectable({
  providedIn: 'root'
})
export class RecuperaCuentaService {

  constructor(private http: HttpClient) { }

  post(email: string, fechaNacimiento: Date, TokenReCaptcha: string){
    let params = new HttpParams();
    params = params.append('email', email );
    params = params.append('fechaNacimiento', fechaNacimiento.toDateString() );
    params = params.append('tokenReCaptcha', TokenReCaptcha);
    params = params.append('isProspectos', 'true');

    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/RecuperaCuenta`, null, { params } );
  }

  cambiarContrasena(usuario: Usuario_){
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/RecuperaCuenta/CambiarContrasena`, usuario );
  }

  verificaToken(usuario: Usuario_){
    let params = new HttpParams();
    params = params.append('token', usuario.Token );
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/RecuperaCuenta/VerificaToken`, null, { params } );
  }

}
