<mat-card class="registro-card">
    <div class="center">
      <mat-card class="none">

        <div   *ngIf="isRechazada">
          <mat-card-title class="card-title"><div class="el-titulo">Pago declinado</div></mat-card-title>
          <br>
          <div class="texto" style='padding-bottom: 150px; line-height: 2.0em'>
            ¡Ups, parece ser que tu pago fue declinado! 
            <br>
            Por favor actualiza o cambia tu método de pago y vuelve a intentarlo. Para volver a intentarlo dar click  <a id="link" href="#" routerLink="/realiza-pago" >aquí</a>.
         </div>


        </div>


          <div  [hidden]="isRechazada">
            <mat-card-title class="card-title"><div class="el-titulo">Gracias</div></mat-card-title>
            <br>
            <div class="texto" style='padding-bottom: 150px; line-height: 2.0em'>
              Ya eres parte de Aliat Universidades. Hoy comienza tu camino a tu mejor versión, ya puedes disfrutar todos los beneficios que tenemos para ti.
              <br>
              Tu pago está siendo procesado, si es aprobado, podrás verlo reflejado en un lapso de 2 horas en tu estado de cuenta o tus movimientos bancarios.
           </div>
            <div>REF: {{referencia}}</div>
  

          </div>


      </mat-card>
    </div>
  </mat-card>
