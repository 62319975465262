import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { SolicitudAdmision } from '@app/_models/solicitudAdmision';
import { Router } from '@angular/router';
import { InteresAcademicoService } from '@app/_services/interes-academico.service';
import { CicloAcademico } from '@app/_models/cicloAcademico';
import { Campus } from '@app/_models/campus';
import { PlanAcademico } from '@app/_models/planAcademico';
import { Modalidad } from '@app/_models/modalidad';
import { Programa } from '@app/_models/programa';
import { Grado } from '@app/_models/grado';
import { Temporalidad } from '@app/_models/temporalidad';
import { Avance } from '@app/_models/avance';
import { CotizacionService } from '@app/_services/cotizacion.service';
import { ProspectoService } from '@app/_services/prospecto.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Cotizacion } from '@app/_models/cotizacion';
import { Concepto } from '@app/_models/concepto';
import { SubPlanAcademico } from '@app/_models/subPlane';

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.scss']
})
export class CotizacionComponent implements OnInit {

  solicitudAdmision: SolicitudAdmision;  

  cotizacion :Cotizacion;
  conceptos : Concepto[] = [];
  tienePago  = false;
  mostrarTurno = false;

  
  displayedColumns = [
    {name:'Nombre',   field:'Nombre',   title: 'Concepto', isArray: false},
    {name:'Valor',    field:'Valor',    title: 'Inscripción', isArray: false},
    {name:'periodo0', field:'Parcialidades', title: 'Colegiatura 1', isArray: true, index:0},
    {name:'periodo1', field:'Parcialidades', title: 'Colegiatura 2', isArray: true, index:1},
    {name:'periodo2', field:'Parcialidades', title: 'Colegiatura 3', isArray: true, index:2},
    {name:'periodo3', field:'Parcialidades', title: 'Colegiatura 4', isArray: true, index:3},
    {name:'periodo4', field:'Parcialidades', title: 'Colegiatura 5', isArray: true, index:4},
    {name:'periodo5', field:'Parcialidades', title: 'Colegiatura 6', isArray: true, index:5},
    {name:'Total',    field:'Total',    title: 'Total', isArray: false}
  ]
  columnsToDisplay: any[] = [];
  dataSource = new MatTableDataSource<Concepto>(this.conceptos);

  public avance: Avance = {
    CompletoDatosPersonales: false,
    CompletoMatriculacion: false,
    CompletoPago: false,
    CompletoSolicitud: false,
    SolicitudAdmisionId: -1
  };

    constructor( private interesAcademicoService: InteresAcademicoService,
      private cotizacionService: CotizacionService,
      private prospectoService: ProspectoService,
      private authenticationService: AuthenticationService,
    private router: Router) { 
      this.initSolicitud();

      this.authenticationService.currentUser.subscribe(user => {
        if (user != null) {
          this.prospectoService.obtieneAvance(user.ProspectoID).then(avance =>
            this.avance = avance
          ).catch(err => console.log("Error" + err));
        };
      });
    }

    ngOnInit(): void {

      this.cotizacionService.tienePago()
      .subscribe(
        tiene => {
            this.tienePago = tiene;
          }
      );      

      this.interesAcademicoService.obtienerSolicitud().then(solicitudAdmision => {
        this.solicitudAdmision = solicitudAdmision;

        
        if(this.solicitudAdmision == null ){
          this.initSolicitud();
          //this.router.navigate(['/selecciona-institucion']);
  
        }else{
          if(this.solicitudAdmision.PlanAcademico.Modalidad.Clave == 'MX'){
            this.mostrarTurno = true;
          }else{
            this.mostrarTurno = false;
          }
  
          this.cotizacionService.consultarCotizacion(this.solicitudAdmision.CicloAcademico.CicloAcademicoID,this.solicitudAdmision.TurnoID).then( cotizacion =>
            {
              if(cotizacion != null){
                this.cotizacion = cotizacion;	
                //console.log("conceptos" + JSON.stringify(cotizacion.Conceptos));              
                //this.conceptos = this.cotizacion.Conceptos;	
                //console.log("Conceptos " + JSON.stringify(this.cotizacion));                
                this.initConceptos();
               this.dataSource = new MatTableDataSource<Concepto>(this.conceptos);                
              }

            }).catch(err=> { console.log("Error" + err)});
        
         

        }
      }).catch(err => {
        console.log("Error" + err);
      });


    }


  initSolicitud(){
    this.solicitudAdmision = new SolicitudAdmision();
    this.solicitudAdmision.CicloAcademico = new CicloAcademico();
    this.solicitudAdmision.Campus = new Campus();
    this.solicitudAdmision.PlanAcademico = new PlanAcademico();
    this.solicitudAdmision.PlanAcademico.Modalidad = new Modalidad();    
    this.solicitudAdmision.PlanAcademico.Programa = new Programa();
    this.solicitudAdmision.PlanAcademico.Programa.Grado = new Grado();
    this.solicitudAdmision.PlanAcademico.Programa.Temporalidad = new Temporalidad();
    this.solicitudAdmision.SubPlanAcademico = new SubPlanAcademico();
    //console.log("Se inicio la solicitud");

    this.cotizacion = new Cotizacion();
    this.cotizacion.Total = new Concepto();


  }


  initConceptos(){

    var to = 6 - this.cotizacion.PrecioPrograma.Parcialidades.length
    this.columnsToDisplay = this.displayedColumns.map(col => col.name);

        
    this.columnsToDisplay = this.columnsToDisplay.splice(0,2+this.cotizacion.PrecioPrograma.Parcialidades.length);

    this.columnsToDisplay.push(this.displayedColumns[this.displayedColumns.length-1].name);

    this.conceptos = new Array();
    this.conceptos.push(this.cotizacion.PrecioPrograma);
    this.conceptos.push(this.cotizacion.BeneficioEspecial);
    this.conceptos.push(this.cotizacion.PrecioNeto);
    
    this.cotizacion.DerivadosInscripcion.forEach(element => {
      this.conceptos.push(element);
    });
    //this.conceptos.push(this.cotizacion.Total);




  }


  modificaSolicitud(){
    //routerLink="/selecciona-institucion"
    //console.log("Ir a modificar");
    this.interesAcademicoService.setEsModificacion(true);
    this.interesAcademicoService.setSolicitudAdmison(this.solicitudAdmision);
    this.router.navigate(['/selecciona-institucion']);
  }  



  s(val){
    return JSON.stringify(val)
  }


}
