import { InteresAcademicoService } from '@app/_services/interes-academico.service';

export class RegistroPagoParam {


    Filtro :string;
    tipoFecha : number;
    tipoPortal : number;
    fechaDesde : Date;
    fechaHasta : Date;
    numRegistros : number;
    pagina : number;
   }
    
  