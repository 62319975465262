import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Grado } from '@app/_models/grado';
import { Programa } from '@app/_models/programa';
import { Turno } from '@app/_models/turno';
import { Modalidad } from '@app/_models/modalidad';
import { Temporalidad } from '@app/_models/temporalidad';
import { SolicitudAdmision } from '@app/_models/solicitudAdmision';
import { ResponseRest } from '@app/_models/responseRest';
import { PlanAcademico } from '@app/_models/planAcademico';
import { CicloAcademico } from '@app/_models/cicloAcademico';
import { AuthenticationService } from './authentication.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { SubPlanAcademico } from '@app/_models/subPlane';
import { Campus } from '@app/_models/campus';


@Injectable({ providedIn: 'root' })
export class InteresAcademicoService {
    private esModificacion: boolean;
    private solicitudAdmision :SolicitudAdmision;

constructor(private http: HttpClient,
    private authenticationService: AuthenticationService) {
}


getSessionStorageCampusID():string{
    return localStorage.getItem('campus');
}

getSessionStorageInstitucionID():string{
    return localStorage.getItem('institucion');
}

getSesionStorageCarreraID(): string{
    return localStorage.getItem('programa');

}

getSesionStorageGradoID(): string{
    return localStorage.getItem('grado');

}


obtieneCampus(CampusID: string): Promise<Campus> {
    return  this.http.get<Campus>(`${environment.apiUrl}/campus/${CampusID}/Get`).toPromise();
  }


  obtenerPlanID(CampusID: string, ProgramaID : string, ModalidadID : number):Observable<string>{
    return this.http.post<string>(`${environment.apiUrl}/InteresAcademico/${CampusID}/${ProgramaID}/${ModalidadID}/ObtenerPlanID`, {});
 }

 /*
obtenerPlanID(CampusID: string, ProgramaID : string, ModalidadID : string): Observable<string> {
    console.log("Consultando " + CampusID )
    console.log("Consultando " + ModalidadID )
    console.log("Consultando " + ProgramaID )
    return this.http.post<string>(`${environment.apiUrl}/InteresAcademico/${CampusID}/${ProgramaID}/${ModalidadID}/ObtenerPlanID`,{});
  }*/


  tieneSolicitud(): Observable<boolean> {
    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    return this.http.post<boolean>(`${environment.apiUrl}/InteresAcademico/${prospectoId}/TieneSolicitud`,{});
  }


obtienerSolicitud(): Promise<SolicitudAdmision> {
    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    var promise =  this.http.get<SolicitudAdmision>(`${environment.apiUrl}/InteresAcademico/${prospectoId}`).toPromise();
    console.log("Prom: " + promise);    
    return promise
  }


  getSolicitudAdmision(): SolicitudAdmision {
      return this.solicitudAdmision;
  }

  setSolicitudAdmison(SolicitudAdmision : SolicitudAdmision){
      this.solicitudAdmision = SolicitudAdmision;
  }



consultarNiveles(CampusID : string):Promise<Grado[]>{
    return this.http.post<Grado[]>(`${environment.apiUrl}/InteresAcademico/${CampusID}/Niveles`, {}).toPromise();
}

consultarCarreras(GradoID: number, CampusID : string):Promise<Programa[]>{
    return this.http.post<Programa[]>(`${environment.apiUrl}/InteresAcademico/${GradoID}/${CampusID}/Carreras`, {}).toPromise();
}


consultarCarrerasXClave(Clave: string, CampusID : string):Promise<PlanAcademico[]>{
    return this.http.post<PlanAcademico[]>(`${environment.apiUrl}/InteresAcademico/${Clave}/${CampusID}/CarrerasXClave`, {}).toPromise();
}

consultarModalidades(ProgramaID: string, CampusID):Promise<Modalidad[]>{
    return this.http.post<Modalidad[]>(`${environment.apiUrl}/InteresAcademico/${ProgramaID}/${CampusID}/Modalidades`, {}).toPromise();
}

consultarTemporalidades(ProgramaID: string, CampusID):Promise<Temporalidad[]>{
    return this.http.post<Temporalidad[]>(`${environment.apiUrl}/InteresAcademico/${ProgramaID}/${CampusID}/Temporalidades`, {}).toPromise();
}

consultarCiclosAcademicos(PlanAcademicoID: string):Promise<CicloAcademico[]>{
    return this.http.post<CicloAcademico[]>(`${environment.apiUrl}/InteresAcademico/${PlanAcademicoID}/CiclosAcademicos`, {}).toPromise();
}

consultarSubPlanesAcademicos(PlanAcademicoID: string):Promise<CicloAcademico[]>{
    return this.http.post<CicloAcademico[]>(`${environment.apiUrl}/InteresAcademico/${PlanAcademicoID}/CiclosAcademicos`, {}).toPromise();
}


consultarTurnos(CicloAcademicoID: string):Promise<Turno[]>{
    return this.http.post<Turno[]>(`${environment.apiUrl}/InteresAcademico/${CicloAcademicoID}/Turnos`, {}).toPromise();
}

consultarSubPlanes(PlanAcademicoID: string, GradoID: number):Promise<SubPlanAcademico[]>{
    return this.http.post<SubPlanAcademico[]>(`${environment.apiUrl}/InteresAcademico/${PlanAcademicoID}/${GradoID}/SubPlanes`, {}).toPromise();
}



guardarSolicitudAdmision(solicitud : SolicitudAdmision): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/InteresAcademico`, solicitud).toPromise();
  
  }

 setEsModificacion(esModificacion: boolean){
     this.esModificacion = esModificacion;
}

getEsModificacion(){
    return this.esModificacion;
}



}

