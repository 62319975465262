<mat-card class="conciliacion-card">
    <mat-card-title class="card-title">Conciliación bancaria</mat-card-title>
    <mat-tab-group>

      <mat-tab label="Carga">

        <br><br>
        <form (keydown.enter)="$event.preventDefault()" class="example-form" name="conciliacionForm" id="conciliacionForm" >

          <label>Carga de extracto bancario</label>

          <br>
          <br>

          <mat-form-field>
            <mat-label>Selecciona el banco</mat-label>
            <mat-select id="banco" name="banco"  [(ngModel)]="banco" required>
              <mat-option *ngFor="let banco of bancos" [value]="banco.value">
              {{banco.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>          

          <br>
          <br>
          <button mat-raised-button color="default" (click)="seleccionarArchivo()" >Seleccionar archivo</button>
          &nbsp; &nbsp;{{nombreArchivo}}
          <input class="hidden" type="file" id="upload-file" name="upload-file" accept=".txt" (change)="adjuntarArchivo($event)">

          <br/>
          <br>    
          <br>  
          <div >
            <button class="color-btn" mat-raised-button (click)="importarArchivo()">Importar</button>             
          </div>

      </form>    

      <br>

      <mat-card>
      <div class="mat-elevation-z8">

          <mat-table [dataSource]="dataSourceDinamy">

            <ng-container matColumnDef="fechaPago">
              <mat-header-cell  *matHeaderCellDef > Fecha de pago </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Fecha pago"> {{element.FechaAplicacion | date: 'dd-MM-yyyy'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fechaAplicacion">
              <mat-header-cell  *matHeaderCellDef >Fecha de aplicación </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Fecha aplicación" > {{element.FechaCreacion | date: 'dd-MM-yyyy'}} </mat-cell>
            </ng-container>       

            <ng-container matColumnDef="referencia">
              <mat-header-cell  *matHeaderCellDef class="column-referencia" > Referencia </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Referencia" class="column-referencia"> {{element.Referencia}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="montoPagado">
              <mat-header-cell  *matHeaderCellDef > Monto pagado </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Monto pagado" >{{element.Monto | currency:'$':true }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="banco">
              <mat-header-cell  *matHeaderCellDef > Banco </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Banco"> {{element.Banco}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="aplicado">
              <mat-header-cell  *matHeaderCellDef > Aplicado </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Aplicado">  <label *ngIf="element.Aplicado">Sí</label> <label *ngIf="!element.Aplicado">No</label> </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </mat-table> 
        
      
         <mat-paginator
         #paginator
         [length]="length"
         [pageIndex]="pageIndex"
         [pageSize]="pageSize"
         [pageSizeOptions]="[10, 20, 50]" 
         (page)="pageEvent = getServerData($event)">
         
         </mat-paginator>
      </div>
    </mat-card>



      </mat-tab>

      <mat-tab label="Listado"> 
          <br><br>
        <form (keydown.enter)="$event.preventDefault()" class="example-form" name="listadoConciliacionForm" id="listadoConciliacionForm" >
        
          <label>Listado de extracto bancario</label>
          <br>
          <br>
          <br>
  
          <label>Búsqueda por:</label>
          &nbsp; &nbsp; 
          <mat-radio-group id='tipoFecha' name="tipoFecha" [(ngModel)]="tipoFecha" >
              <mat-radio-button value="2">Fecha de pago</mat-radio-button>
              <mat-radio-button value="1">Fecha de aplicación</mat-radio-button>
          </mat-radio-group>  

          <br>
          <br>
          <br>
          <label>Rango de fechas:</label>
  
          <br>
        
          <mat-form-field>
          <mat-label>Desde</mat-label>
              <input matInput [matDatepicker]="pickerDesde" placeholder="dd/mm/yyyy"  [(ngModel)]='dateDesde'  id="dateDesdeControl" name="dateDesdeControl" >
              <mat-datepicker-toggle matSuffix [for]="pickerDesde">
              </mat-datepicker-toggle>
          <mat-datepicker #pickerDesde></mat-datepicker>
          </mat-form-field>
  
          &nbsp; &nbsp; &nbsp; &nbsp;
          <mat-label>a</mat-label>
          &nbsp; &nbsp; &nbsp; &nbsp;
  
          <mat-form-field>
            <mat-label>Hasta</mat-label>
                <input matInput [matDatepicker]="pickerHasta" placeholder="dd/mm/yyyy" [(ngModel)]='dateHasta' id="dateHastaControl" name="dateHastaControl" >
                <mat-datepicker-toggle matSuffix [for]="pickerHasta">
                </mat-datepicker-toggle>
            <mat-datepicker #pickerHasta></mat-datepicker>
          </mat-form-field>

          &nbsp; &nbsp; &nbsp; &nbsp;

          <button class="color-btn" mat-raised-button (click)="buscar()">Buscar</button>     

          <br>

          <mat-form-field class="example-full-width">
            <mat-label>Filtro de búsqueda:</mat-label>
            <input matInput [(ngModel)]="filtroP" id="filter" name="filter" (input)="filterText()" >
          </mat-form-field>


  
      </form>
  
      <br>
      <br>
      <mat-card>
      <div class="mat-elevation-z8">
          <mat-table [dataSource]="dataSourceP">

            <ng-container matColumnDef="fechaPago">
              <mat-header-cell  *matHeaderCellDef > Fecha de pago </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Fecha pago"> {{element.FechaAplicacion | date: 'dd-MM-yyyy'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fechaAplicacion">
              <mat-header-cell  *matHeaderCellDef > Fecha de aplicación </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Fecha aplicación" > {{element.FechaCreacion | date: 'dd-MM-yyyy'}} </mat-cell>
            </ng-container>       

            <ng-container matColumnDef="referencia">
              <mat-header-cell  *matHeaderCellDef class="column-referencia" > Referencia </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Referencia" class="column-referencia"> {{element.Referencia}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="montoPagado">
              <mat-header-cell  *matHeaderCellDef > Monto pagado </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Monto pagado"> {{element.Monto | currency:'$':true }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="banco">
              <mat-header-cell  *matHeaderCellDef > Banco </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Banco"> {{element.Banco}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="aplicado">
              <mat-header-cell  *matHeaderCellDef > Aplicado </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Aplicado">  <label *ngIf="element.Aplicado">Sí</label> <label *ngIf="!element.Aplicado">No</label> </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </mat-table> 
        
          <mat-paginator
          #paginatorP
          [length]="lengthP"
          [pageIndex]="pageIndexP"
          [pageSize]="pageSizeP"
          [pageSizeOptions]="[10, 20, 50]" 
          (page)="pageEventP = getServerDataP($event)">
          
          </mat-paginator>

      </div>
    </mat-card>
      </mat-tab>

    </mat-tab-group>






   


    
    








