<mat-card class="mat_card">  
  <mat-card-content >
      <br> 
      <div>
        <mat-card-title class="color-rojo rob-20">
          <img class="sm-img" style="margin-left: 56px;" routerLink="/" src="/assets/iconos/ALT_Icono_Volver-4.png"/>
          <img class="sm-img" routerLink="/" src="/assets/iconos/ALT_Icono_Paso-4.png"/>
          <span class="color-rojo rob-26 space">Bienvenidos a Aliat</span>
        </mat-card-title>
      </div>
        <br>
        <mat-divider></mat-divider>
        <br>
        <div class="margin-principal">
    <label>Te compartimos las plataformas que puedes usar como alumno.</label>
    <br>
    <br>
    <br>
    <mat-card-title class="color-celeste rob-20">
      HERRAMIENTAS ACADÉMICAS
    </mat-card-title>
    <br>
<mat-card>
  <mat-card-content>
    <table>
      <tr>
        <td>
          <img class="img-style" src="/assets/iconos/ALT_Logo-MiRedAliat.png"/>
        </td>
        <br>
        <br>
        <td>
          <br>
          <label><b>Mi Red Aliat Alumnos</b></label>
          <p>
            Utiliza tu app Mi Red Aliat, recuerda que contiene los
            servicios ya disponibles para usuarios IOS y Android; en tu
            app podrás realizar el pago de tu colegiatura en línea 100%
            de forma segura, descargar tu ficha de referencia y más.
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.aliat.mi_red_aliat.aliatuniversidades" target="_blank">
            <button type="button" mat-raised-button class="color-btn style-ingresar button-btn">
              <img src="/assets/iconos/ALT_Icono_Android.png"/>
              Descargar para Android</button>
          </a>
          <br>
          <a href="https://apps.apple.com/mx/app/mi-red-aliat/id1440247597" target="_blank">
            <button type="button" mat-raised-button class="color-btn style-ingresar button-btn" style="margin-top: 7px; width: 65%;">
              <img src="/assets/iconos/ALT_Icono_iOS.png"/>
              Descargar para iOS</button>
          </a>
        </td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
    <br>
    <mat-card>
      <mat-card-content>
        <table>
          <tr>
            <td>
              <img class="img-style" src="/assets/iconos/ALT_Logo-BrightSpace.png"/>
            </td>
            <br>
            <br>
            <td>
              <br>
              <label><b>Plataforma BrightSpace</b></label>
              <p>
                Plataforma de aprendizaje (LMS) que te apoyará en tu
                desarrollo académico a través de herramientas y contenidos
                virtuales adaptándose a tus necesidades de horario, movilidad
                y estilo de vida, ¡un gran aliado para tu educación!
              </p>
              <a href='https://www.youtube.com/watch?v=6QNCPuTA5EE' target="_blank">
                <button type="button"  mat-raised-button class="color-btn style-ingresar button-btn" style="width: 66%;">
                  <img src="/assets/iconos/ALT_Icono_Video.png"/>
                  Ver curso de inducción</button>
              </a>
              <br>
              <a  href='https://aliat.brightspace.com/d2l/loginh/' target="_blank">
                <button type="button" mat-raised-button class="color-btn style-ingresar button-btn" style="margin-top: 7px;">
                  <img src="/assets/iconos/ALT_Icono_Plataforma.png"/>
                  Acceder a la plataforma</button>
              </a>
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
<br>
<br>
    <div class="txt-center">
      <label>¿Ya cargaste tus Documentos?</label>
      <br>
      <br>
      <button mat-raised-button routerLink="/carga-documentos">Cargar Documentos</button>
    </div>
    
    <br>
  </div>
  </mat-card-content>
</mat-card>
