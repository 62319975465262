import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Prospecto } from '@app/_models/prospecto';
import { ResponseRest } from '@app/_models/responseRest';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DatosUsuario } from '@app/_models/datosUsuario';
import { Pais } from '@app/_models/pais';
import { Colonia } from '@app/_models/colonia';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AspiranteService {

  constructor(private http: HttpClient,    private authenticationService: AuthenticationService) { }

  obtieneAspirante(): Promise<DatosUsuario> {

    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    var promise =  this.http.get<DatosUsuario>(`${environment.apiUrl}/Aspirante/${prospectoId}`).toPromise();

    console.log("Prom: " + promise);    
    return promise
  }


  aspiranteRegistroDatos(): Observable<boolean> {
    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    return this.http.post<boolean>(`${environment.apiUrl}/Aspirante/${prospectoId}/TieneDatosRegistrados`,{});
  }


  
consultaColoniasXCodigoPostal(cp : string):Promise<Colonia[]>{
    return this.http.post<Colonia[]>(`${environment.apiUrl}/DatosAspirante/${cp}/Colonias`, cp).toPromise();

}

consultaClavesTelefonicas():Promise<Pais[]>{
    return this.http.post<Pais[]>(`${environment.apiUrl}/DatosAspirante/ClavesTelefonicas`, {}).toPromise();

}


guardarDatosUsuario(datosAspirante: DatosUsuario): Promise<ResponseRest<string>> {
  return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Aspirante`, datosAspirante).toPromise();

}


}
