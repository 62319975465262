import { Concepto } from './concepto';



export class Cotizacion {
    PrecioID : number;
    Clave : string;
    AcademicLevelID : string;
    CicloAcademicoID : string;
    TurnoID : string;
    
    PrecioPrograma : Concepto;
    BeneficioEspecial : Concepto;
    PrecioNeto : Concepto;

    DerivadosInscripcion : Concepto[];

    Total : Concepto;

}