import { Component, OnInit, ViewChild } from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators  } from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {first } from 'rxjs/operators';
import { Pago } from '@app/_models/pago';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { formatDate } from '@angular/common';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter, MatOption } from '@angular/material/core';
import { RegistroPagoService } from '@app/_services/registro-pago.service';
import { RegistroPago } from '@app/_models/registroPago';
import { FileDownloadService } from '@app/_services/file-download.service';


@Component({
  selector: 'app-registro-pagos',
  templateUrl: './registro-pagos.component.html',
  styleUrls: ['./registro-pagos.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class RegistroPagosComponent implements OnInit {  conciliacionForm: FormGroup;
  //listadoConciliacionForm: FormGroup;
  searchControl = new FormControl();
  listadoSearchControl = new FormControl();
  fileTXT:File;
  nombreArchivo = 'Ningún archivo seleccionado';
  banco = '';

  controlBusqueda = new FormControl();
  options: string[] = ['Depósito', 'Santander', 'Banamex'];

  bancos: any[] = [
    {value: 'AZTECA', description: 'Banco azteca'},
    {value: 'BANAMEX', description: 'Banamex'},
    {value: 'OXXO', description: 'Oxxo'},
    {value: 'SANTANDER', description: 'Santander'}
  ];

  displayedColumns: string[] = ['institucion', 'campus','fechaPago', 'fechaAplicacion', 'nombre', 'referencia', 'montoPagado', 'banco'];

  dataSourceDinamy = new MatTableDataSource<RegistroPago>();
  dataSourceP = new MatTableDataSource<RegistroPago>();

  pageEvent: PageEvent;
  pageEventP: PageEvent;
  pageIndex:number = 0;
  pageSize:number = 10;
  length:number = 0
  pagos:Pago[];

  pageIndexP:number = 0;
  pageSizeP:number = 10;
  lengthP:number = 0
  errorMessageP: string = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) paginatorP: MatPaginator;
  minDate: Date;
  maxDate: Date;

  selected = true;
  todos = false;
  

  constructor(private registroPagoService:RegistroPagoService, 
    private formBuilder: FormBuilder, public dialog: MatDialog, 
    private authenticationService: AuthenticationService,
    private fileDownloadService: FileDownloadService) { 

      const currentYear = new Date().getFullYear();
      const currentMoth = new Date().getMonth();
      const currentDay = new Date().getDay();
      this.minDate = new Date(currentYear - 1, currentMoth, currentDay);
      this.maxDate = new Date();
  

  }

  ngOnInit() {
    
    this.dataSourceDinamy.paginator = this.paginator;
    this.dataSourceP.paginator = this.paginator;


    this.conciliacionForm = this.formBuilder.group({
      tipoPortal: ['',Validators.required],
      tipoFecha : ['',Validators.required],
      dateDesde: ['',Validators.required],
      dateHasta: ['', Validators.required],
      referencia: [''],

    });   

    //this.conciliacionForm.get('tipoPortal').setValue(this.tipoPortal);        


  }



  getDataPagos() {
    this.getPagos(this.pageSizeP, this.pageIndexP + 1);
  }

  getPagos(nRegistros:number, nPagina:number) {
    var dateDesde = this.conciliacionForm.get('dateDesde').value;
    var dateHasta = this.conciliacionForm.get('dateHasta').value;
    var tipoFecha = this.conciliacionForm.get('tipoFecha').value;
    var tipoPortal = this.conciliacionForm.get('tipoPortal').value;
    var referencia = this.conciliacionForm.get('referencia').value;
    

    this.registroPagoService.get(nRegistros, nPagina, referencia, tipoFecha,
         tipoPortal, dateDesde ,dateHasta)
    .pipe(first())
    .subscribe(
      data => {
        this.lengthP = data.Total;
        this.dataSourceP = new MatTableDataSource<RegistroPago>(data.Data);
      },
      error => {
        this.errorMessageP = "Error al obtener la lista de pagos";
      });
  }



 
  public getServerDataP(event?:PageEvent){

    this.pageSizeP = event.pageSize;
    this.pageIndexP = event.pageIndex;     

    this.getDataPagos();

    return event;
  }

  filterText(){
    this.pageIndexP = 0;
    this.getDataPagos();
  }



  buscar(){

    var dateDesde = this.conciliacionForm.get('dateDesde').value;
    var dateHasta = this.conciliacionForm.get('dateHasta').value;
    var tipoFecha = this.conciliacionForm.get('tipoFecha').value;
    var tipoPortal = this.conciliacionForm.get('tipoPortal').value;
        
    if(tipoPortal == null ||  tipoPortal == ''){
      const dialogData = new ConfirmDialogModel('Aviso', 'Por favor seleccione el portal para realizar la búsqueda', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "500px",
        data: dialogData
      });
      return;

    }

    if (tipoFecha == null ||  tipoFecha == ''){
      const dialogData = new ConfirmDialogModel('Aviso', 'Por favor seleccione un tipo de fecha para realizar la búsqueda', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "500px",
        data: dialogData
      });
      return;
    }



    if (dateDesde == null || dateHasta == null || dateDesde == '' || dateHasta == '' ) {
      const dialogData = new ConfirmDialogModel('Error', 'Debe ingresar las fechas de busqueda', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }


    this.filterText();



  }


  generar(): void {

    var dateDesde = this.conciliacionForm.get('dateDesde').value;
    var dateHasta = this.conciliacionForm.get('dateHasta').value;
    var tipoFecha = this.conciliacionForm.get('tipoFecha').value;
    var tipoPortal = this.conciliacionForm.get('tipoPortal').value;
    var referencia = this.conciliacionForm.get('referencia').value;    

    if(tipoPortal == null ||  tipoPortal == ''){
      const dialogData = new ConfirmDialogModel('Aviso', 'Por favor seleccione el portal para realizar la búsqueda', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "500px",
        data: dialogData
      });
      return;

    }

    if(tipoFecha == null ||  tipoFecha == ''){
      const dialogData = new ConfirmDialogModel('Aviso', 'Por favor seleccione un tipo de fecha para realizar la búsqueda', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "500px",
        data: dialogData
      });
      return;
    }



    if (dateDesde == null || dateHasta == null || dateDesde == '' || dateHasta == '') {
      const dialogData = new ConfirmDialogModel('Error', 'Debe ingresar las fechas de busqueda', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }


    this.registroPagoService.decargar(referencia, tipoFecha,
      tipoPortal, dateDesde, dateHasta)
    .subscribe(
      data => {
        if (!!data) {
          this.fileDownloadService.downloadFile(data, "registroPagos.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        }
      }
    );
    console.log('Aceptar');
  }





  
}
