<mat-card-title class="card-title">Crear una información de interés</mat-card-title>
<div>
    <mat-dialog-content>
        <form class="example-form" name="detalleInfoForm" id="detalleInfoForm" [formGroup]="detalleInfoForm"  autocomplete="off">
            <mat-form-field >
                <mat-label>Nombre</mat-label>
                <input matInput type="text" id="infoNombre" name="infoNombre" formControlName="infoNombre" (input)='verify()' [(ngModel)]='data.info.Nombre' required placeholder="Nombre">
            </mat-form-field>
            <br>
            <br>
            <div>
                <button mat-raised-button color="default" (click)="seleccionarArchivo()" >Seleccionar imagen</button>
                &nbsp; &nbsp;{{data.info.Nombre ? data.info.Nombre + "_" + data.info.InformacionInteresID: nombreArchivo}}
                <input class="hidden" type="file" id="upload-file"  name="upload-file" accept="image/*" (change)="adjuntarArchivo($event)">    
            </div>
            <br>
            <br>
            <div class="example-full-width">
            <ckeditor required data="{{data.info.Descripcion}}" (change)="onChange($event)" [editor]="Editor"></ckeditor>
            </div>
            <br>
            <br>
            <br> 
            <div class="aling">
                <button type="button" style="margin-bottom: 1rem;" (click)="onSubmit()" mat-raised-button class="color-btn style-ingresar button-btn">
                    + Agregar
                </button>
                &nbsp; 
                <button type="button" mat-raised-button (click)="onNoClick()" class="color-btn style-ingresar button-btn">
                    Cancelar
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>