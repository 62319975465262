import { Programa } from './programa';
import { Modalidad } from './modalidad';

export class PlanAcademico {
    PlanAcademicoID: string;
    Codigo : string;
    Nombre : string;
    Descripcion : string;
    CampusID: string;
    ProgramaID : string;
    Programa : Programa;

    Modalidad :Modalidad;

  }