import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Documento } from '@app/_models/documento';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(private http: HttpClient) { }

  get(prospectoId:number){

    let params = new HttpParams();
    params = params.append('idProspecto', prospectoId.toString() );


    return this.http.get<Documento[]>(`${environment.apiUrl}/Documento`, { params });
  }

}
