import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';

import { Modalidad } from '@app/_models/modalidad';

@Injectable({ providedIn: 'root' })
export class ModalidadService {

    constructor(private http: HttpClient) {
      }


consultaModalidades():Promise<Modalidad[]>{
    return this.http.get<Modalidad[]>(`${environment.apiUrl}/modalidad`).toPromise();

}



 
}
