import { Usuario } from './usuario';
import { Pais } from './pais';
import { Colonia } from './colonia';

export class DatosUsuario {
    
    ProspectoID: number;
    Correo: string;
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;  
    Curp : string
    Calle: string;
    NumeroInterior : string;
    NumeroExterior :string;
    CodigoPostal: string;
    ColoniaID : number;
    Telefono : string;
    ClavePais : Pais;
    Colonia : Colonia;

  
}