<mat-card class="card-size interes-academico">  
    <mat-card-content>
    <br> 
    <div>
      <mat-card-title class="color-celeste-claro rob-20 section-title">
        <img class="sm-img" style="margin-left: 56px;" routerLink="/" src="/assets/iconos/ALT_Icono_Volver-2.png"/>
        <img class="sm-img" routerLink="/" src="/assets/iconos/ALT_Icono_Paso-2.png"/>
        <span class="color-celeste-claro rob-26 space">Información académica de interés</span>
      </mat-card-title>
    </div>    

    <!--div>
    <mat-card-title class="color-celeste-claro rob-20">
        <img class="sm-img"  routerLink="/selecciona-institucion" src="/assets/btn-back.png"/>
        <label class="cuadrado bg-celeste-claro">2.</label> 
        Información académica de interés
    </mat-card-title>
    </div-->
      <br>
      <mat-divider></mat-divider>
      <br>
      <div class="margin-principal">      
      <form  name="interesAcademicoForm" id="interesAcademicoForm" [formGroup]="interesAcademicoForm">  
      <mat-card-title class="color-rojo rob-19 text-center" ></mat-card-title>
          <label class="text-center rob-16">Elija la opción de estudios que se acomoda mejor a lo que buscas</label>
          <br>
          <br>
          <label class="color-pregunta rob-20">Campus:</label>
          <label class="color-naranja-sub rob-20" style="margin-left: 1rem;">{{campus.Nombre}}</label>
          <br>
          <br>
          <br>
          <label class="color-celeste rob-17">INTERÉS ACADÉMICO</label>          
          <br>
          <br>
          <br>
            <mat-form-field   class="nivel-width full-width-mobile" style="margin-right: 2rem;">
              <mat-select  placeholder="¿Qué te gustaría estudiar?"  formControlName="grado" required   (openedChange)="onSelectGrado($event)" >
                <mat-option *ngFor="let c of grados" [value]="c.GradoID">
                  {{c.Nombre}}
                </mat-option>
              </mat-select>
          </mat-form-field>


            <mat-form-field  class="carrera-width full-width-mobile" style="margin-right: 2rem;">
              <mat-select placeholder="Programa Académico" formControlName="carrera"   (openedChange)="onSelectCarrera($event)" required >
                <mat-option *ngFor="let c of programas" [value]="c.ProgramaID">
                  {{c.Descripcion}}
                </mat-option>

              </mat-select>
          </mat-form-field>

          <br>
          <mat-form-field  class="carrera-width full-width-mobile" style="margin-right: 2rem;">
            <mat-select  placeholder="¿Cuál es tu modalidad de interés?" formControlName="modalidad"  required   (openedChange)="onSelectModalidad($event)">
              <mat-option *ngFor="let c of modalidades" [value]="c.ModalidadID">
                {{c.Nombre}}
              </mat-option>
            </mat-select>
        </mat-form-field>     

        <div *ngIf="mostrarEspecialidad">
          <mat-form-field  class="nivel-width full-width-mobile" style="margin-right: 2rem;">
            <mat-select placeholder="Especialidad" formControlName="subPlan"    required >
              <mat-option *ngFor="let c of subPlanes" [value]="c.SubPlanAcademicoID">
                {{c.Nombre}}
              </mat-option>

            </mat-select>
        </mat-form-field>

        </div>        
        
        <br>
        <mat-form-field  class="carrera-width full-width-mobile" style="margin-right: 2rem;">
          <mat-select placeholder="¿En qué temporalidad te gustaría estudiar?"   formControlName="temporalidad" required >
            <mat-option *ngFor="let c of temporalidades" [value]="c.TemporalidadID">
              {{c.Nombre}}
            </mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field  class="carrera-width full-width-mobile" style="margin-right: 2rem;" >
        <mat-select placeholder="¿Cuándo te gustaría comenzar?" formControlName="ciclo"  (openedChange)="onSelectCiclo($event)"  required  >
          <mat-option *ngFor="let c of ciclos" [value]="c.CicloAcademicoID">
            {{c.Descripcion}}
          </mat-option>
        </mat-select>
    </mat-form-field> 

    <div *ngIf="mostrarTurno">
      <mat-form-field  class="nivel-width full-width-mobile" style="margin-right: 2rem;" >
        <mat-select placeholder="¿En que turno te gustaría estudiar?" formControlName="turno" required  >
          <mat-option *ngFor="let c of turnos" [value]="c.TurnoID">
            {{c.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field> 
  

    </div>


    <div class="text-center">
      <button class="color-btn"  (click)="guardarInformacion()"  [disabled]="!interesAcademicoForm.valid"   mat-raised-button>Guardar</button>
    </div>
      <br>
    </form>    	  
    </div>
    </mat-card-content>
</mat-card>


