<div class="barra-red-aliat">
  <span class="tab-red-aliat">Red Aliat</span>
</div>
<div class="barra-marcas">
  <img src="/assets/logo-etac.png" class="logo-universidad" />
  <img src="/assets/logo-unea.png" class="logo-universidad" />
  <img src="/assets/logo-uvg.png" class="logo-universidad" />
  <img src="/assets/logo-cest.png" class="logo-universidad" />
  <img src="/assets/logo-concord.png" class="logo-universidad" />
  <img src="/assets/logo-corbuse.png" class="logo-universidad" />
  <img src="/assets/logo-tangamanga.png" class="logo-universidad" />
</div>
<div class="separador-inferior">
  <div class="seccion-separador bg-rojo"></div>
  <div class="seccion-separador bg-azul"></div>
  <div class="seccion-separador bg-naranja"></div>
  <div class="seccion-separador bg-ambar"></div>
  <div class="seccion-separador bg-celeste"></div>
</div>
<div class="barra-footer">
  <div fxLayout="row wrap" fxLayout.xs="column">
    <div fxFlex="50" class="seccion-footer">
      <div>
          <a href="#" routerLink="/ayuda">
          Ayuda en línea
          <i class="fas fa-info-circle"></i>
        </a>
      </div>
      <div class="contenedor-redes-sociales">
        <a href="https://twitter.com/RedAliat?utm_source=Internet&utm_medium=Directo" class="red-social" aria-label="Twitter">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.facebook.com/AliatUniversidades?utm_source=Internet&utm_medium=Directo" class="red-social" aria-label="Facebook">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.youtube.com/user/UniversidadesAliat?utm_source=Internet&utm_medium=Directo" class="red-social" aria-label="Youtube">
          <i class="fab fa-youtube"></i>
        </a>
        <a href="https://www.aliatuniversidades.com.mx/blog/?utm_source=Internet&utm_medium=Directo" class="red-social" aria-label="RSS">
          <i class="fas fa-rss"></i>
        </a>
      </div>
    </div>
    <div fxFlex="50" class="seccion-footer">
      <a href="https://www.aliatuniversidades.com.mx/">
        <img src="/assets/logo-aliat.png" alt="Aliat Universidades" />
      </a>
    </div>
    <div fxFlex="grow" class="seccion-footer">
      <div>
        <a href="https://www.aliatuniversidades.com.mx/aviso-de-privacidad/?utm_source=Internet&utm_medium=Directo">Aviso de Privacidad</a>
      </div>
      <div class="derechos-reservados">
        &copy; Derechos Reservados. Aliat Universidades 2022
      </div>
    </div>
  </div>
</div>
