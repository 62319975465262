<div class="upload-button-component">
  <div class="preview-image" *ngIf="previewImage" [style.background-image]="previewImage"></div>
  <button mat-raised-button color="default" (click)="onClickUpload()" >Seleccionar imagen</button>
  &nbsp; &nbsp;{{file.filename}}
  <br *ngIf="recommendedWidth && recommendedHeight">
  <br *ngIf="recommendedWidth && recommendedHeight">
  <span *ngIf="recommendedWidth && recommendedHeight">La proporción recomendada es de {{recommendedWidth}}x{{recommendedHeight}}px</span>
  <br *ngIf="error">
  <span *ngIf="error" class="error">{{error}}</span>
  <br>
  <img #imageSlot class="image-slot" />
  <input class="hidden" type="file" #uploadInput id="upload-file" name="upload-file" accept="image/*" (change)="onUploadFile($event)">    
</div>