import { Component, OnInit } from '@angular/core';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';
import { ProspectoDocumentoService } from '@app/_services/prospecto-documento.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { DocumentoService } from '@app/_services/documento.service';
import { first } from 'rxjs/operators';
import { Documento } from '@app/_models/documento';

@Component({
  selector: 'app-carga-documentos',
  templateUrl: './carga-documentos.component.html',
  styleUrls: ['./carga-documentos.component.scss']
})

export class CargaDocumentosComponent implements OnInit {

  NINGUN_ARCHIVO:string = '';  
  prospectoId:number;   
  maxSizeFile:number = 5120000; // 5MB
  documentos:Documento[] = [];

  constructor(
    private prospectoDocumentosService:ProspectoDocumentoService,  
    private authenticationService: AuthenticationService,
    private documentoService: DocumentoService,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    this.getDocumentos();
  }

  seleccionarArchivo(clave:string){
    document.getElementById(clave).click();
  }
  
  adjuntarArchivo(fileInput: any, clave:string) {
    
    let doc = this.documentos.find(x => x.Clave == clave);
      
    if (fileInput.target.files.length <= 0){
      doc.EstatusCarga_ = this.NINGUN_ARCHIVO;
      doc.Archivo_ = null;
    } else {
      doc.Archivo_ = fileInput.target.files[0];
      
      let extension = doc.Archivo_.name.substr(doc.Archivo_.name.lastIndexOf('.') + 1 );

      if (doc.Extensiones.toLowerCase().includes(extension.toLowerCase()))
        doc.EstatusCarga_ = doc.Archivo_.name;
      else{
        doc.EstatusCarga_ = this.NINGUN_ARCHIVO;
        doc.Archivo_ = null;
        this.showMessage("Información", "Sólo se permiten archivos con las siguientes extensiones: " + doc.ExtensionesFormateado);
      }
    }
  
  }

  importarDocumentos(){ 

    let nNulos:number = 0;
    let files_ = this.documentos.map(x => x.Archivo_);
    for(var i = 0; i < files_.length; ++i ) {
      if (files_[i] == null || files_[i] == undefined) nNulos++;
    }

    if (nNulos >= files_.length)
    {
      const dialogData = new ConfirmDialogModel('Aviso', 'Debe cargar al menos un documento para poder realizar la carga', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }

    let filesFD = new FormData();

    this.documentos.forEach(documento => {    

      if (documento.Archivo_ != null && documento.Archivo_ != undefined)
      {
          if (documento.Archivo_.size <= this.maxSizeFile){
            filesFD.append(documento.DocumentoID.toString(), documento.Archivo_, documento.Archivo_.name);
          } else {
            this.showMessage('Información', "El documento (" + documento.Nombre + ") excede el peso permitido.");
            return;
          }
      }

    });    

    this.prospectoDocumentosService.importar(filesFD, this.prospectoId)
    .pipe(first())
    .subscribe(
    data => {      

       if (data.Success){

        if (data.Message != null) {
          this.getDocumentos();
          this.showMessage('Información', data.Message);
        }
        else
        {
          if (data.Data != null){
            if (data.Message != null){
              this.showMessage('Información', data.Message);
            }

          } else {
            this.showMessage('Error', 'La solicitud no devolvió resultados.');        
          }
        }

       } else {
        this.showMessage('Error', data.Message);
       }
    },
    error => {
      console.log('error', error);
    });    
  }

  showMessage(title:string, message:string):void {
    const dialogData = new ConfirmDialogModel(title, message, false);    
    this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }

  getDocumentos() {
    
    this.documentoService.get(this.prospectoId)
    .pipe(first())
    .subscribe(
      data => {
       
        this.documentos = data;
        console.log("this.documentos > ", this.documentos);

        //this.documentos.forEach( x => x.EstatusCarga_ = this.NINGUN_ARCHIVO );
        this.documentos.forEach( x => x.ProspectoDocumentoID > 0 ? x.BotonDescripcion = 'Modificar' :  x.BotonDescripcion = 'Seleccionar');
        this.documentos.forEach( x => x.ProspectoDocumentoID > 0 ? x.EstatusCarga_ = x.NombreOriginal :  x.EstatusCarga_ = '');
      },
      error => {
        this.documentos = [];
      });
  }
}
