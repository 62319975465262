<mat-card class="admin-user-card">
    <mat-card-title class="card-title">Información de interés</mat-card-title>

    <form (keydown.enter)="$event.preventDefault()" class="example-form" name="adminInfoForm" id="adminInfoForm" >

    <button mat-raised-button class="color-btn" (click)="agregarInfo()" >+ Agregar</button>
        
    <br>
    <br>

    <mat-form-field>
      <mat-label>Filtro de búsqueda:</mat-label>
      <input matInput [(ngModel)]="filtro"  (input)="filterText()" id="filter" name="filter">
    </mat-form-field>

    </form>

    <br/>
  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="Nombre">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Nombre </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="Nombre">
            {{element.Nombre}}
          </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="Descripcion">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Descripción </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Descripcion"> 
            {{element.Descripcion}}
          </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="Liga">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Imagen </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Liga"> 
              {{element.Nombre}}_{{element.InformacionInteresID}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Opciones">
            <mat-header-cell *matHeaderCellDef> Opciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Opciones">
                <button mat-icon-button color="basic" aria-label="Editar" (click)="editarInfo(element)"  matTooltip="Editar"  matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button color="accent" aria-label="Eliminar" (click)="eliminarInfo(element)" matTooltip="Eliminar" matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
            </mat-cell>
          </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>

      </mat-table>         
    </div>         
<br>
    
</mat-card>
