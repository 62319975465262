<app-navigation-bar [class.disabled]="sidebarHidden"></app-navigation-bar>

<mat-sidenav-container class="sidebar-container">
  <mat-sidenav
    [mode]="sidebarMode"
    [class.disabled]="sidebarHidden"
    [position]="sidebarPosition"
    [(opened)]="sidebarOpened"
    #sideBar
    [fixedInViewport]="sidebarFixed">
    <app-side-bar></app-side-bar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-loading-screen></app-loading-screen>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<app-footer></app-footer>
