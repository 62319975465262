import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Cargo } from '@app/_models/cargo';
import { environment } from '@environments/environment';
import { ResponseRest } from '@app/_models/responseRest';

@Injectable({ providedIn: 'root' })
export class CargoService {

    constructor(private http: HttpClient) {
    }


    consultaCargos(nRegistros:number, nPagina:number, filtro: string){
      console.log('nRegistros', nRegistros + "nPagina " + nPagina );

      let params = new HttpParams();
      params = params.append('numRegistros', nRegistros.toString() );
      params = params.append('pagina', nPagina.toString() );
      params = params.append('filtro', filtro );
  
      return this.http.get<Cargo[]>(`${environment.apiUrl}/cargo`, { params });
  
     }
  
      crearCargo(cargo : Cargo):Promise<ResponseRest<string>> {
        return  this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Cargo`, cargo).toPromise();
        
      }   
      
      getCount(filtro: string){
        let params = new HttpParams();
        params = params.append('filtro', filtro );
    
        return this.http.get<number>(`${environment.apiUrl}/Cargo/Count`, { params });
      }

      eliminarCargo(cargo : Cargo):Promise<ResponseRest<string>> {
        let params = new HttpParams();
        params = params.append('idCargo', cargo.CargoID.toString() );
        return  this.http.delete<ResponseRest<string>>(`${environment.apiUrl}/Cargo`, {params}).toPromise();      
      }      
}
