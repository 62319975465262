import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ErrorStateMatcher, ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatosUsuario } from '@app/_models/datosUsuario';
import { stringify } from 'querystring';
import { AuthenticationService } from '@app/_services/authentication.service';
import { AspiranteService } from '@app/_services/aspirante.service';
import { Colonia } from '@app/_models/colonia';
import { MatLabel } from '@angular/material/form-field';
import { Pais } from '@app/_models/pais';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';										   
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { BuscarCodigoPostalComponent } from '@app/_components/buscar-codigo-postal/buscar-codigo-postal.component';
import { JsonpInterceptor } from '@angular/common/http';

@Component({
  selector: 'app-registro-aspirante',
  templateUrl: './registro-aspirante.component.html',
  styleUrls: ['./registro-aspirante.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class RegistroAspiranteComponent implements OnInit {  
  registroDatosForm: FormGroup;

  ProspectoID  : number;
  correo : string;
  curpError = false;
  coloniaLLena = false;

  colorForce: ThemePalette = 'primary';
  codigoPostal : "";

  colonias: Colonia[] = [];
  selectedColonia : Colonia;

  paises: Pais[] = [];
  //selectedPais : Pais;
  //selectedValue :Pais;
  //filteredOptions: Observable<Pais[]>;
  option = [];
  datosUsuario = new DatosUsuario();
 


  //constructor() { }
  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, 
    private authenticationService: AuthenticationService,
    private aspiranteService : AspiranteService,
    private router: Router, ) {
    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.correo = user.Correo;
        this.ProspectoID = user.ProspectoID;
      }

    });

  
  }





  llenaForma(){
    this.registroDatosForm.get('nombre').setValue(this.datosUsuario.Nombre)
    this.registroDatosForm.get('primerApellido').setValue(this.datosUsuario.ApellidoPaterno);
    this.registroDatosForm.get('segundoApellido').setValue(this.datosUsuario.ApellidoMaterno);
    this.registroDatosForm.get('curp').setValue(this.datosUsuario.Curp);
    this.registroDatosForm.get('calle').setValue(this.datosUsuario.Calle);
    this.registroDatosForm.get('numeroInterior').setValue(this.datosUsuario.NumeroInterior);
    this.registroDatosForm.get('numeroExterior').setValue(this.datosUsuario.NumeroExterior);
    this.registroDatosForm.get('codigoPostal').setValue(this.datosUsuario.CodigoPostal);  
    if(this.datosUsuario.Colonia !=null){
      this.registroDatosForm.get('colonia').setValue(this.datosUsuario.Colonia.ColoniaID);            
      this.registroDatosForm.get('municipio').setValue(this.datosUsuario.Colonia.Municipio.Nombre+ ", "  +this.datosUsuario.Colonia.Municipio.Estado.Nombre);
      this.coloniaLLena = true;


    }
    if(this.datosUsuario.Telefono != null){
      this.registroDatosForm.get('telefono').setValue(this.datosUsuario.Telefono);  
      this.registroDatosForm.get('claveTelefonica').setValue(this.datosUsuario.ClavePais.PaisID);    

    }else{
      console.log("Buscando México");
      var nombrePais = "México";
      const setVal = this.paises.find( op => op.Nombre === nombrePais);
      this.registroDatosForm.get('claveTelefonica').setValue(setVal.PaisID);

    }


  }

  ngOnInit() {

    this.registroDatosForm = this.formBuilder.group({
      nombre: [  '',Validators.required],
      primerApellido: [ '', Validators.required ],
      segundoApellido: [''],
      curp: ['',Validators.required],
      calle: ['',Validators.required],
      numeroExterior: ['',Validators.required],
      numeroInterior: ['',],
      codigoPostal: ['',Validators.required],
      colonia: ['',Validators.required],
      correo : [{ value: this.correo, disabled: true, }],
      claveTelefonica: ['',Validators.required],
      telefono: ['',Validators.required],
      municipio: [ { value: '', disabled: true, }]

    });

    this.aspiranteService.consultaClavesTelefonicas().then(paises => {
      this.paises = paises;
    }).catch(err => {
      console.log("Error" + err);
    });


    this.aspiranteService.obtieneAspirante().then(aspirante => {
      this.datosUsuario = aspirante;
      this.llenaForma(); 
    }).catch(err => {
      console.log("Error" + err);
    });
  
    //this.filteredOptions = this.registroDatosForm.get('claveTelefonica').valueChanges.pipe(startWith(''), map(value => this._filterPaises(value))  );

  }

  guardarInformacion() {

    var telefono = this.registroDatosForm.get('telefono').value;
    console.log("Teléfono " +telefono  + "size: "+  telefono.length);
    if(telefono.length != 10){
      const dialogData = new ConfirmDialogModel('Validación', "El teléfono debe tener 10 digitos", false);    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }



    this.datosUsuario = new DatosUsuario();
    this.datosUsuario.ClavePais = new Pais();
    this.datosUsuario.Colonia = new Colonia();

    this.datosUsuario.ProspectoID = this.ProspectoID;
    this.datosUsuario.ApellidoPaterno = this.registroDatosForm.get('primerApellido').value;
    this.datosUsuario.ApellidoMaterno = this.registroDatosForm.get('segundoApellido').value;
    this.datosUsuario.Nombre = this.registroDatosForm.get('nombre').value;
    var curp =  this.registroDatosForm.get('curp').value;
    curp = curp.toUpperCase();    
    this.datosUsuario.Curp = curp;
    this.datosUsuario.Calle = this.registroDatosForm.get('calle').value;
    this.datosUsuario.NumeroExterior = this.registroDatosForm.get('numeroExterior').value;    
    this.datosUsuario.NumeroInterior = this.registroDatosForm.get('numeroInterior').value;
    this.datosUsuario.CodigoPostal = this.registroDatosForm.get('codigoPostal').value;
    this.datosUsuario.Telefono = this.registroDatosForm.get('telefono').value;
    this.datosUsuario.ColoniaID = this.registroDatosForm.get('colonia').value;
    this.datosUsuario.ClavePais.PaisID = this.registroDatosForm.get('claveTelefonica').value;

   
    console.log("Llamando guardado"  + JSON.stringify(this.datosUsuario));
    this.aspiranteService.guardarDatosUsuario(this.datosUsuario).then(res => {
      if (res.Exito) {
        const dialogData = new ConfirmDialogModel('', "¡Tu información se ha guardado con éxito!   Estás a un paso de ser parte de la red Aliat", false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });        

        dialogRef.afterClosed().subscribe( result => {
          this.router.navigate(['/selecciona-institucion']);          
        });

        /*
        this.aspiranteService.obtieneAspirante().then(aspirante => {
          this.datosUsuario = aspirante;
          this.llenaForma(); 
        }).catch(err => {
          console.log("Error" + err);
        });       */ 
        //this.router.navigate(['/registro-espera', this.correo]);
      } else {
        //this.error = true;
        const dialogData = new ConfirmDialogModel('Error', res.Mensaje, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });

      }
    })
      .catch(err => {
        const message = `¿Ocurrio un error al conectar con el servidor?`;
        const dialogData = new ConfirmDialogModel("Error", message, false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => { });
      })
  }


  validar() {

    var curp =  this.registroDatosForm.get('curp').value;
    //this.registroDatosForm.get('curp').setValue( this.curp.toUpperCase());    
    if(curp != null){
      curp = curp.toUpperCase();
      this.curpError=false;
      if(curp != null && curp.length >0){
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado =curp.match(re);
        if (!validado)  //Coincide con el formato general?
          this.curpError=true;
      }
    }
  }


  obtenerColonias() {
    console.log("obtenerColonias() ");
    this.registroDatosForm.get('municipio').setValue('');
    this.colonias = [];
    this.registroDatosForm.get('colonia').setValue(0);           
    this.codigoPostal =  this.registroDatosForm.get('codigoPostal').value; 
    if(this.codigoPostal != null){
      if(this.codigoPostal.length == 5){
        this.aspiranteService.consultaColoniasXCodigoPostal(this.codigoPostal).then(colonias => {
          this.colonias = colonias;
        }).catch(err => {
          console.log("Error" + err);
        });
      }
   }




  }

  onSelectColonia(entrando) {
    console.log("obtenerColonias() " + entrando);
    if(!entrando){

      var ColoniaID =  this.registroDatosForm.get('colonia').value; 
      if(ColoniaID != 0){
        const setVal = this.colonias.find( op => op.ColoniaID === ColoniaID);
        this.datosUsuario.Colonia = setVal;
        this.registroDatosForm.get('municipio').setValue(setVal.Municipio.Nombre+ ", "  +setVal.Municipio.Estado.Nombre); 
        this.coloniaLLena = true;      
        this.selectedColonia = setVal;
      }else{
        this.registroDatosForm.get('municipio').setValue(""); 
        this.coloniaLLena = false;     
        this.selectedColonia = null;   
      }


    }
  }   

  setMunicipio(){ 
    console.log("setMunicipio() " );    
    var coloniaID =  this.registroDatosForm.get('colonia').value; 
    const setVal = this.colonias.find( op => op.ColoniaID === coloniaID)
    this.datosUsuario.Colonia = setVal;
    this.registroDatosForm.get('municipio').setValue(setVal.Municipio.Nombre+ ", "  +setVal.Municipio.Estado.Nombre);
  } 
  

   
 /*
private _filterPaises(value: any): Pais[] {
  const filterValue = value ? ((typeof value === 'string') ? value.toLowerCase() : value.Nombre.toLowerCase()) : "";
  return this.paises.filter(option => option.Nombre.toLowerCase().startsWith(filterValue));
} */



buscarCodigoPostal(): void {
  var ColoniaSeleccionada = null;
  if(this.datosUsuario.Colonia != null){
    this.datosUsuario.Colonia.CodigoPostal = this.datosUsuario.CodigoPostal;
    ColoniaSeleccionada = this.datosUsuario.Colonia;
  }

  const dialogRef = this.dialog.open(BuscarCodigoPostalComponent, {
    width: '900px',
    data:  ColoniaSeleccionada
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log("cerradoa")
    this.setDatosColonia(result);
  });


}


setDatosColonia(Colonia : Colonia){
  console.log("setDatosColonia " + JSON.stringify(Colonia));
  if(Colonia && Colonia.CodigoPostal ){
    this.registroDatosForm.get('codigoPostal').setValue(Colonia.CodigoPostal);            
    this.obtenerColonias();
    this.registroDatosForm.get('colonia').setValue(Colonia.ColoniaID);            
    this.registroDatosForm.get('municipio').setValue(Colonia.Municipio.Nombre+ ", "  +Colonia.Municipio.Estado.Nombre);
    this.coloniaLLena = true;

  }
}



numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}


}





