export class Campus {

    CampusID: string;
    InstitucionID : string;
    Clave : string;
    Nombre : string;
    
    GoogleMaps : string;
    Website : string;
    imagen : string;
    TieneLigaGoogle : boolean;
    TieneLigaWebsite : boolean;

  }
  
