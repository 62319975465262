export class CicloAcademico {

    CicloAcademicoID:  string;
    Nombre:  string;
    CicloCS:  string;
    CampusID:  string;
    PlanAcademicoID:  string;
    FechaInicio : Date;
    FechaFin : Date;
    Descripcion : string;
    NombrePlan : string;
  }
  