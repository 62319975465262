export class BusquedaCampusParam {


    CampusID: string;
    CodigoPostal : string;
    numRegistros : number;
    pagina : number;

  }
  
