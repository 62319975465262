<mat-card class="admin-user-card">
    <mat-card-title class="card-title">Descarga de documentos de admisión</mat-card-title>
<br>
<mat-label>Filtros de búsqueda:</mat-label>
<br>
<br>
    <form  class="example-form" name="descargaDocForm" id="descargaDocForm" [formGroup]="descargaDocForm">      
  
      <mat-form-field  class="md-width rob-15">
        <mat-label>Instituciones</mat-label>
        <mat-select #mySelInstituciones name="institucionesControl"  formControlName="institucionesControl" [(value)]="selectedInstituciones" id="institucionesControl" multiple>
            <mat-option  #allSelectedInstituciones  (click)="toggleAllSelectionInstituciones()" [value]="'1'"><label>Todos</label></mat-option>
              <mat-option *ngFor="let institucion_ of institucionesSrc" [value]="institucion_" (click)="tosslePerOneInstitucion()"> 
              {{institucion_}}
              </mat-option>
        </mat-select>
      </mat-form-field>    

      <mat-form-field  class="md-width rob-15">
        <mat-label>Campus</mat-label>
        <mat-select #mySelCampus name="campusControl"  formControlName="campusControl" [(value)]="selectedCampus" id="campusControl" multiple>
            <mat-option  #allSelectedCampus  (click)="toggleAllSelectionCampus()" [value]="'1'"><label>Todos</label></mat-option>
              <mat-option *ngFor="let campus_ of campusSrc" [value]="campus_.CampusID" (click)="tosslePerOneCampus()"> 
              {{campus_.CampusID}} - {{campus_.Nombre}}
              </mat-option>
        </mat-select>
      </mat-form-field>    

      <mat-form-field  class="md-width rob-15">
        <mat-label>Grados</mat-label>
        <mat-select #mySelGrados name="gradosControl"  formControlName="gradosControl" [(value)]="selectedGrados" id="gradosControl" multiple>
            <mat-option  #allSelectedGrados  (click)="toggleAllSelectionGrados()" [value]="'1'"><label>Todos</label></mat-option>
              <mat-option *ngFor="let grado_ of gradosSrc" [value]="grado_.Nombre" (click)="tosslePerOneGrado()"> 
                {{grado_.Nombre}}
              </mat-option>
        </mat-select>
      </mat-form-field>    

      <mat-form-field  class="md-width rob-15">
        <mat-label>Programas</mat-label>
        <mat-select #mySelProgramas name="gradosProgramas"  formControlName="programasControl" [(value)]="selectedProgramas" id="programasControl" multiple>
            <mat-option  #allSelectedProgramas  (click)="toggleAllSelectionProgramas()" [value]="'1'"><label>Todos</label></mat-option>
              <mat-option *ngFor="let programa_ of programasSrc" [value]="programa_.ProgramaID" (click)="tosslePerOnePrograma()"> 
                {{programa_.Nombre}}
              </mat-option>
        </mat-select>
      </mat-form-field>     

      <mat-form-field  class="md-width rob-15">
        <mat-label>Ciclos</mat-label>
        <mat-select #mySelCiclos name="gradosCiclos"  formControlName="ciclosControl" [(value)]="selectedCiclos" id="ciclosControl" multiple>
            <mat-option  #allSelectedCiclos  (click)="toggleAllSelectionCiclos()" [value]="'1'"><label>Todos</label></mat-option>
              <mat-option *ngFor="let ciclo_ of ciclosSrc" [value]="ciclo_.Nombre" (click)="tosslePerOneCiclo()"> 
                {{ciclo_.Nombre}}
              </mat-option>
        </mat-select>
      </mat-form-field>   

      <mat-form-field class="md-width rob-15">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre del prospecto" value="" id="nombreProspecto" name="nombreProspecto" [formControl]="nombreControl" (input)='filtrar()' >
      </mat-form-field>
  
  
  <br>

  <button mat-raised-button class="color-btn" class="" (click)="buscar()"  > Buscar </button>

    </form>

    <br/>
  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="IDProspecto">
            <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> ID Prospecto </mat-header-cell><br>
            <mat-cell *matCellDef="let element" data-label="ID Prospecto" class='column-id-prospecto'>
              {{element.IdProspecto}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nombre">
            <mat-header-cell *matHeaderCellDef class="sub-title"> Nombre del Prospecto </mat-header-cell><br>
            <mat-cell *matCellDef="let element" data-label="Nombre">
              {{element.Nombre}}
            </mat-cell>
          </ng-container>
            
        <ng-container matColumnDef="Email">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Email </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Email"> 
            {{element.Email}}
          </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="Campus">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Institución - Campus </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Institución - Campus"> 
            {{element.Institucion_Campus}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nivel">
          <mat-header-cell *matHeaderCellDef  class="sub-title">Nivel</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Nivel"> 
            {{element.Nivel}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Opciones">
            <mat-header-cell *matHeaderCellDef class='column-opciones'> Opciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Opciones" class='column-opciones'>
                <button mat-icon-button color="basic" aria-label="Descargar documentos"  matTooltip="Descargar documentos"  matTooltipPosition="right" (click)="downloadDocs(element.IdProspecto)">
                  <mat-icon>cloud_download</mat-icon></button>
            </mat-cell>
          </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>

      </mat-table>      
      
      <mat-paginator #paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 20]"
      (page)="pageEvent = getSolcitudesData($event)" >
    </mat-paginator>




    </div>         
<br>
    
</mat-card>
