import { Injectable } from '@angular/core';
import { Cotizacion } from '@app/_models/cotizacion';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class CotizacionService {

    constructor(private http: HttpClient,
                    private authenticationService : AuthenticationService ) {
    }
    
    consultarCotizacion(CicloAcademicoID: string, TurnoID : string):Promise<Cotizacion>{
        return this.http.post<Cotizacion>(`${environment.apiUrl}/Cotizacion/${CicloAcademicoID}/${TurnoID}/Cotizacion`, {}).toPromise();
    }

    tienePago(): Observable<boolean> {
        let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
        return this.http.post<boolean>(`${environment.apiUrl}/Cotizacion/${prospectoId}/TienePago`,{});
      } 
    

}