<mat-card class="realiza-pago"> 
    <mat-card-content class="card-cont">
        <br>
        <br>
        <mat-card-title class="text-center rob-40">
            PROCESO DE ADMISIÓN EN LÍNEA
        </mat-card-title>
        <br>
        <br>
        <br>
        <table class="tab-cont">
            <tr>
                <td>
                    <mat-card class="mat-card-fx" [class.mat-elevation-z1]="avance.CompletoPago">
                        <mat-card-content class="card-table">
                            <mat-card-title class="color-celeste">
                                <img class="sm-img img-space" src="/assets/iconos/ALT_Icono_Paso-1.png" />
                                <span class="color-celeste rob-20 space">Registro de datos <br> del aspirante</span>
                            </mat-card-title>
                            <br>
                            <div class="text-center">
                                <img src="/assets/iconos/ALT_Icono_1-Registro.png" class="logo-universidad" />
                            </div>
                            <br>
                            <div class="text-center">
                                <label class="label-text">
                                    Registra aquí tus datos <br> personales y de contacto.<br><br> El dato de CURP es muy <br> importante,
                                    puedes consultarlo <br> en la página indicada.
                                </label> 
                            </div>
                            <br><br><br><br><br><br>
                            <div class="text-center">
                                <button class="color-btn" style="width: 75%;" mat-raised-button routerLink="/registro-aspirante" [disabled]="avance.CompletoPago">Ingresar/Editar</button>
                            </div>
                            <br><br>
                        </mat-card-content>
                    </mat-card>
                </td>
                <td>
                    <mat-card class="mat-card-fx" [class.mat-elevation-z1]="avance.CompletoPago">
                        <mat-card-content class="card-table">
                            <mat-card-title class="color-celeste-claro">
                                <img class="sm-img img-space" src="/assets/iconos/ALT_Icono_Paso-2.png" /> 
                                <span class="color-celeste-claro rob-20 space">Información académica de interés</span>
                            </mat-card-title>
                            <br>
                            <div class="text-center">
                            <img src="/assets/iconos/ALT_Icono_2-Institiucion.png" class="logo-universidad"/>
                            </div>
                            <br>
                            <div class="text-center">
                                <label class="label-text">
                                    Registra aquí la universidad,<br>
                                    campus, programa y modalidad<br>
                                    de tu interés.<br><br>
                                    Podrás ver la cotización de tu pago<br>
                                    de inscripción y colegiatura.
                                </label> 
                            </div>
                            <br><br><br><br><br><br>
                            <div class="text-center">
                                <button class="color-btn" mat-raised-button routerLink="/selecciona-institucion" [disabled]="!avance.CompletoDatosPersonales || avance.CompletoPago">Ingresar/Editar</button>
                            </div>
                            <br><br>
                        </mat-card-content>
                    </mat-card>
                </td>
                <td>
                    <mat-card class="mat-card-fx">
                        <mat-card-content  class="card-table">
                            <mat-card-title class="color-ambar">
                                <img class="sm-img img-space" src="/assets/iconos/ALT_Icono_Paso-3.png" /> 
                                <span class="color-ambar rob-20 space">Pago <br> en Línea</span>
                            </mat-card-title>
                            <br>
                            <div class="text-center">
                            <img src="/assets/iconos/ALT_Icono_3-Pago.png" class="logo-universidad" />
                            </div>
                            <br>
                            <div  class="text-center">
                                <label class="label-text">
                                    Realiza aquí el pago de<br>
                                    tu inscripción.<br><br>
                                    Al finalizar recibirás un<br>
                                    correo electrónico con<br>
                                    dicha confirmación.<br>
                                </label> 
                            </div>
                            <br><br><br><br><br><br>
                            <div class="text-center">
                                <button class="color-btn" mat-raised-button routerLink="/realiza-pago" [disabled]="!avance.CompletoSolicitud">Pagar</button>
                            </div>
                            <br><br>
                        </mat-card-content>
                    </mat-card>
                </td>
                <td>
                    <mat-card class="mat-card-fx" [class.mat-elevation-z12]="avance.CompletoPago">
                        <mat-card-content  class="card-table" >
                            <mat-card-title class="color-naranja">
                                <img class="sm-img img-space" src="/assets/iconos/ALT_Icono_Paso-4.png" /> 
                                <span class="color-naranja rob-20 space">Bienvenidos <br> a Aliat</span>
                            </mat-card-title>
                            <br>
                            <div class="text-center">
                                <img src="/assets/iconos/ALT_Icono_4-Bienvenido.png" class="logo-universidad"/>
                            </div>
                            <br>
                            <div  class="text-center" style="margin-bottom: 2%;">
                                <label class="label-text">
                                    ¡Bienvenido! Ya eres parte de la<br>
                                    comunidad de Aliat Universidades.<br>
                                    Te invitamos a revisar la<br>
                                    información y conocer los<br>
                                    servicios que tenemos para ti.<br>
                                    No olvides anexar los<br>
                                    documentos de admisión.<br>
                                </label> 
                            </div>
                            <br><br>
                            <div class="text-center">
                                <button class="color-btn" style="width: 75%;" mat-raised-button routerLink="/herramientas-academicas" [disabled]="!avance.CompletoPago">Ingresar</button>
                                <br>
                                <br>
                                <button class="color-btn" mat-raised-button routerLink="/carga-documentos" [disabled]="!avance.CompletoPago">Cargar documentos</button>
                            </div>
                            <br>
                        </mat-card-content>
                    </mat-card>
                </td>
            </tr>
        </table> 
        <br><br><br><br><br>
    </mat-card-content>
</mat-card>

<div class="navigation-items media-mp">
    <br>
    <br>
    <mat-card-title class="text-center" style="color:#3970B5;">
        PROCESO DE ADMISIÓN EN LÍNEA
    </mat-card-title>
    <br>
    <br>
    <table style="margin: auto;">
        <tr>
            <mat-card class="card-table" [class.mat-elevation-z1]="avance.CompletoPago">
                <mat-card-content >
                    <mat-card-title class="color-celeste">
                        <img class="sm-img img-space" src="/assets/iconos/ALT_Icono_Paso-1.png" />
                        <span class="color-celeste rob-20 space">Registro de datos <br> del aspirante</span>
                    </mat-card-title>
                    <br>
                    <div class="text-center">
                        <img src="/assets/iconos/ALT_Icono_1-Registro.png" class="logo-universidad" />
                    </div>
                    <br>
                    <div class="text-center">
                        <label class="label-text">
                            Registra aquí tus datos <br> personales y de contacto.<br><br> El dato de CURP es muy <br> importante,
                            puedes consultarlo <br> en la página indicada.
                        </label> 
                    </div>
                    <br><br>
                    <div class="text-center">
                        <button class="color-btn" mat-raised-button routerLink="/registro-aspirante" [disabled]="avance.CompletoPago">Ingresar/Editar</button>
                    </div>
                </mat-card-content>
            </mat-card>
            <br>
        </tr>
        <tr>
            <mat-card  class="card-table" [class.mat-elevation-z1]="avance.CompletoPago">
                <mat-card-content >
                    <mat-card-title class="color-celeste-claro">
                        <img class="sm-img img-space" src="/assets/iconos/ALT_Icono_Paso-2.png" /> 
                        <span class="color-celeste-claro rob-20 space">Información académica <br> de interés</span>
                    </mat-card-title>
                    <br>
                    <div class="text-center">
                        <img src="/assets/iconos/ALT_Icono_2-Institiucion.png" class="logo-universidad"/>
                        </div>
                        <br>
                        <div class="text-center">
                            <label class="label-text">
                                Registra aquí la universidad,<br>
                                campus, programa y modalidad<br>
                                de tu interés.<br><br>
                                Podrás ver la cotización de tu pago<br>
                                de inscripción y colegiatura.
                            </label> 
                        </div>
                        <br><br>
                    <div class="text-center">
                        <button class="color-btn" mat-raised-button routerLink="/selecciona-institucion" [disabled]="!avance.CompletoDatosPersonales || avance.CompletoPago">Ingresar/Editar</button>
                    </div>
                </mat-card-content>
            </mat-card>
            <br>
        </tr>
        <tr>
            <mat-card  class="card-table">
                <mat-card-content >
                    <mat-card-title class="color-ambar">
                        <img class="sm-img img-space" src="/assets/iconos/ALT_Icono_Paso-3.png" /> 
                        <span class="color-ambar rob-20 space">Pago <br> en Línea</span>
                    </mat-card-title>
                    <br>
                    <br>
                    <div class="text-center">
                    <img src="/assets/iconos/ALT_Icono_3-Pago.png" class="logo-universidad" />
                    </div>
                    <br>
                    <div  class="text-center">
                        <label class="label-text">
                            Realiza aquí el pago de<br>
                            tu inscripción.<br><br>
                            Al finalizar recibirás un<br>
                            correo electrónico con<br>
                            dicha confirmación.<br>
                        </label> 
                    </div>
                    <br><br>
                    <div class="text-center">
                        <button class="color-btn" mat-raised-button routerLink="/realiza-pago" [disabled]="!avance.CompletoSolicitud">Pagar</button>
                    </div>
                </mat-card-content>
            </mat-card>
            <br>
        </tr>
        <tr>
            <mat-card  class="card-table" [class.mat-elevation-z12]="avance.CompletoPago">
                <mat-card-content >
                    <mat-card-title class="color-naranja">
                        <img class="sm-img img-space" src="/assets/iconos/ALT_Icono_Paso-4.png" /> 
                        <span class="color-naranja rob-20 space">Bienvenidos <br> a Aliat</span>
                    </mat-card-title>
                    <br>
                    <div class="text-center">
                        <img src="/assets/iconos/ALT_Icono_4-Bienvenido.png" class="logo-universidad"/>
                    </div>
                    <br>
                    <div  class="text-center" style="margin-bottom: 2%;">
                        <label class="label-text">
                            ¡Bienvenido! Ya eres parte de la<br>
                            comunidad de Aliat Universidades.<br>
                            Te invitamos a revisar la<br>
                            información y conocer los<br>
                            servicios que tenemos para ti.<br>
                            No olvides anexar los<br>
                            documentos de admisión.<br>
                        </label> 
                    </div>
                    <br>
                    <br>
                    <div class="text-center">
                        <button class="color-btn" mat-raised-button routerLink="/herramientas-academicas" [disabled]="!avance.CompletoPago">Ingresar</button>
                        <button class="color-btn" mat-raised-button style="margin-left: 10px" routerLink="/carga-documentos" [disabled]="!avance.CompletoPago">Cargar documentos</button>
                    </div>
                </mat-card-content>
            </mat-card>
            <br>
        </tr>
    </table>
</div>
