<mat-card class="mat_card">  
    <mat-card-content >
        <br> 
        <div>
          <mat-card-title class="color-rojo rob-20">
            <img routerLink="/" src="/assets/Carga-documentos/ALT_Icono_Volver-4.png" style="cursor:pointer;"/>
            <img routerLink="/" src="/assets/Carga-documentos/ALT_Icono_Paso-4.png"/>
            <!--<label class="cuadrado bg-rojo">4.</label> -->
            <span class="lato-bold_26">Bienvenidos a Aliat</span>
        </mat-card-title>
        </div>
          <br>
          <mat-divider></mat-divider>
          <br>   
          <span class='lato-regular'>Por favor, carga tus documentos para concluir con el proceso de inscripción.</span>
          <br>      
          <br>
          <br>
     
      <span class="lato-bold text-azul" style="font-size: 20px;">CARGA DE DOCUMENTOS</span>
      <br>
      <br>
      <span class='lato-regular'>Carga de documentos legibles, en el formato especificado, y que pesen <span class='lato-bold' style="color:#EA5329">menos de 5MB.</span></span>
      <br>
      <br>
      <br>
      <div *ngFor="let documento of documentos">
      
        
       
        <!--<label>{{documento.Descripcion}} Extensiones permitidas: {{documento.ExtensionesFormateado}}</label>-->
        <br>
        <table > 
          <tr>
            <td style="width: 40%;" >
              <span class="lato-bold" style="font-size: 20px; color:#082C46"> <img src="/assets/Carga-documentos/ALT_Icono_Carga-Docs.png"/> &nbsp;  &nbsp; {{documento.Nombre}}</span>
              <br>
              <div class="lato-bold-italic">&nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp; ( {{documento.ExtensionesFormateado}} )</div>
            </td>
            <td style="width: 20%;">
              <button class="{{ documento.BotonDescripcion=='Seleccionar' ? 'btn-seleccionar' : 'btn-modificar'}}"  mat-raised-button (click)="seleccionarArchivo(documento.Clave)"> <span class='lato-bold espaciado margin-btn' style="font-size: 16px;">{{documento.BotonDescripcion | uppercase }}</span> </button>  
            </td>
            <td style="width: 40%;">
                <span class="lato-regular" style="color:#8B99A6"> &nbsp; {{documento.EstatusCarga_}}</span>  
            </td>
            <td> <input class="hidden" type="file" id="{{documento.Clave}}" name="{{documento.Clave}}" accept="{{documento.ExtensionesFormateado}}" (change)="adjuntarArchivo($event, documento.Clave)"> </td>
          </tr>
        </table>
        <br>
        <br>
      </div>
    
      <mat-divider></mat-divider>
      <br>
      <br>

      <div class="button-right">
        <button class="btn-seleccionar" mat-raised-button (click)="importarDocumentos()"> <span class='lato-bold espaciado margin-btn' style="font-size: 16px;"> CARGAR DOCUMENTOS </span> </button>
      </div>
      <br>
      <br>
    </mat-card-content>

    <br>

</mat-card>
