import { Component, OnInit, ElementRef, ViewChild, AfterContentInit } from '@angular/core';
import { ThemePalette, ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { RecuperaCuentaService } from '@app/_services/recupera-cuenta.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Usuario_ } from '@app/_models/usuario_';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-cambia-contrasena',
  templateUrl: './cambia-contrasena.component.html',
  styleUrls: ['./cambia-contrasena.component.scss']
})

export class CambiaContrasenaComponent implements OnInit {

  @ViewChild('recaptcha', { static: false }) recaptchaElement: ElementRef;

  cambioPasswordForm: FormGroup;
  confirmarControl: FormControl;

  showInput = false;

  usuario: Usuario_;
  token: string;
  modo: number = 0;
  titulo: string = "Elige una nueva contraseña";
  enable_: boolean = false;
  
  public SITE_KEY: string = '';
  TokenReCaptcha: string = '';
  
  showReCaptcha: boolean = false;

  colorForce: ThemePalette = 'primary';
  valueForce = 0;
  bufferValue = 75;

  password = "";
  containsPssword = false;

  noCoinciden = false;
  matcher = new PasswordErrorStateMatcher();

  fortalezaError = false;  
  mostrarDescripcionPass = false;    

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private recuperaCuentaService:RecuperaCuentaService, public dialog: MatDialog) 
    {

      this.confirmarControl = new FormControl('',[]);

      /*this.cambioPasswordForm = new FormGroup({
      });
*/
      this.activatedRoute.queryParams.subscribe(params => {          
        if(params != null){
          if(params['modo'] != null){
            this.modo = params['modo'];
            if (this.modo == 2) this.titulo = "Ingresa una contraseña para activar la cuenta";
          }
          if(params['token'] != null){
            this.token = params['token']; 
          } else {
            this.router.navigate(['/login']);
          }
        }
      });
    }

  ngOnInit() {
  
    this.SITE_KEY = environment.SITE_KEY_Captcha;

    this.noCoinciden = false;
   
    this.cambioPasswordForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required]),
      confirmPassword: this.confirmarControl,
    }, { validators: this.checkPasswords });

    this.usuario = { 
      UsuarioID: 0, 
      Token: this.token, 
      Password: '', 
      ApellidoMaterno:'', 
      ApellidoPaterno:'',
      Nombre:'', 
      Correo:'',
      TokenRecaptcha: '' 
    };

    this.verificarToken();

  }

  ngAfterContentInit() {

    setTimeout(() => {
      this.showInput = true;
      this.addRecaptchaScript();
    }, 3000);

  }

  get f() { return this.cambioPasswordForm.controls; }
  
  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey': this.SITE_KEY ,
      'callback': (response) => {
        this.TokenReCaptcha = response;
      }
    });
  }

  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  verificarToken() {
    this.enable_ = false;

    this.recuperaCuentaService.verificaToken(this.usuario)
      .pipe(first())
      .subscribe(
        data => {
          if (data.Success) {     
            this.enable_ = true;
          }
        }
      );

  }

  mostrarDescripcion() {
    this.mostrarDescripcionPass= true;
  }
  
  
  ocultarDescripcion() {
    this.mostrarDescripcionPass= false;
  }



  checkPasswords(group: FormGroup) {
    if (!group || !group.get('password') || !group.get('confirmPassword')) {
      return null;
    }

    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
																																					  

    return ((!confirmPass) || (pass === confirmPass)) ? null : { notSame: true }
  }

  cambiarContrasena() { 

    var responseCaptcha = grecaptcha.getResponse();

    this.noCoinciden = false;

    if (responseCaptcha.trim() == '') {     
      const dialogData = new ConfirmDialogModel('Validación', 'Favor de seleccionar la casilla del captcha', false);    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }

    if (this.valueForce <75) {
      this.showMessage('La contraseña no cumple con los requisitos de seguridad', 'Error');
      return;
    }    

    if (this.cambioPasswordForm.get('password').value != this.cambioPasswordForm.get('confirmPassword').value) {
      this.showMessage('No coinciden las contraseñas', 'Error');
      return;
    }

    if (!this.cambioPasswordForm.valid) {
      return;
    }

    /*
    let password_ = this.cambioPasswordForm.get('password').value;
    let confirmPassword_ = this.cambioPasswordForm.get('confirmPassword').value;

    if (password_ != confirmPassword_){
      this.noCoinciden = true;
      return;
    }*/

    this.usuario = { 
      UsuarioID: 0, 
      Token: this.token, 
      Password: this.cambioPasswordForm.get('password').value, 
      ApellidoMaterno:'', 
      ApellidoPaterno:'',
      Nombre:'', 
      Correo:'',
      TokenRecaptcha: this.TokenReCaptcha };

      this.recuperaCuentaService.cambiarContrasena(this.usuario)
      .pipe(first())
      .subscribe(
      data => {
      
        if (data.Success) {    
          const dialogData = new ConfirmDialogModel('Éxito', 'El cambio de contraseña se realizó correctamente.', false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
          });

          dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['/login']);
          });           
        } else {        
          this.router.navigate(['/cambia-contrasena-validar']);
        }   
        grecaptcha.reset();
      },
      error => {

        const dialogData = new ConfirmDialogModel('Error', 'Sucedió un error al procesar la solitud.', false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
        });
        grecaptcha.reset();
      });    
  }

  updateProgressBar() {
    this.fortalezaError = false;
    this.containsPssword = false;
    if (this.f.password.value == undefined) return;
    if (this.f.password.value == null) return;
    if (this.f.password.value.trim() == '') return;

    this.valueForce = this.checkStrength(this.f.password.value);

    if (this.valueForce <= 45) {
      this.fortalezaError = true;
      this.colorForce = 'warn';
    } else if (this.valueForce <= 75) {
      this.colorForce = 'primary';
    } else {
      this.colorForce = 'primary';
    }

    this.containsPssword = true;
  }

  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = (p.length <= 6) ? Math.min(force, 10) : force;

    // 7
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;

    if (force == 20) return 45;
    if (force == 30) return 75;
    if (force == 40) return 100;
    return force;
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {
   
    const dialogData = new ConfirmDialogModel(titulo, message, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    return dialogRef
  }

}

export class PasswordErrorStateMatcher implements ErrorStateMatcher {

															   
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || control.parent.hasError('notSame'));
  }
  
}