<mat-card class="registro-card">
    <div class="center">
      <mat-card class="none">
          <mat-card-title class="card-title"><div class="el-titulo">¡Tu cuenta ha sido creada!</div></mat-card-title>
          <br>
          <div class="texto" style='padding-bottom: 150px; line-height: 2.0em'>
            ¡Estás a un paso de ser parte de la red Aliat Universidades!
            <br><br>
            Tu cuenta ha sido registrada con el correo  {{correo}}
            <br>
            En los próximos minutos recibirás un correo de noreply5@redaliat.mx para activar tu cuenta. Por favor, revisa la bandeja de SPAM o no deseados en caso de no ver el correo en tu bandeja principal.
            <br>
            <br>
         </div>

      </mat-card>
    </div>
  </mat-card>
  