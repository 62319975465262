<mat-card class="card-size">  
  <mat-card-content>
    <br> 
    <div>
      <mat-card-title class="color-celeste section-title">
        <img class="sm-img" style="margin-left: 56px;" routerLink="/" src="/assets/iconos/ALT_Icono_Volver-1.png"/>
        <img class="sm-img" routerLink="/" src="/assets/iconos/ALT_Icono_Paso-1.png"/>
        <span class="color-celeste rob-26 space">Registro de datos del aspirante</span>
      </mat-card-title>
    </div>
    <br>
    <mat-divider></mat-divider>
    <br>
    <div class="margin-principal">
      <form  name="registroDatosForm" id="registroDatosForm" [formGroup]="registroDatosForm">  
        <label class="text-center rob-16">
          Por favor completa el cuestionario para brindarte <br> una mejor asesoría y proceso de admisión.
        </label>
        <br>
        <br>
        <br>
        <label class="color-celeste rob-17">GENERALES</label>
        <br/>
        <br>
        <mat-form-field class="md-width rob-15 full-width-mobile">
          <mat-label>Nombre</mat-label>
          <input matInput name="nombre" formControlName="nombre"  required>
        </mat-form-field>
        <mat-form-field class="md-width rob-15 full-width-mobile">
          <mat-label>Apellido paterno</mat-label>
          <input matInput name="primerApellido" formControlName="primerApellido" required>
        </mat-form-field>
        <mat-form-field class="md-width rob-15 full-width-mobile">
          <mat-label>Apellido materno</mat-label>
          <input matInput name="segundoApellido" formControlName="segundoApellido">
        </mat-form-field>
        <br>
        <mat-form-field class="md-width rob-15 full-width-mobile">
          <mat-label>CURP</mat-label>
          <input matInput class="uppercase" (ngModelChange)="validar()" name="curp" formControlName="curp" required>
        </mat-form-field>
        <div *ngIf="curpError">
          <mat-error *ngIf="curpError"> El CURP tiene un formato inválido </mat-error>
        </div>
        <a target="_blank"  style=" margin-left: 1rem;" href="https://www.gob.mx/curp/">Consulta tu CURP</a>
        <br/>
        <br/>
        <br/>
        <label class="color-celeste rob-17">DIRECCIÓN</label>
        <br/>
        <br>
        <mat-form-field class="registro-full-width full-width-mobile rob-15">
          <mat-label>Calle</mat-label>
          <input matInput  name="calle" formControlName="calle" >
        </mat-form-field>
        <mat-form-field class="xs-width rob-15 full-width-mobile">
          <mat-label>N° exterior</mat-label>
          <input matInput  name="numeroExterior" formControlName="numeroExterior" >
        </mat-form-field>
        <mat-form-field class="xs-width rob-15 full-width-mobile">
          <mat-label>N° interior</mat-label>
          <input matInput  name="numeroInterior" formControlName="numeroInterior">
        </mat-form-field>
        <br>
        <mat-form-field class="md-width rob-15 full-width-mobile">
          <mat-label>Código postal</mat-label>
          <input matInput placeholder="municipio" value=""   name="codigoPostal" formControlName="codigoPostal"   [ngModel]="codigoPostal"    (ngModelChange)="obtenerColonias()">
        </mat-form-field>
        <a href="javascript:void(0);" (click)="buscarCodigoPostal()" style=" margin-left: 1rem;">Consulta tu código postal</a>
        <br/>
        <mat-form-field class="md-width rob-15 full-width-mobile" style="width: 35%;">
        <mat-select id="colonia" name="colonia" formControlName="colonia"  (openedChange)="onSelectColonia($event)" required >
          <mat-option   [value]="0"><label>Seleccione colonia</label></mat-option>                    
          <mat-option *ngFor="let e of colonias" [value]="e.ColoniaID">
            {{e.Nombre}}
          </mat-option>
        </mat-select>
        </mat-form-field>             

        <mat-form-field class="md-width rob-15 full-width-mobile" style="width: 45%;">
          <mat-label>Municipio</mat-label>
          <input matInput placeholder="municipio" value=""  name="municipio" formControlName="municipio">
        </mat-form-field>
        <br>
        <br/>
        <br/>
        <label class="color-celeste rob-17">CONTACTO</label>
        <br/>
        <br>
        <mat-form-field class="rob-15 full-width-mobile" style="width: 35%; margin-right: 2rem;">
          <mat-label>Correo</mat-label>
          <input matInput name="correo" formControlName="correo">
        </mat-form-field>

        <mat-form-field class="xs-width rob-15 full-width-mobile">
          <mat-select id="claveTelefonica" name="claveTelefonica" placeholder="Clave país" formControlName="claveTelefonica"   required >
            <mat-option *ngFor="let e of paises" [value]="e.PaisID">
              {{e.Nombre}} {{e.ClaveTelefonica}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="md-width rob-15 full-width-mobile">
          <mat-label>Teléfono</mat-label>
          <input matInput name="telefono" formControlName="telefono"   maxlength="10" (keypress)="numberOnly($event)" 
          matTooltip="Ingresa los 10 dígitos de tu teléfono sin caractéres extra" >
        </mat-form-field>
        <br/>
        <br/>
        <div style="text-align: right;">
          <button class="color-btn" (click)="guardarInformacion()"  [disabled]="curpError || !registroDatosForm.valid || !coloniaLLena"  mat-raised-button>Guardar</button>
        </div>
      </form>
    </div>
    <br/>
  </mat-card-content>
</mat-card>
  
  
  