import { Component, OnInit } from '@angular/core';
import { ProspectoService } from '@app/_services/prospecto.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Avance } from '@app/_models/avance';

@Component({
  selector: 'app-pantalla-principal',
  templateUrl: './pantalla-principal.component.html',
  styleUrls: ['./pantalla-principal.component.scss']
})
export class PantallaPrincipalComponent implements OnInit {


  public avance: Avance = {
    CompletoDatosPersonales: false,
    CompletoMatriculacion: false,
    CompletoPago: false,
    CompletoSolicitud: false,
    SolicitudAdmisionId: -1
  };

  constructor(
    private authenticationService: AuthenticationService,
    private prospectoService: ProspectoService) {

    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.prospectoService.obtieneAvance(user.ProspectoID).then(avance =>
          this.avance = avance
        ).catch(err => console.log("Error" + err));
      };
    });
  }

  ngOnInit(): void {
    this.loadScript('hs-script-loader','//js.hs-scripts.com/3354370.js');
    
    /*
    setTimeout(function () {
      const chat = document.getElementById('hubspot-messages-iframe-container');
      if(chat == null ){
          //location.reload();
      }
    }, 2000);*/

  }

  


  public loadScript( id:string, url: string) {

    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.type = 'text/javascript';
    script.id = id;
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  


  }  

}
