import { DecimalPipe } from '@angular/common';
import { CicloAcademico } from './cicloAcademico';
import { Grado } from './grado';
import { Modalidad } from './modalidad';
import { Campus } from './campus';


export class Descuento{
    Monto : number;
    Inscripcion : number;
    Colegiatura : number;

    DescuentoID : number;
    Clave : string;
    Nombre : string;
    FechaInicio : Date;
    FechaFin : Date;
    CicloAcademico : CicloAcademico;
    Grado : Grado;
    Modalidad : Modalidad;
    Campus : Campus;
    Activo : boolean;



}