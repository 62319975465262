import { Component, EventEmitter, Output } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service';
import { NavigationService } from '@app/_services/navigation.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {

  @Output() toggleSidenav = new EventEmitter<void>();

  private returnUrl = '/';
  private _isAuthenticated = false;
  public isMobile: boolean = false;
  public isProspect: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
  ) {
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {

        this.returnUrl = event.url;
        
      }
    });

    this.navigationService.isMobile.subscribe(data => {
      this.isMobile = data;
      return;
    });
    

  };



  public get isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated;
  }

  public isAdmin() {
    return this.authenticationService.currentUserValue.IsAdmin;
  }

  public toggleMenu() {
    this.navigationService.isToggledMenu.next(!this.navigationService.isToggledMenu.value);
  }

  public onProfile() {
    this.router.navigate(['/perfil']);
  }

  public isLogin() {
    console.log(this.route);
    return this.route.component == "LoginComponent";
  }

  public logout() {
    this.navigationService.isToggledMenu.next(false);
    this.authenticationService.logout();
    this.router.navigate(['/logout']);
  }
  




}
