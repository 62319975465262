

export class SubPlanAcademico {
    SubPlanAcademicoID: string;
    Codigo : string;
    Nombre : string;
    Descripcion : string;
    CampusID: string;
    ProgramaID : string;
    PlanAcademicoID : string;

  }