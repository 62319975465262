<mat-card class="card-size">
  <mat-card-content>
    <br>
    <div>
      <mat-card-title class="color-ambar section-title">
        <img class="sm-img" style="margin-left: 56px;" routerLink="/" src="/assets/iconos/ALT_Icono_Volver-3.png" />
        <img class="sm-img" routerLink="/" src="/assets/iconos/ALT_Icono_Paso-3.png" />
        <span class="color-ambar rob-26 space">Pago en Línea</span>
      </mat-card-title>
    </div>
    <br>
    <mat-divider></mat-divider>
    <br>
    <div class="margin-principal">
      <label>A continuacion se muestra el importe que tienes por cubrir<br>
        de acuerdo a tu elección. Por favor, elige un método de pago.</label>
      <table class="full-width responsive-table" cellspacing="0">
        <tr>
          <td>
            <br>
            <br>
            <label class="sub-title">SECCIÓN ACADÉMICA</label>
            <br>
            <br>
            <br>
            <div class="col center">
              <table class="tablePago">
                <tr>
                  <td><label class="title-table">Grado:</label></td>
                  <br />
                  <td><label>{{solicitudAdmision.PlanAcademico.Programa.Grado.Nombre}}</label></td>
                  <td><label class="title-table">Modalidad:</label></td>
                  <br />
                  <td><label>{{solicitudAdmision.PlanAcademico.Modalidad.Nombre}}</label></td>
                </tr>
                <br>
                <tr>
                  <td><label class="title-table">Ciclo:</label></td>
                  <br />
                  <td><label>{{solicitudAdmision.CicloAcademico.Descripcion}}</label></td>
                  <td><label class="title-table">Temporalidad:</label></td>
                  <br />
                  <td><label>{{solicitudAdmision.PlanAcademico.Programa.Temporalidad.Nombre}}</label></td>
                </tr>
                <br>
                <tr>
                  <td><label class="title-table">Campus:</label></td>
                  <br />
                  <td><label>{{solicitudAdmision.Campus.Nombre}}</label></td>
                  <td><label class="title-table">Inicio de clases:</label></td>
                  <br />
                  <td><label> {{solicitudAdmision.CicloAcademico.FechaInicio |  date:'dd/LL/yyyy'}}</label></td>
                </tr>
                <br />
                <tr>
                  <td><label class="title-table">Programa Académico:</label></td>
                  <br />
                  <td><label>{{solicitudAdmision.PlanAcademico.Nombre}}</label></td>
                  <td><label class="title-table">Especialidad:</label></td>
                  <br />
                  <td><label>{{solicitudAdmision.SubPlanAcademico.Descripcion}}</label></td>
                </tr>
                <br />
                <tr>
                  <td>
                    <div *ngIf="mostrarTurno"><label class="title-table">Turno:</label></div>
                  </td>
                  <br />
                  <td>
                    <div *ngIf="mostrarTurno"><label>{{solicitudAdmision.TurnoID}}</label></div>
                  </td>
                </tr>

                <br />
                <br />
              </table>
            </div>
          </td>
          <td style="vertical-align: bottom;">
            <button class="color-btn" routerLink="/selecciona-institucion" mat-raised-button
                      [disabled]="avance.CompletoPago">Modificar</button>
          </td>
        </tr>
      </table>
      <table class="full-width responsive-table">
        <tr>
          <td>
            <label class="sub-title xs-no-margin">CONCEPTO Y MONTO A PAGAR</label>
            <br>
            <br>
            <br>
            <table class="full-width responsive-table">
              <tr>
                <td style="vertical-align: top;">
                  <div style="font-size: 20px;font-weight: 500;">Inscripción: <span style="color: red;">${{ prospecto.Saldo | number : '1.2-2'}}</span></div>
                </td>
                <td style="vertical-align: top;">
                  <label><b>Selecciona el método de pago:</b></label>
                <br />
                <br />
                <div class="table pago">
                  <mat-radio-group [(ngModel)]="metodoPago">
                    <mat-radio-button value="p">
                      <span style="margin-bottom: 10px;display: block;"><mat-icon>credit_card</mat-icon>Tarjeta crédito/débito<br></span>
                      <img src="assets/iconos/visa.png" style="margin-right:10px" /><img src="assets/iconos/mastercard.png" />
                    </mat-radio-button>
                    <br />
                    <br />
                    <mat-radio-button value="o">
                      <span style="margin-bottom: 10px;display: block;"><mat-icon>description</mat-icon>Referencia de pago<br></span>
                      <img src="assets/iconos/oxxo.png" style="margin-right:10px" />
                      <img src="assets/iconos/santander.png" style="margin-right:10px" />
                      <img src="assets/iconos/azteca.png" style="margin-right:10px" />
                      <img src="assets/iconos/banamex.png" />
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div *ngIf="!!error" class="text-center">
                  <span class="alerta alerta-warning full-width">
                    {{ error }}
                  </span>
                </div>
                <br />
                <br>
                </td>
                <td style="vertical-align: bottom;">
                  <div>
                    <button class="color-btn" mat-raised-button (click)="pagar()" [disabled]="saldo <= 0">Pagar</button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <table class="full-width responsive-table">
        <tr>
          <td>
            <label class="sub-title xs-no-margin">PLAN DE PAGOS PARCIALES</label>
            <br>
            <br>
            <br>
            <label>Estos son los pagos correspondientes a tu inscripción y colegiaturas que irás cubriendo parcialmente;<br>el area sombreada corresponde al pago que realizarás hoy.</label>
            <br>
            <br>
            <table mat-table [dataSource]="dataSource" class="responsive-table pagos mat-elevation-z8">
              <ng-container [matColumnDef]="column.name" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef class="table-header"> {{column.title}} </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="column.field === 'Nombre'" class="concepto">
                    {{element[column.field]}}
                  </div>
                  <div *ngIf="column.field != 'Nombre'"
                    [ngClass]="{'gris': (column.field == 'Parcialidades' || column.field == 'Total' ) }">
                    {{ (column.isArray ? ( element[column.field][column.index] ) : (element[column.field] )) | currency:"MXN"}}
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  <div *ngIf="column.field === 'Nombre'" class="concepto">
                    {{cotizacion.Total.Nombre}}
                  </div>
                  <div *ngIf="column.field == 'Valor'" class="red">
                    {{  ( cotizacion.Total.Valor ) | currency:"MXN"}}
                  </div>          
        
                  <div *ngIf="column.field == 'Parcialidades'" class="gris">
                    {{  ( cotizacion.Total.Parcialidades[0] ) | currency:"MXN"}}
                  </div>
        
                  <div *ngIf="column.field == 'Total'" class="gris" >
                    {{  ( cotizacion.Total.Total ) | currency:"MXN"}}
                  </div>          
        
                </td>        
              </ng-container>
        
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
              <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>      
            </table>
          </td>
        </tr>
        <tr>
          <td style="text-align: right;padding-top: 20px">
            <button class="color-btn" routerLink="/cotizacion" mat-raised-button>Regresar</button>
          </td>
        </tr>
      </table>
      <br />
    </div>
  </mat-card-content>
</mat-card>
