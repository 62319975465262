import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Prospecto } from '@app/_models/prospecto';
import { Avance } from '@app/_models/avance';
import { ResponseRest } from '@app/_models/responseRest';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Pais } from '@app/_models/pais';

@Injectable({
  providedIn: 'root'
})
export class ProspectoService {

  constructor(private http: HttpClient) { }

  public crearProspecto(prospecto: Prospecto): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Cuenta`, prospecto).toPromise();

  }

  public crearAspirante(prospecto: Prospecto): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/CuentaAspirante`, prospecto).toPromise();

  }

  consultaClavesTelefonicas():Promise<Pais[]>{
    return this.http.post<Pais[]>(`${environment.apiUrl}/CuentaAspirante/ClavesTelefonicas`, {}).toPromise();

}


  public existeUsuario(correo: string):  Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Cuenta/${correo}/Existe`,{}).toPromise(); 
  }

  public obtieneAvance(prospectoId: number): Promise<Avance> {
    return this.http.get<Avance>(`${environment.apiUrl}/Prospecto/${prospectoId}/Avance`).toPromise();
  }


}
