import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OperationResult } from '@app/_models/operationResult';
import { ConfiguracionSitio } from '@app/_models/configuracionSitio';
import { environment } from '@environments/environment';
import { ResponseRest } from '@app/_models/responseRest';

@Injectable({ providedIn: 'root' })
export class ConfiguracionSitioService {

  constructor(private http: HttpClient) {}

  guardar(configuracionSitio: ConfiguracionSitio): Promise<ResponseRest<ConfiguracionSitio>> {
    return this.http.post<ResponseRest<ConfiguracionSitio>>(`${environment.apiUrl}/ConfiguracionSitio`, configuracionSitio).toPromise();
  }

  obtener(): Promise<ConfiguracionSitio> {
    return this.http.get<ConfiguracionSitio>(`${environment.apiUrl}/ConfiguracionSitio`).toPromise();
  }

  guardarImagen(archivos: FormData): Promise<OperationResult<any>> {
    let params = new HttpParams();

    return this.http.post<OperationResult<any>> (`${environment.apiUrl}/ConfiguracionSitio/GuardarImagen`, archivos).toPromise();
  }
}
