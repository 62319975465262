import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Grado } from '@app/_models/grado';
import { Reporte } from '@app/_models/reporte';
import { ResponseRest } from '@app/_models/responseRest';

@Injectable({ providedIn: 'root' })
export class ReporteService {

    constructor(private http: HttpClient) {
      }



      obtieneReporte(reporte: Reporte): Observable<any> {
        //let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
        return this.http.post<any>(`${environment.apiUrl}/ReporteAspirantes`, reporte);
      }

 
}
