import { Campus } from './campus';
import { PlanAcademico } from './planAcademico';
import { CicloAcademico } from './cicloAcademico';
import { SubPlanAcademico } from './subPlane';

export class SolicitudAdmision {
    SolicitudAdmisionID: number;
    ProspectoID: number;
    EstatusAdmisionID: number;
    Campus : Campus;
    PlanAcademico : PlanAcademico;
    CicloAcademico : CicloAcademico;

    SubPlanAcademico : SubPlanAcademico;
    TurnoID : string;



  }
  