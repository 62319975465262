<mat-card class="contenedor login" [ngStyle]="{ backgroundImage: imagenBienvenida }" [style.visibility]="pageReady ? 'visible' : 'hidden'">
  <div>
    <mat-card class="none main-div imgCentral hide-mobile" [style.visibility]="plecaBienvenida && pageReady ? 'visible' : 'hidden'">
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <div>
        <div class="color none ampl editor" #textoBienvenida>
          <br>
          <mat-card-title class="style-title">Admisión en Línea</mat-card-title>
          <hr>
          <br>
          <mat-label class="style-cont">
            Bienvenid@ al módulo de admisiones de Universidad Aliat. Ahora es más fácil inscribirte y comenzar tu
            formación profesional; sólo tienes que seguir los siguientes pasos:
          </mat-label>

          <div class="margin">
            <br>
            <mat-label class="style-cont-bold">
              Paso 1:
            </mat-label>
            <mat-label class="style-cont">
              Regístrate
            </mat-label>

            <br>
            <mat-label class="style-cont-bold">
              Paso 2:
            </mat-label>
            <mat-label class="style-cont">
              Selecciona la institución de tu interés y la carrera
            </mat-label>
            <br>
            <mat-label class="style-cont-bold">
              Paso 3:
            </mat-label>
            <mat-label class="style-cont">
              Realiza tu pago en línea
            </mat-label>
            <br>
            <mat-label class="style-cont-bold">
              Paso 4:
            </mat-label>
            <mat-label class="style-cont">
              Accede a las herramientas académicas y carga tus documentos
            </mat-label>
            <br>


          </div>


          <br>
          <mat-label class="style-cont-italic">
            *Si tienes alguna duda respecto al proceso de registro, abajo podrás consultar el paso a paso.
          </mat-label>
          <br>
          <br>

        </div>
      </div>
    </mat-card>
  </div>
  <mat-card class="none separador">
  </mat-card>
  <mat-card-content class="col-xs-12 col-xl-12 login-form" style="margin-right: 2rem auto;">
    <br /><br /><br /><br />

    <div class="text-center">
      <label class="style-preguntas">¿Nos visitas por primera vez?</label>
    </div>
    <br />
    <div class="text-center">
      <button type="button" mat-raised-button class="color-btn style-ingresar" routerLink="/registro">CREA TU CUENTA
        AQUÍ</button>
    </div>
    <br>
    <br>


    <div class="color dpNone hide-mobile" style="width: 80%; margin: auto; text-align: justify;">
      <mat-card-title>Admisión en Línea.</mat-card-title>
      <hr><br>
      <mat-label class="style-cont">
        Bienvenid@ al módulo de admisiones de Universidad Aliat. Ahora es más fácil inscribirte y comenzar tu formación
        profesional; sólo tienes que seguir los siguientes pasos:
      </mat-label>

      <div class="margin">
        <br>
        <mat-label class="style-cont-bold">
          Paso 1:
        </mat-label>
        <mat-label class="style-cont">
          Regístrate
        </mat-label>
        <br>
        <mat-label class="style-cont-bold">
          Paso 2:
        </mat-label>
        <mat-label class="style-cont">
          Selecciona la institución de tu interés y la carrera
        </mat-label>
        <br>
        <mat-label class="style-cont-bold">
          Paso 3:
        </mat-label>
        <mat-label class="style-cont">
          Realiza tu pago en línea
        </mat-label>
        <br>
        <mat-label class="style-cont-bold">
          Paso 4:
        </mat-label>
        <mat-label class="style-cont">
          Accede a las herramientas académicas y carga tus documentos
        </mat-label>
        <br>
      </div>
    </div>
    <br>
    <br>
    <mat-card-title class="text-center style-inicio">INICIO DE SESIÓN</mat-card-title>
    <div>
      <form style="text-align: -webkit-center; ">
        <div class="full-width field-container">
          <input type="text" placeholder="Correo" [(ngModel)]="usuario" name="usuario" required>
          <br />
          <input placeholder="Contraseña" [(ngModel)]="password" type="password" name="password" required>
          <br>
        </div>
        <button type="button" mat-raised-button class="color-btn style-ingresar" (click)="login()">INGRESAR</button>
        <div *ngIf="error" class="alerta alerta-warning text-center full-width" style="margin: 0.5rem 0;">
          {{errorMessage}}
        </div>
      </form>
      <br />
    </div>
    <br>

    <div class="text-center">
      <label class="style-preguntas">¿No puedes ingresar a tu cuenta?</label>
    </div>
    <div class="text-center">
      <a class="style-link" id="link" href="#" routerLink="/recupera-cuenta">Recupera tu cuenta aquí</a>
    </div>
    <br>
    <br>
  </mat-card-content>
</mat-card>
<br>
<mat-tab-group mat-align-tabs="center" [style.visibility]="pageReady ? 'visible' : 'hidden'">
  <mat-tab label="PROCESO DE ADMISIÓN EN LÍNEA" active>
    <br>
    <div class="editor" style="width: 80%; margin:auto; text-align: justify;" #textoProceso>
      <label class="rob-20-bold">
        Para aclarar cualquier duda con respecto al proceso, a continuación explicamos los pasos que debes seguir para
        inscribirte de manera fácil y sencilla.
      </label><br><br>

      <label class="rob-24" style="color:#0070B9">
        Paso 1: Crea tu cuenta
      </label>
      <br>
      <br>
      <label>
        Es importante que te registres, para hacerlo usa tu correo electrónico personal,
        crear una cuenta de usuario e indica una contraseña. Éstas serán tus credenciales
        de acceso a la plataforma de Admisión en Línea. Al tener tu cuenta activa tendrás que
        registrar tu información personal, general y de contacto para podamos tener contacto contigo. <br>

      </label>
      <br>

      <label class="rob-24" style="color:#52B9EB">
        Paso 2: Seleccionar institución, campus y programa educativo
      </label>
      <br><br>
      <label>
        En este punto es muy importante que selecciones la Institución y campus de tu preferencia.
        Contarás con una opción que te permitirá buscar el campus más cercano a tu casa.<br><br>

        En este paso, también es importante que elijas el <label style="font-weight: bold;">programa de tu interés y la
          modalidad de estudios</label>
        (presencial, ejecutiva u online*).
        <label class="rob-18-ital">*La modalidad puede variar según la institución educativa y su
          campus.</label><br><br>

        Después de elegir el programa académico, podrás ver la cotización con los costos de inscripción
        y colegiatura del programa y modalidad que elegiste. También te dará los descuentos o
        promociones que tengamos para ti en ese momento.<br>
      </label>


      <br>
      <label class="rob-24" style="color:#F89B1C">
        Paso 3: Pago en línea
      </label>
      <br>
      <br>
      <label>
        Ya estás a sólo un paso de ser parte de nuestra red y tener acceso a todos los beneficios
        de estudiar con nosotros; <label style="font-weight: bold;">realiza tu pago </label>
        de inscripción y ¡listo!
        <br>
        <br>
        Encontrarás varias alternativas para realizarlo, como: tarjeta de crédito, débito, pago en banco,
        transferencia bancaria y pago en establecimientos OXXO.
        <br>
        <br>
        Tendrás la opción de pagar la cantidad total o elegir hacerlo en parcialidades;
        lo que sí te recomendamos es que hagas el pago total de la inscripción para asegurar tu lugar.
      </label>
      <br>
      <br>
      <label class="rob-24" style="color:#EA5329">
        Paso 4: Bienvenido a Aliat Universidades
      </label>
      <br>
      <br>
      <label>
        ¡Felicidades, ya eres parte de la red Aliat Universidades! Al llegar a este paso, tendrás acceso
        a la plataforma y herramientas para que gestiones temas administrativos y académicos.
        <br>
        <br>
        En esta etapa es muy importante que cargues tus
        <label style="font-weight: bold;">documentos para concluir el proceso de admisión.</label>
        A continuación, te decimos cuáles son los que debes tener en formato digital para anexarlos:
        <br>
        <ul>
          <li>
            Acta de Nacimiento
          </li>
          <li>
            CURP
          </li>
          <li>
            Certificado de Estudios*
          </li>
        </ul>
        <br>
        <label class="rob-18-ital">
          *En caso de que hayas terminado tus estudios, anexa un certificado de estudios completo. Si no lo tienes aún,
          anexa una
          constancia de 100% de créditos o certificado en trámite.
        </label>
      </label>
      <br>
      <br>
    </div>
  </mat-tab>
  <mat-tab label="CONOCE ALIAT UNIVERSIDADES">
    <br>
    <div class="editor" style="width: 80%; margin:auto; text-align: justify;" #textoConoce>
      <br>
      <br>
      <label class="rob-18">
        Al inscribirte con nosotros serás parte de una red mexicana de universidades con más de 50
        años formando profesionales y con:
        <br><br>
        <i class="fa fa-check" aria-hidden="true" style="color:#0070B9"></i>
        <label>
          170 mil egresados y más de 50 mil estudiantes inscritos.
        </label>

        <br><br>
        <i class="fa fa-check" aria-hidden="true" style="color:#0070B9"></i>
        <label>
          Tiene presencia en 18 estados de la república con 32 campus en 13 estados,
          18 sedes y una plataforma en línea.
        </label>

        <br><br>
        <i class="fa fa-check" aria-hidden="true" style="color:#0070B9"></i>
        <label>
          Amplia oferta académica: bachillerato, licenciatura, licenciaturas ejecutivas,
          licenciaturas sabatinas, licenciaturas en línea, maestrías, doctorados y especialidades.
        </label>
        <br><br>
        <i class="fa fa-check" aria-hidden="true" style="color:#0070B9"></i>
        Aliat Universidades está conformada por 7 instituciones educativas:

        <ul>
          <li>
            <label style="font-weight: bold;">
              Universidad ETAC:
            </label>
            Estado de México (Coacalco, Chalco y Tlalnepantla), Hidalgo (Tulancingo) y Ciudad de México (Tlatelolco).
          </li>
          <li>
            <label style="font-weight: bold;">
              Universidad Valle de Grijalva (UVG):
            </label>
            Chiapas (Tuxtla, Comitán, Pichucalco y Tapachula), Campeche, Tabasco (Villahermosa) y Veracruz
            (Coatzacoalcos).
          </li>
          <li>
            <label style="font-weight: bold;">
              Universidad de Estudios Avanzados (UNEA):
            </label>
            Aguascalientes, Querétaro, Coahuila (Saltillo), Chihuahua, Baja California: Mexicali y Tijuana (Alamar y
            Florido)
          </li>
          <li>
            <label style="font-weight: bold;">
              Centro de Estudios Superiores de Tapachula (CEST):
            </label>

          <li>
            <label style="font-weight: bold;">
              Universidad Tangamanga (UTAN):
            </label>
            San Luis Potosí (Saucito, Industrias, Tequis y Huasteca).
          </li>
          <li>
            <label style="font-weight: bold;">
              Universidad La Concordia (ULC)
            </label>
            Aguascalientes (Centro y Fórum Internacional).
          </li>
          <li>
            <label style="font-weight: bold;">
              Centro de Estudios Superiores Corbusé:
            </label>
            Estado de México (Interlomas, Metepec, Santa Mónica y Coacalco), Ciudad de México (Parque Hundido) y
            Querétaro.
          </li>

          <li>
            <label style="font-weight: bold;">
              On Aliat
            </label>
            es la plataforma en línea de Aliat Universidades, que cuenta con las mejores herramientas digitales y una
            oferta educativa muy amplia, ya que tiene 12 licenciaturas, 3 maestrías y una especialidad, todos avalados
            por la SEP.
          </li>
        </ul>
        <br>
      </label>
      <br>
      <br>

    </div>
  </mat-tab>


</mat-tab-group>
