

<mat-card-title class="card-title">Nuevo prospecto</mat-card-title>
<form    [formGroup]="detalleProspectoForm" class="form" name="detalleProspectoForm" id="detalleProspectoForm">  

  <mat-form-field class="full-width">
    <mat-label>Correo electrónico*</mat-label>
    <input matInput placeholder="correo@example.com"   id="correo" name="correos" formControlName="correo"   [disabled]="guardando">
  </mat-form-field>

  <br>



  <mat-form-field  class="full-width">
    <mat-label>Campus {{cargandoCampus}}</mat-label>
    <input matInput placeholder="Campus" aria-label="Campus" [matAutocomplete]="auto" [formControl]="myControl" >
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option id="campus" name="campus" *ngFor="let option of filteredOptions | async"  [value]="option.CampusID" (onSelectionChange)="selectedOption($event)">
        {{option.CampusID}} - {{option.Nombre}}
      </mat-option>
    </mat-autocomplete>
    <!-- <mat-select  id="campus" name="campus" formControlName="campus"  (ngModelChange)='verify()' required [disabled]="guardando || cargandoCampus">
      <mat-option *ngFor="let c of campus" [value]="c.CampusID">
        {{c.InstitucionID}} - {{c.Nombre}}
      </mat-option>
    </mat-select> -->
</mat-form-field>          
  <br>

<mat-form-field  class="full-width">
    <mat-label>Grado {{cargandoGrado}}</mat-label>
    <mat-select id="grado" name="grado" formControlName="grado"  (ngModelChange)='verify()' required [disabled]="guardando || cargandoGrado">
      <mat-option *ngFor="let c of grados" [value]="c.GradoID">
        {{c.Nombre}}
      </mat-option>
    </mat-select>
</mat-form-field>
<br>
<mat-form-field class="full-width" >
    <mat-label>Periodo {{cargandoPeriodo}}</mat-label>
    <mat-select id="periodo" name="periodo" formControlName="periodo"  [disabled]="guardando || cargandoPeriodo">
      <mat-option *ngFor="let c of periodos" [value]="c.CicloID">
        {{c.Nombre}}
      </mat-option>
    </mat-select>
</mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Oportunidad*</mat-label>
    <input matInput placeholder="Ingrese la oportunidad"  id="oportunidad" name="oportunidad" formControlName="oportunidad"  value='{{data.Cargo.NumeroOportunidad}}' [disabled]="guardando">
  </mat-form-field>


  <mat-form-field class="full-width">
    <mat-label>Monto a pagar*</mat-label>
    <input matInput placeholder="Ingrese el monto a pagar" id="monto" name = "monto" 
    formControlName = "monto"  value='{{data.Cargo.Monto}}' [disabled]="guardando" type="text"
    (blur)="transformAmount($event)" [(ngModel)]="formattedAmount">
  </mat-form-field>

<table>
    <tr>
      <td>
          <button mat-raised-button color="primary" class="" (click)="aceptar()" matTooltip="Guardar el Prospecto" [disabled]="guardando || hayErrores || !detalleProspectoForm.valid">Guardar</button>
      
    
                                                                
      </td>
      <td>
        <button mat-raised-button color="warn" class="" (click)="cancelar()" matTooltip="Cancelar la creación" [disabled]="guardando">Cancelar</button>
      </td>
      <td>
        <mat-progress-spinner mode="indeterminate" [diameter]="30" *ngIf="guardando"></mat-progress-spinner>
      </td>
    </tr>
</table>

  


</form>
    
    