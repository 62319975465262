<mat-card class="card-size">  
    <br> 
    <div>
      <mat-card-title class="color-celeste-claro rob-20 section-title">
        <img class="sm-img" style="margin-left: 56px;" routerLink="/" src="/assets/iconos/ALT_Icono_Volver-2.png"/>
        <img class="sm-img" routerLink="/" src="/assets/iconos/ALT_Icono_Paso-2.png"/>
        <span class="color-celeste-claro rob-26 space">Información académica de interés</span>
      </mat-card-title>
    </div>    
    <!--div>
      <mat-card-title class="color-celeste-claro rob-20">
          <img class="sm-img"  routerLink="/" src="/assets/btn-back.png"/>
          <label class="cuadrado bg-celeste-claro">2.</label> 
          Información académica de interés
      </mat-card-title>
    </div-->
    <br>
    <mat-divider></mat-divider>
    <br>  
    <div class="margin-principal">
    <form    [formGroup]="seleccionInstitucionForm" class="form" name="seleccionInstitucionForm" id="buscarCPForm"> 
      <label class="text-center rob-16">Por favor completa el cuestionario para brindarte una mejor asesoría y proceso de admisión.</label>
      <br>
      <br/>
      <label class="color-celeste rob-17">LOCALIZACIÓN DE INSTITUCIÓN</label>      
      <br>
      <br>
      <label class="rob-16">¿Cómo deseas localizar tu institución?</label>
      <br>
      <br>

            
      <div class="registro-full-with">



    </div>

      <table class="full-width responsive-table" cellspacing="0">
        <mat-radio-group formControlName="tipo"  (change)="onSelecTipo($event)" >
        <tr>        
          <td class="full-width-mobile" style="width: 70%;"  >
              <mat-radio-button  value="1"><label>Por su nombre</label></mat-radio-button>
          </td>
          <td class="full-width-mobile">
              <mat-radio-button value="2"><label>Por Código Postal</label></mat-radio-button>
          </td>          
          <td></td>
        </tr>
        <tr>
          <td>
            <mat-form-field  class="nombre-width full-width-mobile rob-15">
              <input matInput placeholder="Nombre de institución " aria-label="Nombre de institución" [matAutocomplete]="auto" formControlName="campus"  >
              <mat-autocomplete #auto="matAutocomplete" >
                <mat-option      value="Todas"><label>Todas</label></mat-option>                              
                <mat-option id="est" name="est" *ngFor="let option of filteredInstituciones | async"  [value]="option" >
                  {{option}}
                </mat-option>
              </mat-autocomplete>
          </mat-form-field>

            <!--mat-form-field  class="nombre-width rob-15">
              <input matInput placeholder="Nombre de institución " aria-label="Nombre de institución" [matAutocomplete]="auto" formControlName="campus"  >
              <mat-autocomplete #auto="matAutocomplete" >
                <mat-option    (onSelectionChange)="onSelectedCampus($event)"  [value]="0"><label>Todas</label></mat-option>                              
                <mat-option id="est" name="est" *ngFor="let option of filteredCampus | async"  [value]="option" (onSelectionChange)="onSelectedCampus($event)">
                  {{option.InstitucionID}}-{{option.Nombre}}
                </mat-option>
              </mat-autocomplete>
          </mat-form-field-->
          </td>
          <td>
            <mat-form-field class="md-width rob-15 full-width-mobile">
              <mat-label>C.P.</mat-label>
              <input matInput class="input-cp" type="text" formControlName="codigoPostal"  placeholder="C.P." >
            </mat-form-field>            

          </td>
          <td>
            <button (click)="buscar()" mat-raised-button class="search-btn">
              Buscar
            <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
          </button>
      
          </td>
        </tr>
      </mat-radio-group>
      </table>




    
    
    <br/>
    <br/>
    <label class="color-celeste rob-17">INSTITUCIONES ENCONTRADAS</label>          
    <br/>
    <br>
    <label class="rob-16">¿En qué institución te gustaría estudiar?</label>
    <br/>
    <br>
    <div class="example-container mat-elevation-z8">
      <mat-table [dataSource]="dataSource" matSort>
      
          <ng-container matColumnDef="Institución">
            <mat-header-cell *matHeaderCellDef> Institución </mat-header-cell><br>
            <mat-cell *matCellDef="let element" data-label="Institución">
              <img class="img-style" src={{element.imagen}} onError="this.src='assets/logo-aliat.png'" />
            </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="Campus">
            <mat-header-cell *matHeaderCellDef> Campus </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Campus"> {{element.Nombre}} </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="Enlaces">
            <mat-header-cell *matHeaderCellDef> Referencias </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Visítanos"> 
              <table>
                <tr><td>
                  <div   *ngIf="element.TieneLigaWebsite" >
                    <a target="_blank"  href={{element.Website}}>Visitar página web</a><br>
                  </div>
                </td></tr>
                <tr><td>
                  <div class="full-width" *ngIf="element.TieneLigaGoogle">
                    <a target="_blank"  href={{element.GoogleMaps}}>Ver en Google Maps</a>                
                  </div>
        
                </td></tr>
              </table>

              <h4>

                
              </h4>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef=" ">
            <mat-header-cell *matHeaderCellDef > </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label=""> 
              <div class="txt-center">
                <button mat-raised-button  (click)="seleccionarInstitucion(element)"  >Seleccionar</button>
              </div>  
            </mat-cell>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table> 
        <mat-paginator 
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20]" 
        (page)="pageEvent = getServerData($event)" >
      </mat-paginator>            
       </div>         



    </form>    
  </div>    
 
</mat-card>

