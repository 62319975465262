<mat-card class="card-size"> 
  <mat-card-title class="color-celeste">
    <img class="sm-img" style="margin-left: 56px;" routerLink="/" src="/assets/iconos/ALT_Icono_Volver-1.png"/>
    <span class="color-celeste rob-26 space">Recupera cuenta</span>
  </mat-card-title>
  <br>
  <mat-divider></mat-divider>
  <br>
  <div class="principal1">
    <div class="margin-principal">
  <br>
  <br>
  <form class="example-form" name="recuperarCuentaForm" id="recuperarCuentaForm" [formGroup]="recuperarCuentaForm">

    <mat-form-field class="recupera-full-width">
      <mat-label>Correo electrónico</mat-label>
      <input matInput placeholder="correo@example.com" formControlName="email" id="email" name="email" [(ngModel)]='correo' required>                
    </mat-form-field> 
    <br>

    <mat-form-field class="recupera-full-width">
      <mat-label>Fecha de nacimiento*</mat-label>
      <input matInput [min]="minDate" class="sm-date" [max]="maxDate" placeholder="dd/mm/yyyy" 
      [(ngModel)]='dateNac' [matDatepicker]="picker" formControlName="fechaNac" id="fechaNac" name="fechaNac">    
            <div *ngIf="dateError">
              <mat-error *ngIf="dateError"> La fecha ingresada no tiene un formato correcto. </mat-error>
            </div>                                   
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <br>
    <br>

    <div #recaptcha></div>

    <br>
    <div class="text-center">
      <button class="color-btn" mat-raised-button (click)="onSubmit()" [disabled]="!enableBtn"  >Continuar</button>
    </div>
  </form>
</div>
</div>  
</mat-card>