import { Component, OnInit } from '@angular/core'
import { AuthenticationService } from '../_services/authentication.service'
import { Router } from '@angular/router'
import { NavigationService } from '../_services/navigation.service'
import ClassicEditor from '../../ckeditor5-build-classic/build/ckeditor'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component'
import { File } from '@app/_components/upload-button/upload-button.component'
import { FormBuilder, FormGroup } from '@angular/forms'
import { first } from 'rxjs/operators'
import { ConfiguracionSitioService } from '@app/_services/configuracion-sitio.service'
import { ConfiguracionSitio } from '@app/_models/configuracionSitio'
import { OperationResult } from '@app/_models/operationResult'
import { ResponseRest } from '@app/_models/responseRest'
import { environment } from '@environments/environment'
import { MatCheckboxChange } from '@angular/material/checkbox'
import {MatSnackBar} from '@angular/material/snack-bar'

@Component({
  selector: 'app-administracion-imagen',
  templateUrl: './administracion-imagen.component.html',
  styleUrls: ['./administracion-imagen.component.scss']
})
export class AdministracionImagenComponent implements OnInit {
  public Editor = ClassicEditor
  administracionImagenForm: FormGroup
  values: ConfiguracionSitio
  previewImage: string

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private formBuilder: FormBuilder,
    private configuracionSitioService: ConfiguracionSitioService,
    private snackBar: MatSnackBar
  ) {
  }

  onEditorChange({ editor }: ChangeEvent, fieldName) {
    const data = {}

    data[fieldName] = editor.getData()

    this.administracionImagenForm.patchValue(data)
  }

  onCheckChange(value: MatCheckboxChange, fieldName) {
    const data = {}

    data[fieldName] = value.checked

    this.administracionImagenForm.patchValue(data)
  }

  async save() {
    const data = new ConfiguracionSitio()
    const value = this.administracionImagenForm.value

    data.TextoBienvenida = value.textoBienvenida
    data.TextoConoce = value.textoConoce
    data.TextoProceso = value.textoProceso
    data.PlecaBienvenida = value.plecaBienvenida

    try {
      const result: ResponseRest<ConfiguracionSitio> = await this.configuracionSitioService.guardar(data)
      const saveImageResult: OperationResult<null> = await this.saveImage()

      this.snackBar.open("Configuración guardada", "Ok", { duration: 2000, verticalPosition: "top" })
    }
    catch(ex) {
      console.error(ex)
      let self = this
      this.snackBar
        .open("Ocurrió un error", "Reintentar", { duration: 2000, verticalPosition: "top" })
        .onAction()
        .subscribe(() => self.save())
    }
  }

  async saveImage() {
    let fileData = new FormData();

    const imagenBienvenida = this.administracionImagenForm.value.imagenBienvenida

    if(imagenBienvenida && imagenBienvenida.data instanceof Blob) {
      fileData.append("imgFile", imagenBienvenida.data, imagenBienvenida.name)
    }
    else {
      return { Data: false, Success: true, Message: null, Total: null }
    }

    return await this.configuracionSitioService.guardarImagen(fileData)
  }

  onUploadFile(file: File) {
    this.administracionImagenForm.patchValue({ imagenBienvenida: file })
  }

  async loadData() {
    const data: ConfiguracionSitio = await this
      .configuracionSitioService
      .obtener()

    this.previewImage = `${environment.apiUrl.replace("api", "")}Content/ConfiguracionSitio/${data.ImagenBienvenida}`

    this.values = data

    this.administracionImagenForm.patchValue({
      textoBienvenida: data.TextoBienvenida,
      textoConoce: data.TextoConoce,
      textoProceso: data.TextoProceso,
      imagenBienvenida: data.ImagenBienvenida,
      plecaBienvenida: data.PlecaBienvenida
    })
  }

  ngOnInit() {
    this.administracionImagenForm = this.formBuilder.group({
      textoBienvenida: [''],
      textoConoce: [''],
      textoProceso: [''],
      imagenBienvenida: [undefined],
      plecaBienvenida: [true]
    })

    this.loadData()
  }
}
