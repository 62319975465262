import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_shared/auth.guard';

import { AdministracionComponent } from './administracion/administracion.component';
import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { RecuperaCuentaComponent } from './recupera-cuenta/recupera-cuenta.component';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { CambiaContrasenaValidarComponent } from './cambia-contrasena-validar/cambia-contrasena-validar.component';
import { RegistroComponent } from './registro/registro.component';
import { RealizaPagoComponent } from './realiza-pago/realiza-pago.component';
import { PagoProcesadoComponent } from './pago-procesado/pago-procesado.component';
import { ReferenciaComponent } from './referencia/referencia.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { ReporteComponent } from './reporte/reporte.component';
import { ActivarComponent} from './activar/activar.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { RegistroEsperaComponent} from './registro-espera/registro-espera.component';
import { RecuperaCuentaEsperaComponent} from './recupera-cuenta-espera/recupera-cuenta-espera.component';
import { CargaDocumentosComponent } from './carga-documentos/carga-documentos.component';
import { HerramientasAcademicasComponent } from './herramientas-academicas/herramientas-academicas.component';
import { InteresAcademicoComponent } from './interes-academico/interes-academico.component';
import { PantallaPrincipalComponent } from './pantalla-principal/pantalla-principal.component';
import { SeleccionaInstitucionComponent } from './selecciona-institucion/selecciona-institucion.component';
import { RegistroAspiranteComponent } from './registro-aspirante/registro-aspirante.component';
import { CotizacionComponent } from './cotizacion/cotizacion.component';
import { AdministracionUsuariosComponent } from './administracion-usuarios/administracion-usuarios.component';
import { ConciliacionBancariaComponent } from './conciliacion-bancaria/conciliacion-bancaria.component';
import { DescargaDocumentosComponent } from './descarga-documentos/descarga-documentos.component';
import { AdminInfInteresComponent } from './admin-inf-interes/admin-inf-interes.component';
import { AdminDescuentosComponent } from './admin-descuentos/admin-descuentos.component';
import { ProspectosAvisoComponent } from './prospectos-aviso/prospectos-aviso.component';
import { RegistroPagosComponent } from './registro-pagos/registro-pagos.component';
import { AdministracionImagenComponent } from './administracion-imagen/administracion-imagen.component';


const routes: Routes = [{ path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'pantalla-principal', component: PantallaPrincipalComponent, canActivate: [AuthGuard] },
  { path: 'inicio', component: InicioComponent, canActivate: [AuthGuard] },
  { path: 'administracion', component: AdministracionComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] } },
  { path: 'realiza-pago', component: RealizaPagoComponent, canActivate: [AuthGuard] },
  { path: 'pago-procesado', component: PagoProcesadoComponent },
  { path: 'carga-documentos', component: CargaDocumentosComponent, canActivate: [AuthGuard] },
  { path: 'descarga-documentos', component: DescargaDocumentosComponent, canActivate: [AuthGuard] },
  { path: 'admin-inf-interes', component: AdminInfInteresComponent, canActivate: [AuthGuard] },
  { path: 'herramientas-academicas', component: HerramientasAcademicasComponent, canActivate: [AuthGuard] },
  { path: 'interes-academico', component: InteresAcademicoComponent, canActivate: [AuthGuard] },
  { path: 'admin-descuentos', component: AdminDescuentosComponent, canActivate: [AuthGuard] },
  { path: 'administracion-usuarios', component: AdministracionUsuariosComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] } },
  { path: 'administracion-imagen', component: AdministracionImagenComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] } },
  { path: 'administracion-prospectos', component: ProspectosAvisoComponent, canActivate: [AuthGuard], data: { roles: ["CARGOS"] } },
  { path: 'selecciona-institucion', component: SeleccionaInstitucionComponent, canActivate: [AuthGuard] },
  { path: 'cotizacion', component: CotizacionComponent, canActivate: [AuthGuard] },
  { path: 'conciliacion-bancaria', component: ConciliacionBancariaComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "CONCILIA"] } },
  { path: 'registro-pagos', component: RegistroPagosComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "REGPAGOS"] } },  
  { path: 'reporte', component: ReporteComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "REPORTE"] } },
  { path: 'referencia', component: ReferenciaComponent },
  { path: 'ayuda', component: AyudaComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'registro-espera/:correo', component: RegistroEsperaComponent },
  { path: 'recupera-cuenta', component: RecuperaCuentaComponent },
  { path: 'recupera-cuenta-espera/:correo', component: RecuperaCuentaEsperaComponent },
  { path: 'cambia-contrasena', component: CambiaContrasenaComponent },
  { path: 'cambia-contrasena-validar', component: CambiaContrasenaValidarComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reporte', component: ReporteComponent },
  { path: 'activar', component: ActivarComponent },
  { path: 'logout', component: SignOutComponent },
  { path: 'registro-aspirante', component: RegistroAspiranteComponent }];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
