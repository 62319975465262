import { Component, OnInit, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';

import { InfocionInteresOperacion } from '../../_models/informacioninteres';

import { first } from 'rxjs/operators';
import { InformacionInteres } from '@app/_services/informacioninteres.service';
import { AuthenticationService } from '@app/_services/authentication.service';

import ClassicEditor from '../../../ckeditor5-build-classic/build/ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

@Component({
  selector: 'app-detalle-inf-interes',
  templateUrl: './detalle-inf-interes.component.html',
  styleUrls: ['./detalle-inf-interes.component.scss']
})

export class DetalleInfInteresComponent implements OnInit {

  public model = {
    editorData: ''
  };
  public Editor = ClassicEditor;
  fileTXT:File;
  nombreArchivo = 'Ningún archivo seleccionado';
    
  detalleInfoForm: FormGroup;

  maxSizeFile:number = 5120000; // 5MB
  enableBtn = false;
  textBtnCancelar = "Cancelar";
  disabled_ = false;

  loading: boolean = false;
  error: boolean = false;
  correcto: boolean = false;
  errorMessage: string = "";
  message: string = "";

  option = [];
  selectedValue = "";
  cargandoCampus = null;

  idUsuario: number;
  prospectoId: number;

  constructor(private formBuilder: FormBuilder,
     public dialogRef: MatDialogRef<DetalleInfInteresComponent>,
     private authenticationService: AuthenticationService,
     private interesService: InformacionInteres,
     public dialog: MatDialog,
     @Inject(MAT_DIALOG_DATA) public data: InfocionInteresOperacion) { }

  ngOnInit(): void {

    this.prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    if (this.data.operacion ===  'Crear') {
      this.disabled_ = false;
      this.data.info = {
        InformacionInteresID: 0,
        Nombre: '',
        Descripcion: '', 
        Liga: '',
        Archivo_ : null
      }
    } else {
      this.disabled_ = true;
    }

    this.detalleInfoForm = this.formBuilder.group({
      infoNombre: [this.data.info.Nombre, Validators.required],
    }); 
  }
  onChange( { editor }: ChangeEvent ) {
    this.data.info.Descripcion = editor.getData();
}

  onSubmit(){
    this.textBtnCancelar = "Cancelar";
    this.error = false;
    this.correcto = false;
    this.loading = true;
    if (this.detalleInfoForm.invalid) {
      return;
    }
    if (this.data.info.Descripcion == '') {
      return;
    }
    if (this.data.operacion ===  'Crear') {     

      this.interesService.post(this.data.info)
      .pipe(first())
      .subscribe(
      data => {
        this.data.info.InformacionInteresID =  +data.Message
        this.importarDocumentos()
        this.loading = false;
        if (data){
          this.data.info = {
            InformacionInteresID: 0,
            Nombre: '',
            Descripcion: '', 
            Liga: '',
            Archivo_ : null
          }
          this.correcto = true;
          this.message = "Liga de interés creada correctamente";
          this.cancelar();
        }else{
          this.error = true;
        }            
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al crear la Liga de interés";
        this.loading = false;
      });
    } else {
      this.interesService.put(this.data.info)
      .pipe(first())
      .subscribe(
      data => {
        this.loading = false;
        if (data){
          this.correcto = true;
          this.enableBtn = false;
          if (this.data.info.Archivo_ != null && this.data.info.Archivo_ != undefined)
          {
          this.importarDocumentos()
          }
          this.message = "Liga de interés actualizado correctamente";
          this.textBtnCancelar = "Cerrar";
          this.cancelar();
        }else{
          //this.errorMessage = data.Message;
          this.error = true;
        }            
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al actualizar la Liga de interés";
        this.loading = false;
      });
    }
  }
  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  }

  adjuntarArchivo(fileInput: any) {
    if (fileInput.target.files.length <= 0){
      this.nombreArchivo = 'Ningún archivo seleccionado';
      this.fileTXT = null;
    } else {
      this.fileTXT = fileInput.target.files[0];
      this.nombreArchivo = this.fileTXT.name;
      this.data.info.Archivo_ = this.fileTXT
    }
  
  }

  importarDocumentos(){ 
    
    let filesFD = new FormData();

    if (this.data.info.Archivo_ != null && this.data.info.Archivo_ != undefined)
    {
        if (this.data.info.Archivo_.size <= this.maxSizeFile){
          filesFD.append('imgFile', this.data.info.Archivo_, this.data.info.Archivo_.name);
        } else {
          this.showMessage('Información', "El documento (" + this.data.info.Archivo_.name + ") excede el peso permitido.");
          return;
        }
    }
    this.interesService.importar(filesFD, this.data.info.InformacionInteresID, this.data.info.Nombre)
    .pipe(first())
    .subscribe(
    data => {      

       if (data.Success){

        if (data.Message != null) {
          this.showMessage('Información', data.Message);
        }
        else
        {
          if (data.Data != null){
            if (data.Message != null){
              this.showMessage('Información', data.Message);
            }

          } else {
            this.showMessage('Error', 'La solicitud no devolvió resultados.');        
          }
        }

       } else {
        this.showMessage('Error', data.Message);
       }
    },
    error => {
      console.log('error', error);
    });    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  cancelar(): void {
    this.dialogRef.close();
  }
  aceptar(): void {
    this.dialogRef.close();
  }

  agregar(): void {
    this.dialogRef.close();
  }

  verify() {
    if (!this.detalleInfoForm.invalid) {
      this.enableBtn = true;
    }else{
      this.enableBtn = false;
    }
  }

  showMessage(title:string, message:string):void {
    const dialogData = new ConfirmDialogModel(title, message, false);    
    this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }
}
