import { Component, OnInit } from '@angular/core';
import { InfocionInteres } from '@app/_models/informacioninteres';
import { InformacionInteres } from '@app/_services/informacioninteres.service';
import { first } from 'rxjs/operators';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-herramientas-academicas',
  templateUrl: './herramientas-academicas.component.html',
  styleUrls: ['./herramientas-academicas.component.scss']
})
export class HerramientasAcademicasComponent implements OnInit {
  imageSource;
  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  pageIndex:number = 0;
  pageSize:number = 5;
  filtro: string = "";
  datos: InfocionInteres[] = [];
  constructor(private interesService: InformacionInteres, public dialog: MatDialog, private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getDataInfoInteres();
  }

  getDataInfoInteres() {
    this.getLiga(this.pageSize, this.pageIndex + 1);
  }

  getLiga(nRegistros:number, nPagina:number) {
    this.loading = true;
    this.interesService.get(nRegistros, nPagina, this.filtro)
    .pipe(first())
    .subscribe(
      data => {
        this.datos = data;
        
        this.datos.forEach(obj => {
          this.imageSource = this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${obj.Liga}`);
          obj.Liga = this.imageSource
         });
        this.loading = false;
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al obtener el total de registros";
        this.loading = false;
      });
  }
}
