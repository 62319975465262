import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Grado } from '@app/_models/grado';

@Injectable({ providedIn: 'root' })
export class GradoService {

    constructor(private http: HttpClient) {
      }


consultaGrados():Promise<Grado[]>{
    return this.http.get<Grado[]>(`${environment.apiUrl}/grado`).toPromise();

}

getByIdUser(idUsuario:number):Promise<Grado[]>{
    let params = new HttpParams();
    params = params.append('idUsuario', idUsuario.toString() );

    return this.http.get<Grado[]>(`${environment.apiUrl}/Grado/GetByIdUsuario`, { params }).toPromise();
}


 
}
