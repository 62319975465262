import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../_services/navigation.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
  ) {
    this.authenticationService.currentUser.subscribe(user => {
      this.isAdmin = false;
      this.isColab = false;
      this.isAmonProspecto = false;
      this.isConciliacion = false;
      this.isRegPagos = false;
      this.isReportes = false;
      this.isProspect = false;
      this.isAuthenticated = false;
      this.isDescargaDoc = false;
      this.userName = "";
      if (user != null) {
        this.isAdmin = user.IsAdmin;
        this.isColab = user.IsEmpleado;
        this.isAmonProspecto = user.Roles.indexOf("CARGOS") > -1 || user.IsAdmin;
        this.isConciliacion = user.Roles.indexOf("CONCILIA") > -1 || user.IsAdmin;
        this.isRegPagos = user.Roles.indexOf("REGPAGOS") > -1 || user.IsAdmin;
        this.isReportes = user.Roles.indexOf("REPORTE") > -1 || user.IsAdmin;
        this.isDescargaDoc = user.Roles.indexOf("DESCDOC") > -1 || user.IsAdmin;
        this.isDescuentos = user.Roles.indexOf("DESCTOS") > -1 || user.IsAdmin;
        this.isInfoInteres = user.Roles.indexOf("INFOINT") > -1 || user.IsAdmin;
        this.isProspect = user.IsProspecto;
        this.isAuthenticated = true;
        this.userName = user.Nombre + " " + user.ApellidoPaterno + " " + user.ApellidoMaterno;
      }
    })
  }

  ngOnInit() {
  }

  public isAdmin: boolean = false;

  public isColab: boolean = false;

  public isAmonProspecto: boolean = false;

  public isConciliacion: boolean = false;

  public isReportes: boolean = false;

  public isProspect: boolean = false;

  public isRegPagos : boolean = false;

  public isDescargaDoc : boolean = false;  

  public isDescuentos : boolean = false;  

  public isInfoInteres : boolean = false;  

  public userName: string = "";

  public isAuthenticated: boolean = false;

  public logout() {
    this.authenticationService.logout();
    this.router.navigate(['/logout']);
  }

}
