export class InfocionInteres {
    InformacionInteresID:number;
    Nombre: string;
    Descripcion: string;
    Liga : string;
    Archivo_ ? : File;
  }

  export class InfocionInteresOperacion {
    info: InfocionInteres
    operacion: string
  }