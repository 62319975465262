import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { OperationResult } from '@app/_models/operationResult';
import { Pago } from '@app/_models/pago';
import { RegistroPago } from '@app/_models/registroPago';
import { Observable } from 'rxjs';
import { RegistroPagoParam } from '@app/_models/registroPagoParam';

@Injectable({
  providedIn: 'root'
})
export class RegistroPagoService {

  constructor(private http: HttpClient) { }

  get(nRegistros:number, nPagina:number, filtro: string, tipoFecha: number, 
    portal:number, desde: Date, hasta: Date){

      var param = new RegistroPagoParam();
      param.Filtro = filtro;
      param.tipoFecha = tipoFecha;
      param.tipoPortal = portal;
      param.fechaDesde = desde
      param.fechaHasta = hasta;
      param.pagina = nPagina;
      param.numRegistros = nRegistros;


    return this.http.post<any>(`${environment.apiUrl}/RegistroPagoRegistro`,  param );
  }



  decargar(filtro: string, tipoFecha: number, 
    portal:number, desde: Date, hasta: Date): Observable<any> {
    var param = new RegistroPagoParam();
    param.Filtro = filtro;
    param.tipoFecha = tipoFecha;
    param.tipoPortal = portal;
    param.fechaDesde = desde
    param.fechaHasta = hasta;
    return this.http.post<any>(`${environment.apiUrl}/RegistroPago`,  param );
  }  

}
